import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import UserReducer from "./reducerUser";
import ReviewsReducer from "./reducerReviews";
import PriceListReducer from "./reducerPriceList";
import OrderstReducer from "./reducerOrders";
import reducerRefundRequest from './reducerRefundRequest';
import cartReducer from "./cartReducer";
import Purchases from "./PurchasesMyOrders";
import ListReducer from "../components/PriceList/PriceInner/ListReducer";

import { reducer as formReducer } from "redux-form";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    user: UserReducer,
    reviews: ReviewsReducer,
    price_list: PriceListReducer,
    orders: OrderstReducer,
    cart: cartReducer,
    purchases: Purchases,
    innerPriceList: ListReducer,
    refund_request: reducerRefundRequest,
  });

export default rootReducer;
