import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import './Loader.scss';

const Loader = (props) => {
    return (
        <div className="loader_wrapper">
            <CircularProgress size={50} />
        </div>
    );
};

export default Loader;