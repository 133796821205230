import React from "react";

export const withUploadFile = (Component) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        formData: new FormData(),
      };
    }

    setFromData = (id, message, files) => {
      const { formData } = this.state
      // очищаем formData
      formData.delete("message");
      formData.delete("refund_request");
      formData.delete("files");

      // добавляем formData
      formData.append("message", message);
      formData.append("refund_request", id);
      // загрузка нескольких файлов
      if (files && files.length) {
        Array.from(files).forEach((item) => {
          formData.append("files", item);
        });
      }
      this.setState({ formData });
    
    };

    render() {
      const { formData } = this.state;

      return (
        <Component
          withUploadFile={{
            formData,
            setFromData: this.setFromData,
          }}
          {...this.props}
        />
      );
    }
  };
};
