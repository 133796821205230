import * as types from "../actions/constants";

const INITIAL_STATE = {
    items: [],
    item_detail: {},
    error: [],
    data: {},
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_PURCHASES_SUCCESS:
            return { ...state, items: action.payload.data };
        case types.GET_MY_PURCHASES_SUCCESS:
            return { ...state, data: action.payload.data };
        case types.GET_PURCHASES_DETAIL_SUCCESS:
            return { ...state, item_detail: action.payload.data };
        case types.CLEAR_DATA_ITEM:
            return {
                ...state,
                items: action.items,
                item_detail: action.items_detail,
            };
        default:
            return state;
    }
}
