import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {reduxForm, Field} from 'redux-form';
import moment from 'moment';
import DefaultButton from '../../../Buttons/DefaultButton/DefaultButton';

import {postOrderComment, getOrder} from "../../../../actions/ordersActions";

import './CommentOrders.scss';

class CommentOrders extends Component {
    state = {
        allOrders: false
    };

    toggleAllOrders = () => {
        this.setState(({allOrders}) => ({
            allOrders: !allOrders
        }));
    };

    resizeText = () => {
        const inputValue = ReactDOM.findDOMNode(this._inputEl);
        setTimeout(function(){
            inputValue.style.cssText = 'height:auto;';
            let height = Number(inputValue.scrollHeight) + 2;
            inputValue.style.cssText = 'height:' + height + 'px';
        },0);

    };

    submitForm = (data) => {
        const { postOrderComment, getOrder, params, reset } = this.props;

        let obj = {
            order: params.id,
            comment: data.comment,
            new_message: true
        };
        postOrderComment(obj).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 201) {
                reset();
                getOrder(params.id);
                const inputValue = ReactDOM.findDOMNode(this._inputEl);
                setTimeout(function(){
                    inputValue.style.cssText = 'height:auto;';
                },0);
            }
        })
    };


    render(){
        const {orders: {order}, handleSubmit, submitting, pristine, valid} = this.props;
        const {allOrders} = this.state;

        return (
            <div className="comment-orders">
                <div className="title">Комментарии</div>
                {order.orderreviews.length>3 ?
                    <button className="btn_hide" onClick={this.toggleAllOrders}>• • •  {allOrders ? "скрыть" : "показать"}</button>
                    :
                    null
                }
                <div className={allOrders || order.orderreviews.length<=3 ? "comments-wrapper" : "comments-wrapper comments-wrapper-last" }>
                    {order.orderreviews.map((orderreview, index) => (
                        <div className="comment" key={"order" + index}>
                            <div className="comment-info">
                                <div className="comment-name">{orderreview.name}</div>
                                <div className="comment-time">{moment(orderreview.date).format('DD.MM.YYYY HH:mm')}</div>
                            </div>
                            <div className="descriptions">{orderreview.comment}</div>
                        </div>
                    ))
                    }
                </div>
                <form onSubmit={handleSubmit(this.submitForm)}>
                    <Field name="comment" rows='7' component="textarea" onKeyDown={(e)=>this.resizeText(e)} ref={(node)=>{this._inputEl = node}}  className="comment-text" placeholder="Ваше сообщение..."/>
                    <DefaultButton
                        variant="contained"
                        formAction
                        disabled={submitting || pristine || !valid }
                    >
                        Ответить
                    </DefaultButton>
                </form>

            </div>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.comment) {
        errors.customer_name = 'Required'
    }
    return errors
};

CommentOrders = reduxForm({
    form: 'CommentOrders',
    enableReinitialize: true,
    validate
})(CommentOrders);

function mapStateToProps(state) {
    return{
        orders: state.orders,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postOrderComment,
        getOrder
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentOrders);