import * as types from "../actions/constants";

const INITIAL_STATE = {
    user_info: {},
    dashboard: {},
    notifications: [],
    info_company: {},
    info_contacts: [],
    info_contacts_loaded: false,
    info_addresses: [],
    info_addresses_loaded: false,
    error: [],
    info_balance: {},
    productsRecommendations: []
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.USER_SUCCESS :
            return {...state, user_info : action.payload.data};
        case types.DASHBOARD_SUCCESS :
            return {...state, dashboard : action.payload.data};
        case types.PATCH_REVIEWS_DASHBOARD_SUCCESS :
            let reviewDashSuccessObj = state.dashboard;
            reviewDashSuccessObj[0].reviews[reviewDashSuccessObj[0].reviews.findIndex(x => x.id === action.payload.data.id)] = action.payload.data;
            return {...state, dashboard : reviewDashSuccessObj};
        case types.NOTIFICATION_SUCCESS :
            return {...state, notifications : action.payload.data};
        case types.INFO_SUCCESS :
            return {...state, info_company : action.payload.data};
        case types.CHANGE_INFO_SUCCESS :
            return {...state, info_company : action.payload.data};
        case types.INFO_CONTACTS_SUCCESS :
            return {...state, info_contacts : action.payload.data, info_contacts_loaded: true};
        case types.NEW_CONTACT_SUCCESS :
            return {...state, info_contacts : [ ...state.info_contacts, action.payload.data]};
        case types.CHANGE_CONTACT_SUCCESS :
            let changeContactObj = state.info_contacts;
            changeContactObj[changeContactObj.findIndex(x => x.id === action.payload.data.id)] = action.payload.data;
            return {...state, info_contacts : changeContactObj};
        case types.DELETE_CONTACT_SUCCESS :
            let id = Number(action.meta.previousAction.payload.request.url.split('/')[3]);
            return {...state, info_contacts : state.info_contacts.filter(el => el.id !== id)};
        case types.INFO_ADDRESSES_SUCCESS :
            return {...state, info_addresses : action.payload.data, info_addresses_loaded: true};
        case types.NEW_ADDRESS_SUCCESS :
            return {...state, info_addresses : [ ...state.info_addresses, action.payload.data]};
        case types.CHANGE_ADDRESS_SUCCESS :
            let changeAddressObj = state.info_addresses;
            changeAddressObj[changeAddressObj.findIndex(x => x.id === action.payload.data.id)] = action.payload.data;
            return {...state, info_addresses : changeAddressObj};
        case types.DELETE_ADDRESS_SUCCESS :
            let idx = Number(action.meta.previousAction.payload.request.url.split('/')[3]);
            return {...state, info_addresses : state.info_addresses.filter(el => el.id !== idx)};
        case types.BALANACE_INFO :
            return {...state, info_balance : action.payload.data};

        case types.GET_PRODUCTS_RECOMMENDATIONS_SUCCESS :
            return { ...state, productsRecommendations : action.payload.data };
        case types.CHANGE_PRODUCTS_RECOMMENDATIONS_AMOUNT :
            let tempProductsRecommendations = [ ...state.productsRecommendations ],
                tempItemToChange = state.productsRecommendations.find(el => el.offer_id === action.offer_id);
            const amount = tempItemToChange.amount || 1;
            if (action.operation === "add") {
                if (amount < tempItemToChange.quantity) {
                    tempProductsRecommendations.find(el => el.offer_id === action.offer_id).amount = amount + 1;
                }
            } else {
                if (amount > 1) {
                    tempProductsRecommendations.find(el => el.offer_id === action.offer_id).amount =  amount - 1;
                }
            }
            return { ...state, productsRecommendations : tempProductsRecommendations };

        case types.INFO_FAIL :
            return {...state, error : action.error.response.data};
        case types.CHANGE_INFO_FAIL :
            return {...state, error : action.error.response.data};
        case types.INFO_CONTACTS_FAIL :
            return {...state, error : action.error.response.data};
        case types.INFO_ADDRESSES_FAIL :
            return {...state, error : action.error.response.data};
        default:
            return state;
    }
}