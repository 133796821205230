import React, { Component } from "react";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import DefaultButton from "../../../Buttons/DefaultButton/DefaultButton";
import RenderField from "../../../HelperComponents/RenderField/RenderField";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import TooltipMessage from "../../../HelperComponents/TooltipMessage/TooltipMessage";
import SelectComponent from "../../../HelperComponents/SelectComponent/SelectComponent";
import FormControl from "@material-ui/core/FormControl";

import { isArray, hasExtension } from "../../../../helpers/functions";
import { setPriceListInfo } from "../../../../actions/updateRedux";
import {
  getPriceListAddresses,
  postSendFile,
  postValidateOptionalFields,
} from "../../../../actions/priceListActions";

import ErrorIcon from "@material-ui/icons/Error";
import NoteIcon from "../../../../assets/image/note.svg";
import EmailIcon from "../../../../assets/image/email_inactive.svg";
import EmailActiveIcon from "../../../../assets/image/email_active.svg";
import LinkIcon from "../../../../assets/image/link_inactive.svg";
import LinkActiveIcon from "../../../../assets/image/link_active.svg";
import CheckedIcon from "../../../../assets/image/checked.svg";
import UncheckedIcon from "../../../../assets/image/unchecked.svg";
import CopyImg from "../../../../assets/image/copy-img.svg";
import CopiedImg from "../../../../assets/image/copied.svg";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckOff from "../../../../assets/image/checkbox_off.png";
import CheckOn from "../../../../assets/image/checkbox_on.png";

import "./PriceDownload.scss";

class PriceDownload extends Component {
  state = {
    file: null,
    fileError: false,
    address: null,
    address_list: [],
    autoUpdateOption: undefined,
    withLoginAndPassword: false,
    copied: false,
  };

  componentDidMount() {
    const { getPriceListAddresses } = this.props;
    getPriceListAddresses().then((res) => {
      if (res.payload && res.payload.status && res.payload.status === 200) {
        let arr = [];
        res.payload.data.map((el) =>
          arr.push({ label: el.coordinates, value: el.id })
        );
        this.setState({ address_list: arr });
      }
    });
  }

  submitForm = (data) => {
    const {
      postSendFile,
      setPriceListInfo,
      changeTab,
      postValidateOptionalFields,
      setAdditionalInfo,
    } = this.props;
    const {
      file,
      address,
      autoUpdateOption,
      withLoginAndPassword,
    } = this.state;
    const formData = new FormData();
    formData.append("price_list", file);
    let obj = data;
    if (address !== null) {
      obj.representation = address.value;
    }
    if (autoUpdateOption) {
      let additionalData = {};
      if (autoUpdateOption === "email") {
        additionalData = {
          email: data.email,
          subject: data.subject,
          attachment_name: data.attachment_name,
        };
      } else {
        additionalData = {
          link: data.link,
        };
      }
      postValidateOptionalFields(additionalData).then((response) => {
        if (response && response.payload) {
          setAdditionalInfo({
            email: data.email || "",
            subject: data.subject || "",
            attachment_name: data.attachment_name || "",
            link: data.link || "",
            login: data.login || "",
            password: data.password || "",
          });
          postSendFile(formData).then((res) => {
            if (
              res.payload &&
              res.payload.status &&
              res.payload.status === 200
            ) {
              let arrSample = ["", "", "", "", "", ""],
                rows_update = res.payload.data.rows;
              rows_update.map((cell, i) => {
                arrSample.map((el, ii) => {
                  if (!cell[ii] && typeof cell[ii] === "undefined") {
                    cell.push("");
                  }
                });
              });

              obj.rows_update = rows_update;
              setPriceListInfo({ ...res.payload.data, ...obj });
              changeTab(1);
            }
          });
        }
      });
    } else {
      postSendFile(formData).then((res) => {
        if (res.payload && res.payload.status && res.payload.status === 200) {
          let arrSample = ["", "", "", "", "", ""],
            rows_update = res.payload.data.rows;
          rows_update.map((cell, i) => {
            arrSample.map((el, ii) => {
              if (!cell[ii] && typeof cell[ii] === "undefined") {
                cell.push("");
              }
            });
          });

          obj.rows_update = rows_update;
          setPriceListInfo({ ...res.payload.data, ...obj });
          changeTab(1);
        }
      });
    }
  };

  selectFile = () => {
    let el = document.getElementById("file");
    el.click();
  };

  handleChange = (name) => (event) => {
    this.setState({ address: event });
  };

  handleImageChange(e) {
    e.preventDefault();
    this.setState({ fileError: false });
    let file = e.target.files[0];
    if (hasExtension(file.name, [".xls", ".XLS", ".xlsx", ".XLSX", ".csv", ".CSV", ".ods", ".ODS"])) {
      this.setState({ file });
    } else {
      this.setState({ fileError: true, file: null });
    }
  }

  changeAutoUpdateOption = (option) => {
    const { change } = this.props;
    const { autoUpdateOption } = this.state;
    if (option === "email") {
      if (!autoUpdateOption || autoUpdateOption === "link") {
        this.setState({
          autoUpdateOption: option,
          withLoginAndPassword: false,
        });
        // change("link", "");
        // change("login", "");
        // change("password", "");
      } else {
        this.setState({ autoUpdateOption: undefined });
        // change("email", "");
        // change("subject", "");
        // change("attachment_name", "");
      }
    } else {
      if (!autoUpdateOption || autoUpdateOption === "email") {
        this.setState({ autoUpdateOption: option });
        // change("email", "");
        // change("subject", "");
        // change("attachment_name", "");
      } else {
        this.setState({
          autoUpdateOption: undefined,
          withLoginAndPassword: false,
        });
        // change("link", "");
        // change("login", "");
        // change("password", "");
      }
    }
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      valid,
      price_list: { error_price },
      email,
      login,
      password,
      link,
      accounting,
      changeAccounting,
    } = this.props;
    const {
      file,
      fileError,
      address_list,
      address,
      autoUpdateOption,
      withLoginAndPassword,
      copied,
    } = this.state;
    return (
      <div className="price-download">
        <div className="download-file">
          <div className={`download ${fileError ? "download_error" : ""}`}>
            {file !== null ? <span>{file.name}</span> : "Выберите файл"}
            <input
              id="file"
              type="file"
              onChange={(e) => this.handleImageChange(e)}
            />
          </div>
          <DefaultButton variant="contained" onClick={this.selectFile}>
            Загрузить
          </DefaultButton>
          <div className="download-file_error flex-center">
            {fileError ? (
              <TooltipMessage
                text="Select correct file extension"
                delay={200}
                error
              >
                <ErrorIcon />
              </TooltipMessage>
            ) : (
              ""
            )}
          </div>
        </div>
        <form className="price-form" onSubmit={handleSubmit(this.submitForm)}>
          <div className="price-field">
            <Field
              name="name"
              type="text"
              component={RenderField}
              label="Название прайс-листа"
            />
            <FormControl className="custom_input_wrapper">
              <SelectComponent
                value={address}
                options={address_list}
                loading={!isArray(address_list)}
                change={this.handleChange("address")}
                placeholder="Представительства"
              />
            </FormControl>
          </div>
          <div className="check_field_wrapper price-check">
            <FormControlLabel
              control={
                <Checkbox
                  checked={accounting}
                  onChange={() => {
                    changeAccounting();
                  }}
                  // value={input.name}
                  checkedIcon={<img src={CheckOn} alt="check on" />}
                  icon={<img src={CheckOff} alt="check off" />}
                  classes={{
                    root: "custom_check",
                  }}
                />
              }
              label={"Учёт"}
            />
          </div>
          <p className="price-desc">
            Для прайс-листов с типом «Учёт» недоступно обновление прайс-листа
            путём загрузки excel-файлов
          </p>
          <div
            className={`auto_update_wrapper${
              accounting ? " accounting-disabled" : ""
            }`}
          >
            <div className="title">Автоматическое обновление прайс-листа</div>
            <div className="own-flex-wrapper">
              <div className="auto_update_option">
                <div
                  className="clickable_area"
                  onClick={() => this.changeAutoUpdateOption("email")}
                >
                  <img
                    src={
                      autoUpdateOption === "email" ? EmailActiveIcon : EmailIcon
                    }
                    alt="by-email"
                  />
                  <div className="option_name">
                    <div
                      className={autoUpdateOption === "email" ? "active" : ""}
                    >
                      Настроить автоматическое обновление по e-mail
                    </div>
                    <p>
                      Прайс-лист нужно отправлять на электронный адрес{" "}
                      <span>pricelist@mexcar.ru</span>
                    </p>
                  </div>
                </div>
                {autoUpdateOption === "email" && (
                  <div className="fields_email">
                    <div className="first_row">
                      <Field
                        name="email"
                        type="text"
                        component={RenderField}
                        label={
                          <p>
                            Электронный адрес
                            <span
                              style={{
                                color: "#DC0202",
                              }}
                            >
                              *
                            </span>
                          </p>
                        }
                      />
                      <Field
                        name="subject"
                        type="text"
                        component={RenderField}
                        label="Тема письма"
                      />
                    </div>
                    <div className="second_row">
                      <Field
                        name="attachment_name"
                        type="text"
                        component={RenderField}
                        label="Название файла"
                      />
                      <div>
                        <img src={NoteIcon} alt="no-file-extension" />
                        Введите название файла без указания формата
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`copy-div${copied ? " green-text" : ""}`}
                onClick={() => {
                  if (copied) return;
                  navigator.clipboard.writeText("pricelist@mexcar.ru");
                  this.setState({ copied: true });
                  setTimeout(() => {
                    this.setState({ copied: false });
                  }, 3000);
                }}
              >
                {copied ? (
                  <>
                    <img src={CopiedImg} />
                    Скопировано
                  </>
                ) : (
                  <>
                    <img src={CopyImg} />
                    Скопировать электронный адрес
                  </>
                )}
              </div>
            </div>
            <div className="auto_update_option">
              <div
                className="clickable_area"
                onClick={() => this.changeAutoUpdateOption("link")}
              >
                <img
                  src={autoUpdateOption === "link" ? LinkActiveIcon : LinkIcon}
                  alt="by-link"
                />
                <div className="option_name">
                  <div className={autoUpdateOption === "link" ? "active" : ""}>
                    Настроить автоматическое обновление по ссылке
                  </div>
                </div>
              </div>
              {autoUpdateOption === "link" && (
                <div className="fields_link">
                  <div className="link_field">
                    <Field
                      name="link"
                      type="text"
                      component={RenderField}
                      label="Ссылка"
                    />
                  </div>
                  <div
                    className="with_login_and_password"
                    onClick={() =>
                      this.setState({
                        withLoginAndPassword: !withLoginAndPassword,
                      })
                    }
                  >
                    <img
                      src={withLoginAndPassword ? CheckedIcon : UncheckedIcon}
                      alt="with-login"
                    />
                    Указать логин/пароль для доступа к файлу
                  </div>
                  <div
                    className={`login_and_password_fields ${withLoginAndPassword &&
                      "active"}`}
                  >
                    <Field
                      name="login"
                      type="text"
                      component={RenderField}
                      label="Логин"
                      disabled={!withLoginAndPassword}
                    />
                    <Field
                      name="password"
                      type="password"
                      component={RenderField}
                      label="Пароль"
                      password
                      disabled={!withLoginAndPassword}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="btn-wrapper">
            <DefaultButton
              variant="outlined"
              classes="cancel_btn"
              type="link"
              to="/admin"
            >
              Отмена
            </DefaultButton>
            <DefaultButton
              variant="contained"
              disabled={
                submitting ||
                pristine ||
                !valid ||
                file === null ||
                address === null ||
                (autoUpdateOption === "email" && !email) ||
                (autoUpdateOption === "link" &&
                  ((withLoginAndPassword && (!link || !login)) ||
                    (!withLoginAndPassword && !link)))
              }
              formAction
            >
              Продолжить
            </DefaultButton>
            {error_price &&
            Object.keys(error_price).length !== 0 &&
            error_price.hasOwnProperty("price_list") ? (
              <div className="auth_error error_after_button">
                <ErrorIcon />
                <span>{error_price.price_list}</span>
              </div>
            ) : null}
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  } else if (values.name.length < 3) {
    errors.name = "Must be 3 characters or more";
  } else if (values.name.length > 98) {
    errors.name = "Must be less 100 characters";
  }
  if (!values.representation) {
    errors.representation = "Required";
  }
  return errors;
};

PriceDownload = reduxForm({
  form: "AddPriceDownload",
  validate,
  enableReinitialize: true,
})(PriceDownload);

const selector = formValueSelector("AddPriceDownload");

PriceDownload = connect((state) => {
  const email = selector(state, "email");
  const login = selector(state, "login");
  const password = selector(state, "password");
  const link = selector(state, "link");
  return {
    email,
    login,
    password,
    link,
  };
})(PriceDownload);

function mapStateToProps(state) {
  return {
    price_list: state.price_list,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPriceListAddresses,
      postSendFile,
      setPriceListInfo,
      postValidateOptionalFields,
      change,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceDownload);
