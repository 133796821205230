import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import BackLink from "../../BackLink/BackLink";
import delete_cross from "../../../assets/image/delete_cross.svg";
import Up from "../../../assets/image/up.png";
import SelectComponent from "../../../components/HelperComponents/SelectComponent/SelectComponent";
import TransitionedBlock from '../../../components/HelperComponents/TransitionedBlock/TransitionedBlock';
import SnackBar from '../../../components/HelperComponents/SnackBar/SnackBar';

import {
  getPriceList,
  getProducerList,
  addPriceList,
  deletePriceList,
  updatePriceList,
  getPriceListInfo,
  getLinkPriceList,
  changeValueOffers,
  deleteOffers,
  addOffers,
  searchOffers,
  deleteAdded,
  changeValueAdded,
} from "./ListActions";
import { ClickAwayListener, Portal } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from "../../../assets/image/add.png";
import SearchIcon from "../../../assets/image/search_icon.svg";
import { useParams } from "react-router-dom";
import Loader from "../../HelperComponents/Loader/Loader";
import { ToastContainer } from "react-toastify";
import { notifyError } from "../../../helpers/toastEmitter";
import "react-toastify/dist/ReactToastify.css";
import "./PriceInner.scss";

// import { compose } from "redux";
// import InfiniteLoader from "react-window-infinite-loader";
// import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { List } from "react-virtualized";
import "react-virtualized/styles.css";

const PriceInner = (props) => {
  const dispatch = useDispatch();
  const {
    list,
    searched,
    addedOffers,
    producersList,
    priceListInfo,
    loading,
    addedError,
  } = useSelector(({ innerPriceList }) => innerPriceList);

  const link = useRef();
  const additionalWidth = 40;
  const [pageElementCount, setPageElementCount] = useState(25);
  const [success, setSuccess] = useState(false);
  const rowSize = 30;

  const names = {
    article_number: "Артикул",
    producer_name: "Производитель",
    product_name: "Наименование",
    amount: "Кол-во",
    location: "Место хранения",
    purchase_price: "Цена закуп.",
    price: "Цена розн.",
    wholesale_price: "Цена опт.",
    lf_price: "Цена LF",
  };

  const [valueSearch, setValueSearch] = useState("");

  const { id } = useParams();

  const [handleError, setHandleError] = useState({
    article_number: { empty: false, required: true },
    amount: {
      empty: false,
      required: true,
      isNumber: true,
      valueIsNumber: false,
      valueIsNull: true,
    },
    price: {
      empty: false,
      required: true,
      isNumber: true,
      valueIsNumber: false,
      valueIsNull: true,
    },
    producer_id: { empty: false, required: true },
    product_name: { empty: false, required: true },
    location: { empty: false, required: false },
    purchase_price: {
      empty: false,
      required: false,
      isNumber: true,
      valueIsNumber: false,
      valueIsNull: true,
    },
    wholesale_price: {
      empty: false,
      required: false,
      isNumber: true,
      valueIsNumber: false,
      valueIsNull: true,
    },
    lf_price: {
      empty: false,
      required: false,
      isNumber: true,
      valueIsNumber: false,
      valueIsNull: true,
    },
  });

  const [touched, setTouched] = useState(false);

  const [chooseProducer, setChooseProducer] = useState(null);

  const prevRef = useRef();

  const [chooseAddedProducer, setChooseAddedProducer] = useState(null);

  const sendData = (event, colInfo) => {
    return {
      create_offers: event === "create_offers" ? [colInfo] : [],
      update_offers: event === "update_offers" ? [colInfo] : [],
      delete_offers: event === "delete_offers" ? [colInfo] : [],
    };
  };

  const offersDelete = (idx, idxSearched) => {
    dispatch(deletePriceList(id, { offer_id: list[idx].id }));
    dispatch(deleteOffers(idx));
    if (searched.length > 0) {
      searched.splice(idxSearched, 1);
    }
  };

  const deletingAdded = (idx) => {
    dispatch(deleteAdded(idx));
  };

  const offersCreate = () => {
    dispatch(addOffers(list));
  };

  const handleProducer = (idx) => {
    setChooseProducer((prev) => (prev === idx ? false : idx));
  };

  const handleAddedProducer = (idx) => {
    setChooseAddedProducer((prev) => (prev === idx ? false : idx));
  };

  const handleChangeValue = (rowIndex, colName, value, producerId) => {
    dispatch(changeValueOffers(rowIndex, colName, value, producerId));
  };

  const handleChangeValueAdded = (
    AddedRowIndex,
    AddedColName,
    valueAdded,
    AddedproducerId
  ) => {
    const constrolValueAdded =
      (AddedColName === "lf_price" ||
        AddedColName === "purchase_price" ||
        AddedColName === "wholesale_price") &&
      valueAdded === ""
        ? null
        : valueAdded;

    dispatch(
      changeValueAdded(
        AddedRowIndex,
        AddedColName,
        constrolValueAdded,
        AddedproducerId
      )
    );
  };

  const handleChangeSearch = (value) => {
    setValueSearch(value);
    dispatch(searchOffers(value));
  };

  const sendValue = (rowIndex, colName, id) => {
    if (
      (/^[1-9][\.\d]*(,\d+)?$/.test(list[rowIndex].amount) ||
        /[aeiou]/gi.test(list[rowIndex].amount)) &&
      (/^[1-9][\.\d]*(,\d+)?$/.test(list[rowIndex].lf_price) ||
        /[aeiou]/gi.test(list[rowIndex].lf_price)) &&
      (/^[1-9][\.\d]*(,\d+)?$/.test(list[rowIndex].price) ||
        /[aeiou]/gi.test(list[rowIndex].price)) &&
      (/^[1-9][\.\d]*(,\d+)?$/.test(list[rowIndex].purchase_price) ||
        /[aeiou]/gi.test(list[rowIndex].purchase_price))
    ) {
      dispatch(updatePriceList(id, list[rowIndex]));
    } else {
      notifyError("Введите числовое значение в поле");
    }
  };

  const isNumber = (value) => {
    return /^[1-9][\.\d]*(,\d+)?$/.test(value);
  };

  const isNull = (value) => {
    return /^[1-9][\.\d]*(,\d+)?$/.test(value);
  };

  const addNewOffer = (rowIndex, colName, id, colIndex) => {
    if (
      addedOffers[rowIndex].amount &&
      // (/^[1-9][\.\d]*(,\d+)?$/.test(addedOffers[rowIndex].amount) ||
      //   /[aeiou]/gi.test(addedOffers[rowIndex].amount)) &&
      // (/^[1-9][\.\d]*(,\d+)?$/.test(addedOffers[rowIndex].lf_price) ||
      //   /[aeiou]/gi.test(addedOffers[rowIndex].lf_price) ||
      //   addedOffers[rowIndex].lf_price === "") &&
      // (/^[1-9][\.\d]*(,\d+)?$/.test(addedOffers[rowIndex].price) ||
      //   /[aeiou]/gi.test(addedOffers[rowIndex].price) ||
      //   addedOffers[rowIndex].price === "") &&
      // (/^[1-9][\.\d]*(,\d+)?$/.test(addedOffers[rowIndex].purchase_price) ||
      //   /[aeiou]/gi.test(addedOffers[rowIndex].purchase_price) ||
      //   addedOffers[rowIndex].purchase_price === "") &&
      addedOffers[rowIndex].article_number &&
      addedOffers[rowIndex].price &&
      addedOffers[rowIndex].producer_id &&
      addedOffers[rowIndex].product_name
    ) {
      dispatch(addPriceList(id, addedOffers[rowIndex]));
      setHandleError({
        article_number: { empty: false, required: true },
        amount: {
          empty: false,
          required: true,
          isNumber: true,
          valueIsNumber: false,
          valueIsNull: true,
        },
        price: {
          empty: false,
          required: true,
          isNumber: true,
          valueIsNumber: false,
          valueIsNull: true,
        },
        producer_id: { empty: false, required: true },
        product_name: { empty: false, required: true },
        location: { empty: false, required: false },
        purchase_price: {
          empty: false,
          required: false,
          isNumber: true,
          valueIsNumber: false,
          valueIsNull: true,
        },
        wholesale_price: {
          empty: false,
          required: false,
          isNumber: true,
          valueIsNumber: false,
          valueIsNull: true,
        },
        lf_price: {
          empty: false,
          required: false,
          isNumber: true,
          valueIsNumber: false,
          valueIsNull: true,
        },
      });
      setTouched(false);
    }
  };

  const highlightControl = (text, key) => {
    // подправить здесь
    let highlightText = `${!!text ? text : ""}`.split("");
    let startSlice = `${!!text ? text : ""}`
      .toLowerCase()
      .indexOf(valueSearch.toLowerCase());
    let searchFragment = highlightText
      .slice(startSlice, startSlice + valueSearch.length)
      .join("");
    startSlice !== -1 &&
      highlightText.splice(
        `${!!text ? text : ""}`
          .toLowerCase()
          .indexOf(searchFragment.toLowerCase()),
        searchFragment.length,
        "<span>" + searchFragment + "</span>"
      );
    return highlightText.join("");
  };

  const getDownloadLink = async() => {
    const response = await dispatch(getLinkPriceList(props.match.params.id))
    link.current = response.payload.data.link
  }
  const downloadExcel = async() => {
    await getDownloadLink()
    window.open(`${link.current}`, 'download')
  }
  const copyLink = async() => {
    if (link.current === undefined) {
      await getDownloadLink()
    }
    navigator.clipboard.writeText(link.current)
    setSuccess(true)
  }
  const toggleSnack = () => {
    setSuccess(false)
  };
  const createOffersList = (arr) =>
    arr.map((el, idx) => (
      <div className="row new" key={idx}>
        {Object.keys(names).map((key, colIndex) =>
          key !== "producer_name" ? (
            <div
              className={`col ${
                !handleError[key].empty && handleError[key].required && touched
                  ? "error"
                  : ""
              }`}
              key={colIndex}
            >
              <input
                className="add_field"
                type={
                  key === "amount" ||
                  key === "price" ||
                  key === "purchase_price" ||
                  key === "wholesale_price" ||
                  key === "lf_price"
                    ? "number"
                    : "text"
                }
                onChange={(e) => {
                  handleChangeValueAdded(idx, key, e.target.value);
                }}
                value={el[key]}
                onBlur={(e) => {
                  setTouched(true);
                  setHandleError({
                    ...handleError,
                    [key]: {
                      ...handleError[key],
                      index: colIndex,
                      empty: e.target.value,
                      valueIsNumber: isNumber(e.target.value),
                      valueIsNull: isNull(e.target.value),
                    },
                  });
                  addNewOffer(idx, key, id, colIndex);
                  // e.target.classList.toggle("visible");
                  // e.target.nextElementSibling.classList.toggle("visible");
                }}
              />
              {/* <div
                onClick={(e) => {
                  e.target.classList.toggle("visible");
                  e.target.previousElementSibling.classList.toggle("visible");
                  e.target.previousElementSibling.focus();
                }}
                className={`search_highlight visible ${
                  !!handleError ? "error" : ""
                }`}
                dangerouslySetInnerHTML={{
                  __html: el[key],
                }}
              /> */}
            </div>
          ) : (
            <div
              className={`col ${
                !handleError["producer_id"].empty &&
                handleError["producer_id"].required &&
                touched
                  ? "error"
                  : ""
              }`}
              key={colIndex}
            >
              {/* <p
                onClick={() => handleProducer(idx)}
                className="producer_name"
              >
                {el[key]}
              </p> */}
              <div
                onClick={() => {
                  handleAddedProducer(idx);
                }}
                className={`search_highlight visible`}
                dangerouslySetInnerHTML={{
                  __html:
                    highlightControl(el[key]).length > 0
                      ? highlightControl(el[key])
                      : el[key],
                }}
              />
              {chooseAddedProducer === idx ? (
                <ClickAwayListener
                  onClickAway={() => handleAddedProducer(null)}
                >
                  <div>
                    <SelectComponent
                      options={producersList}
                      isSearchable
                      withSearchIcon={true}
                      menuIsOpen={true}
                      placeholder={"Введите название..."}
                      blur={(e) => {
                        sendValue(idx, key, id);
                        console.log(handleError["product_name"].empty);
                      }}
                      change={(e) => {
                        handleAddedProducer(idx);
                        handleChangeValueAdded(
                          idx,
                          key,
                          e ? e.label : "",
                          e ? e.value : ""
                        );
                        addNewOffer(idx, key, id);

                        setTouched(true);
                        setHandleError({
                          ...handleError,
                          producer_id: {
                            ...handleError["producer_id"],
                            index: colIndex,
                            empty: e.label,
                          },
                        });
                      }}
                    />
                  </div>
                </ClickAwayListener>
              ) : null}
            </div>
          )
        )}
        <div className="col-btn">
          <button onClick={() => deletingAdded(idx)} className="delete_btn">
            <img src={delete_cross} alt="delete_cross" />
          </button>
        </div>
      </div>
    ));

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    let el = valueSearch.length > 0 ? searched[index] : list[index],
      idx = index;
    let idxFromAllList = list.findIndex((item) => el.id === item.id)
    let idxFromSearched = searched.findIndex((item) => el.id === item.id)
    return (
      <div
        className="row"
        key={key}
        style={{ ...style, width: `calc(100% - ${additionalWidth}px)` }}
      >
        {Object.keys(names).map((key, index) =>
          key !== "producer_name" ? (
            <div className="col" key={index}>
              <input
                disabled={`${key === "product_name" ? "disabled" : ""}`}
                className="field"
                type={
                  key === "amount" ||
                  key === "price" ||
                  key === "purchase_price" ||
                  key === "wholesale_price" ||
                  key === "lf_price"
                    ? "number"
                    : "text"
                }
                onChange={(e) => {
                  handleChangeValue(idxFromAllList, key, e.target.value);
                }}
                onInput={(e) => {if (key === 'amount') {e.target.value = e.target.value.replace(/[^\d]/g, '')}}}
                value={!!el[key] ? el[key] : ""}
                onBlur={(e) => {
                  sendValue(idxFromAllList, key, id);
                  e.target.classList.toggle("visible");
                  e.target.nextElementSibling.classList.toggle("visible");
                }}
              />
              <div
                onClick={(e) => {
                  e.target.classList.toggle("visible");
                  e.target.previousElementSibling.classList.toggle("visible");
                  e.target.previousElementSibling.focus();
                }}
                className={`search_highlight visible`}
                dangerouslySetInnerHTML={{
                  __html:
                    highlightControl(el[key]).length > 0
                      ? highlightControl(el[key], key)
                      : el[key],
                }}
              />
            </div>
          ) : (
            <div className="col" key={index}>
              {/* <p
                    onClick={() => handleProducer(idx)}
                    className="producer_name"
                  >
                    {el[key]}
                  </p> */}
              <div
                onClick={() => {
                  handleProducer(idxFromAllList);
                }}
                className={`search_highlight visible`}
                dangerouslySetInnerHTML={{
                  __html:
                    highlightControl(el[key]).length > 0
                      ? highlightControl(el[key])
                      : el[key],
                }}
              />
              {chooseProducer === idxFromAllList ? (
                <ClickAwayListener onClickAway={() => handleProducer(null)}>
                  <div>
                    <SelectComponent
                      options={producersList}
                      isSearchable={true}
                      withSearchIcon={true}
                      menuIsOpen={true}
                      placeholder={"Введите название..."}
                      blur={() => {
                        sendValue(idxFromAllList, key, id);
                      }}
                      change={(e) => {
                        handleProducer(idxFromAllList);
                        handleChangeValue(
                          idxFromAllList,
                          key,
                          e ? e.label : "",
                          e ? e.value : ""
                        );
                        sendValue(idxFromAllList, key, id);
                        if (searched.length > 0) handleProducer(null);
                      }}
                    />
                  </div>
                </ClickAwayListener>
              ) : null}
            </div>
          )
        )}
        <div className="col-btn">
          <button onClick={() => offersDelete(idxFromAllList, idxFromSearched)} className="delete_btn">
            <img src={delete_cross} alt="delete_cross" />
          </button>
        </div>
      </div>
    );
  }

  // const [scrollGoing, setScrollGoing] = useState();

  // const onScroll = (e) => {
  //   setScrollGoing(e.scrollTop);
  // };

  const [height, setHeight] = useState(30);
  const [backupHeight, setBackupHeight] = useState([]);
  const [resultHeight, setResultHeight] = useState();
  const isAlreadyOpen = () => prevRef.current === null;
  useEffect(
    () => {
      let nodeArr = [
        document.querySelector(
          ".price_inner-content .table .table_body .ReactVirtualized__Grid.ReactVirtualized__List"
        ),
        document.querySelector(
          ".price_inner-content .table .table_body .ReactVirtualized__Grid__innerScrollContainer"
        ),
      ];

      if (chooseProducer !== null) {
        if (isAlreadyOpen()) {
          setBackupHeight(nodeArr.map((el) => el.style.height));
          if (
            (searched.length < pageElementCount && valueSearch.length > 0) ||
            list.length < pageElementCount
          ) {
            nodeArr.forEach((el) => {
              el.style.height = `calc(${
                document.querySelector(".select__menu").clientHeight
              }px + ${el.style.height})`;
              el.style.maxHeight = `calc(${
                document.querySelector(".select__menu").clientHeight
              }px + ${el.style.maxHeight})`;
            });
          } else {
            nodeArr[1].style.height = `calc(${
              document.querySelector(".select__menu").clientHeight
            }px + ${nodeArr[1].style.height})`;
            nodeArr[1].style.maxHeight = `calc(${
              document.querySelector(".select__menu").clientHeight
            }px + ${nodeArr[1].style.maxHeight})`;
            nodeArr[0].scrollTo({
              top:
                document
                  .querySelector(".select__menu")
                  .closest(".row")
                  .style.top.split("px")[0] -
                nodeArr[0].offsetHeight / 3,
              behavior: "smooth",
            });
          }
        } 
        // else {
        //   nodeArr[0].scrollTo({
        //     top:
        //       document
        //         .querySelector(".select__menu")
        //         .closest(".row")
        //         .style.top.split("px")[0] -
        //       nodeArr[0].offsetHeight / 3,
        //     behavior: "smooth",
        //   });
        // }
      } else {
        if (nodeArr.every((el) => el !== null)) {
          if (
            (searched.length < pageElementCount && valueSearch.length > 0) ||
            list.length < pageElementCount
          ) {
            nodeArr.forEach((el, idx) => {
              el.style.height = `${backupHeight[idx]}`;
              // el.style.maxHeight = `${backupHeight[idx]}`;
            });
          } else {
            nodeArr[1].style.height = `${backupHeight[1]}`;
            // nodeArr[1].style.maxHeight = `${backupHeight[1]}`;
          }
        }
      }
      prevRef.current = chooseProducer;
    },
    [chooseProducer]
  );

  useEffect(
    () => {
      tableHeight();
    },
    [searched, list, valueSearch, pageElementCount, addedOffers]
  );

  const tableHeight = (newOffer = 0) => {
    let tempHeight = 0;
    if (searched.length < pageElementCount && valueSearch.length > 0) {
      tempHeight = searched.length * rowSize;
    } else if (list.length < pageElementCount) {
      tempHeight = list.length * rowSize;
    } else {
      tempHeight = pageElementCount * rowSize;
    }
    setHeight(tempHeight + newOffer);
  };

  const renderList = () => (
    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          width={width + additionalWidth}
          height={
            // (searched.length < pageElementCount && valueSearch.length > 0) ||
            // list.length < pageElementCount
            //   ? searched.length > pageElementCount
            //     ? searched.length * rowSize
            //     : list.length < pageElementCount
            //   : pageElementCount * rowSize

            // searched.length < pageElementCount && valueSearch.length > 0
            //   ? searched.length * rowSize
            //   : pageElementCount * rowSize
            height
          }
          rowCount={valueSearch.length > 0 ? searched.length : list.length}
          rowHeight={30}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );

  useEffect(
    () => {
      dispatch(getPriceList(props.match.params.id));
      dispatch(getProducerList());
      dispatch(getPriceListInfo(id));
    },
    [props.match.params.id]
  );

  // useEffect(() => {
  //   .addEventListener("scroll", (e) => {
  //     if (Math.floor(window.pageYOffset) > 600) console.log(window.pageYOffset);
  //   });
  // }, []);

  return (
    <div className="container">
      <div className="title-block">
        <BackLink to="/admin/price-list" />
        <h1>{priceListInfo.name}</h1>
      </div>
      {loading ? (
        <div className="price_inner-content" id="price_inner-content">
          <div className="search_wrapper_price">
            <div className="search_wrapper">
              <input
                type="text"
                placeholder="Поиск по артикулу, производителю, наименованию, месту хранения…"
                value={valueSearch}
                onChange={(e) => handleChangeSearch(e.target.value)}
              />
              <div
                className="search_input_icon"
                onClick={() => {
                  dispatch(searchOffers(valueSearch));
                }}
              >
                <img src={SearchIcon} alt="SearchIcon" />
              </div>
            </div>
            <ToastContainer />
            <div className="price_inner__buttons">
              {list.length > 0 &&
                <Button
                  className="price_inner__btn-download"
                  classes={{ root: "add_btn" }}
                  onClick={() => copyLink()}  
                >
                  Скопировать ссылку
                </Button>
              }
              {list.length > 0 &&
                <Button
                  className="price_inner__btn-download"
                  classes={{ root: "add_btn" }}
                  onClick={() => downloadExcel()}  
                >
                  Скачать в Excel
                </Button>
              }
              <Button
                onClick={() => {
                  addedOffers.length > 0
                    ? notifyError("Заполните или удалите текущее предложение")
                    : offersCreate();
                }}
                disableRipple={true}
                classes={{ root: "add_btn" }}
              >
                Добавить
                <img src={AddIcon} alt="edit icon" />
              </Button>
            </div>
          </div>
          <TransitionedBlock>
              <SnackBar
                  open={success}
                  classes="success"
                  vertical="top"
                  onClose={() => toggleSnack()}
              >
                  Скопировано
              </SnackBar>
            </TransitionedBlock>

          <div className={`table`}>
            <div className="table_head">
              {Object.keys(names).map((el, idx) => (
                <div className="col" key={idx}>
                  {names[el]}
                </div>
              ))}
            </div>

            <div className="table_body">
              {createOffersList(addedOffers)}
              {(valueSearch.length > 0 && searched.length <= 0) ||
              list.length <= 0 ? (
                <div className="no_item">Товары не найдены</div>
              ) : (
                renderList()
              )}
            </div>
            {/* {scrollGoing >= 600 ? (
              <button className="up-btn">
                НАВЕРХ <img src={Up} alt="Up" />
              </button>
            ) : null} */}
          </div>
        </div>
      ) : (
        <div className="loader_wrapper_center">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default PriceInner;
