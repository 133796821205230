import React from 'react';
import TextField from '@material-ui/core/TextField';
import './RenderTimeField.scss';

const RenderTimeField = ({ input, placeholder, label, autoFocus, symbol, disabled, meta: {touched, error} }) => {
    return (
        <div className="custom_time_field">
            <TextField
                {...input}
                type="time"
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 300, // 5 min
                }}
            />
        </div>
    );
};

export default RenderTimeField;