import React from 'react';
import Badge from '@material-ui/core/Badge';
import './Badged.scss';

const Badged = ({invisible = false, classes = 'notification_badge', children}) => {
    return (
        <Badge
            invisible={invisible}
            variant="dot"
            classes={{
                badge: `custom_badge ${classes}`
            }}
        >
            {children}
        </Badge>
    );
};

export default Badged;