import React, {Component} from 'react';
import TransitionedBlock from '../../HelperComponents/TransitionedBlock/TransitionedBlock';
import Pagination from '../../HelperComponents/Pagination/Pagination';
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";
import NoItems from '../../../assets/image/no_orders_3.png';
import AddIcon from '../../../assets/image/add.png';
import {getMyRequests} from "../../../actions/PurchasesActions";

import './Requests.scss';
import {connect} from "react-redux";
import RequestsItem from './RequestsItem/RequestsItem';
import {SuccessMessage} from "../../HelperComponents/ToastMessages/ToastMessage";
import PanelPage from '../../HelperComponents/PanelPage/PanelPage';

class Requests extends Component {

    state = {
        activePage: 1,
        hasResults: false,
        hasSearchResults: false,
        items: [],
        items_count: 0,
        success: false,
        valueSearch: '',
    };

    changePage = (pageNumber) => {
        this.setState({activePage: pageNumber});
        setTimeout(() => {
            this.doRequest();
        }, 0);
    };

    componentDidMount() {
        let page = this.state.activePage;
        this.setState({success: this.props.success});
        setTimeout(() => {
            this.getMyRequests(page);
        }, 0);
    }

    toggleSnack = () => {
        this.setState(({success}) => ({
            success: false
        }));
    };
    getMyRequests = (page) => {
        let arr = [];
        const {getMyRequests} = this.props;

        arr.push(`page=${page}`);
        arr.push(`page_size=10`);
        getMyRequests(arr).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                if (res.payload.data.results.length === 0) {
                    this.setState({hasResults: false});
                } else {
                    this.setState({
                        hasResults: true,
                        items: res.payload.data.results,
                        items_count: res.payload.data.count
                    });
                }
            }

        });
    };
    doRequest = () => {
        let page = this.state.activePage;
        const {getMyRequests} = this.props;
        const { valueSearch  } = this.state;
        
        let arr = [];

        if (valueSearch !== "") {
            arr.push(`query=${valueSearch}`);
        }

        arr.push(`page=${page}`);
        arr.push(`page_size=10`);

        getMyRequests(arr).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                if (res.payload.data.results.length === 0) {
                    this.setState({hasResults: false, hasSearchResults: false});
                } else {
                    this.setState({
                        hasResults: true,
                        hasSearchResults: true,
                        items: res.payload.data.results,
                        items_count: res.payload.data.count
                    });
                }
            }

        });
    };
    changeValueSearch = e => {
        this.setState({ valueSearch: e.target.value });
    };
    handleSearch = e => {
        if (e.keyCode === 13) {
            this.setState({ activePage: 1 });
            setTimeout(() => {
                this.doRequest();
            }, 0);
        } else if (e === "iconClick") {
            setTimeout(() => {
                this.doRequest();
            }, 0);
        }
    };

    render() {
        const {activePage, items_count, hasResults, items, success, valueSearch, hasSearchResults} = this.state;
        const pageCount = 10;
        return (
            <TransitionedBlock>
                <div className="requests">
                    <div className="flex justify-content-between">
                        <Button
                            component={Link}
                            to="/admin/requests-add"
                            disableRipple={true}
                            classes={{
                                root: 'add_btn'
                            }}

                        >
                            <img src={AddIcon} alt="edit icon"/>
                            Создать заявку
                        </Button>
                        <PanelPage
                            doRequest={this.doRequest}
                            changeValueSearch={this.changeValueSearch}
                            handleSearch={this.handleSearch}
                            valueSeacrh={valueSearch}
                        />
                    </div>
                    <div className="requests_table">
                        {hasResults ?
                            <div className="transactions_columns table_container">
                                <div className="table_header">
                                    <div className="table_row">
                                        <div className="row_orders">
                                            <div className="row_item">Наименование</div>
                                            <div className="row_item">Артикул</div>
                                            <div className="row_item">Заказ</div>
                                            <div className="row_item">Производитель</div>
                                            <div className="row_item">Дата/время</div>
                                            <div className="row_item">Статус</div>
                                            <div className="row_item">К-во товара</div>
                                            <div className="row_item">Сумма</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table_body">
                                    {items.map((el, index) => {
                                        return (
                                            <RequestsItem item={el} key={el.id}/>
                                        )
                                    })}


                                </div>
                                <div>
                                    {items_count > pageCount ?
                                        <Pagination
                                            current={activePage}
                                            pageCount={pageCount}
                                            total={items_count}
                                            onChange={this.changePage}
                                        />
                                        : null}
                                </div>
                            </div>
                            :
                            <div className="no_item_orders">
                                <div className="box_img">
                                    <img src={NoItems} alt="Введите артикул товара"/>
                                </div>
                                <div className="title_no_item">{!hasSearchResults ? 'Заявок не найдено' : 'Пока нет ни одной заявки'}</div>
                                {hasSearchResults && <div className="descriptions_no_item">После добавления первой заявки на возврат Вы увидите ее здесь</div>}
                            </div>
                        }

                    </div>
                    {success ?
                        <SuccessMessage close={this.toggleSnack} message={"Заявка на возврат успешно создана"}/> : null}

                </div>
            </TransitionedBlock>

        );
    }
}

const mapDispatchToProps = {
    getMyRequests
};

export default connect(null, mapDispatchToProps)(Requests);