import * as types from "../actions/constants";

const INITIAL_STATE = {
    refund_request: {},

    error: []
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.GET_REFUND_REQUEST_SUCCESS :
            return {...state, refund_request : action.payload.data};

        default:
            return state;
    }
}