import * as types from './constants.jsx';

//user actions

export function getUser() {
    return {
        type: types.USER,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/users/`,
                method: "get"
            }
        }
    };
}

export function getNotifications() {
    return {
        type: types.NOTIFICATION,
        payload: {
            client: 'default',
            request: {
                url: `/notification/producer/`,
                method: "get"
            }
        }
    };
}

export function getInfo() {
    return {
        type: types.INFO,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/totalinfo/`,
                method: "get"
            }
        }
    };
}

export function patchInfo(data) {
    return {
        type: types.CHANGE_INFO,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/totalinfo/`,
                method: "patch",
                data
            }
        }
    };
}

export function getContacts() {
    return {
        type: types.INFO_CONTACTS,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/phones/`,
                method: "get"
            }
        }
    };
}

export function postNewContact(data) {
    return {
        type: types.NEW_CONTACT,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/phones/`,
                method: "post",
                data
            }
        }
    };
}

export function patchContact(id, data) {
    return {
        type: types.CHANGE_CONTACT,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/phone/${id}/`,
                method: "patch",
                data
            }
        }
    };
}

export function deleteContact(id) {
    return {
        type: types.DELETE_CONTACT,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/phone_delete/${id}`,
                method: "delete"
            }
        }
    };
}

export function getAddresses() {
    return {
        type: types.INFO_ADDRESSES,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/addresses/`,
                method: "get"
            }
        }
    };
}

export function getDashboard() {
    return {
        type: types.DASHBOARD,
        payload: {
            client: 'default',
            request: {
                url: `/dashboard/`,
                method: "get"
            }
        }
    };
}

export function patchReviewDashboard(id, data) {
    return {
        type: types.PATCH_REVIEWS_DASHBOARD,
        payload: {
            client: 'default',
            request: {
                url: `/review/${id}/`,
                method: "patch",
                data
            }
        }
    };
}

export function postNewAddress(data) {
    return {
        type: types.NEW_ADDRESS,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/addresses/`,
                method: "post",
                data
            }
        }
    };
}

export function patchAddress(id, data) {
    return {
        type: types.CHANGE_ADDRESS,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/address/${id}/`,
                method: "patch",
                data
            }
        }
    };
}

export function deleteAddress(id) {
    return {
        type: types.DELETE_ADDRESS,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/address_delete/${id}`,
                method: "delete"
            }
        }
    };
}

export function balanceInfo() {
    return {
        type: types.BALANACE_INFO,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/balance-info/`,
                method: "get"
            }
        }
    };
}

export function getProductsRecommendations() {
    return {
        type: types.GET_PRODUCTS_RECOMMENDATIONS,
        payload: {
            client: 'default',
            request: {
                url: `/supplier/products-recommendations/`,
                method: "get"
            }
        }
    };
}

export function changeProductsRecommendationsAmount(operation, offer_id) {
    return {
        type: types.CHANGE_PRODUCTS_RECOMMENDATIONS_AMOUNT,
        operation,
        offer_id
    };
}