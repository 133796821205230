import React from 'react';
import './CommentComponent.scss';
import { formatDate } from '../../../helpers/functions';


const CommentComponent = ({ data }) => {
    return (
        <div className='comment-component'>
            <div className="comment-component__header comment-component-header">
                <div className="comment-component-header__title">{data.from_user}</div>
                <div className="comment-component-header__date date_wrapper">{formatDate(data.pub_date)}</div>
            </div>
            <div className="comment-component__body">
                <div className="">{data.message}</div>
            </div>
        </div>
    );
};

export default CommentComponent;