import React, { Fragment, useEffect, useState } from "react";
import { Field } from "redux-form";
import RenderField from "../RenderField/RenderField";
import RenderCount from "../RenderCount/RenderCount";
import AddProduct from "../../Orders/AddOrders/AddProduct";
import Button from "@material-ui/core/Button";
import AddIcon from "../../../assets/image/add.png";

const RenderOrderProducts = ({
  fields,
  onClick,
  change,
  error,
  meta: { touched },
  downloadFile,
  buttonShown,
}) => {
  const [path, setPath] = useState("");

  useEffect(() => {
    buttonShown && downloadFile(setPath);
  }, []);
  return (
    <Fragment>
      <div className="flex-center-btw">
        <p className="order_section_title">Товар(ы)</p>
        <AddProduct fields={fields} change={change} />
      </div>

      {!fields.length > 0 ? (
        <p className="add_order_info">Пока не добавлено ни одного товара</p>
      ) : (
        fields.map((product, index) => (
          <div
            key={index}
            className={
              !!error &&
              !!error.arr &&
              error.arr.filter((e) => e === index).length === 1
                ? "order_product_item order_product_item_error"
                : "order_product_item"
            }
          >
            <Field
              name={`${product}.info`}
              type="text"
              disabled
              component={RenderField}
              label="Сведения о товаре"
            />
            <Field
              name={`${product}.count`}
              component={RenderCount}
              disabled
              change={change}
              label="Количество"
            />
            {/* <Button
                        classes={{
                            root: "red_btn ",
                        }}
                        disableRipple={true}
                        onClick={() => {
                            fields.remove(index);
                        }}
                    >
                        Удалить
                    </Button> */}
          </div>
        ))
      )}
      {/* <button
                className="download_xlsx"
                onClick={(e) => {
                    e.preventDefault();
                    downloadFile();
                }}
            >
                Скачать XLSX-файл
            </button> */}
      {buttonShown && (
        <a href={path} download className="download_xlsx">
          Скачать XLSX-файл
        </a>
      )}
    </Fragment>
  );
};

export default RenderOrderProducts;
