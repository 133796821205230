import React, { Component } from "react";
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RenderField from "../../../HelperComponents/RenderField/RenderField";
import DialogComponent from "../../../HelperComponents/DialogComponent/DialogComponent";

import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DefaultButton from "../../../Buttons/DefaultButton/DefaultButton";
import RenderSteppedPriceField from "../../../HelperComponents/RenderSteppedPriceField/RenderSteppedPriceField";
import SelectComponent from "../../../HelperComponents/SelectComponent/SelectComponent";
import FormControl from "@material-ui/core/FormControl";

import { postCreatePriceList } from "../../../../actions/priceListActions";

import RadioOff from "../../../../assets/image/radio_off.png";
import RadioOn from "../../../../assets/image/radio_on.png";

import "./PriceSettings.scss";
import { isArray } from "../../../../helpers/functions";
import CloseDialogIcon from "../../../../assets/image/Mask.png";

import IconButton from "@material-ui/core/IconButton";
import Arrow from "../../../../assets/image/arrow.png";

class PriceSettings extends Component {
  state = {
    loading: false,
    type: "miss",
    activeColumn: 1,
    fields_map: [],
    delivery_list: [],
    delivery_item: { label: "Под заказ", value: "custom" },
    deliveryDay: "",
    open: false,
    errorColumn: false,
    tempProducers: {},
    user_producer_list: [],
  };

  componentDidMount() {
    const {
      price_list: { price_list_info },
    } = this.props;
    let arr = [];
    price_list_info.delivery_time.map((el) =>
      arr.push({ value: el[0], label: el[1] })
    );
    this.setState({ delivery_list: arr });
  }

  handleChange = (event) => {
    this.setState({ type: event.target.value });
  };
  handleChangeDelivery = (name) => (event) => {
    this.setState({ delivery_item: event });
  };

  submitForm = (data) => {
    const {
      postCreatePriceList,
      price_list: { price_list_info },
      history,
      additionalInfo,
      accounting,
    } = this.props;
    const { fields_map, type, delivery_item } = this.state;
    this.setState({ errorColumn: false });
    let obj = {
      name: price_list_info.name,
      delivery_time: delivery_item.value,
      delivery_day:
        delivery_item.value === "custom" ? data.delivery_day : undefined,
      markup: type,
      file_name: price_list_info.file_name,
      fields_map: fields_map,
      agency: price_list_info.representation,
      markup_percentage: type === "miss" ? undefined : data.markup_percentage,
      markup_list: data.markup_list.map((el) => [
        Number(el._before),
        Number(el._after),
      ]),
      updating_from_file: !accounting,
      ...additionalInfo,
    };

    if (
      (fields_map.length === 5 &&
        fields_map.findIndex((el) => el[0] === "location") === -1) ||
      fields_map.length === 6
    ) {
      // validation on 5 required fields
      this.setState({ loading: true });
      return postCreatePriceList(obj).then((res) => {
        if (res.payload && res.payload.status && res.payload.status === 201) {
          history.push("/admin/price-list");
        } else {
          let arr2d2 = []; //  Default value undefined producers
          res.error.response.data.undefined_producers.map((e) =>
            arr2d2.push({
              name: e,
              id:
                res.error.response.data.producer_list[
                  res.error.response.data.producer_list.findIndex(
                    (x) => x.name === "Другое"
                  )
                ].id,
            })
          );

          let arrProd = []; //  List of available producers
          res.error.response.data.producer_list.map((el) =>
            arrProd.push({ value: el.id, label: el.name })
          );

          obj.optionsProdusers = arrProd;
          this.setState({
            loading: false,
            open: true,
            tempProducers: obj,
            user_producer_list: arr2d2,
          });

          throw new SubmissionError({
            ...res.error.response.data,
            _error: res.error.response.data.error
              ? res.error.response.data.error
              : "Error.",
          });
        }
      });
    } else {
      this.setState({ errorColumn: true });
    }
  };

  toggleDialog = () => {
    this.setState(({ open }) => ({
      open: !open,
    }));
  };

  handleChangeProducer = (item) => (event) => {
    const { user_producer_list } = this.state;
    let stateTemp = user_producer_list;
    stateTemp[stateTemp.findIndex((x) => x.name === item.name)].id =
      event.value;
    this.setState({ user_producer_list: stateTemp });
  };

  submitNewproducers = async () => {
    const { postCreatePriceList, history } = this.props;
    const { tempProducers, user_producer_list } = this.state;
    this.setState({ open: false, loading: true });
    let newObj = tempProducers;
    delete newObj.optionsProdusers;
    newObj.user_producer_list = user_producer_list;
    await postCreatePriceList(newObj);
    history.push("/admin/price-list");
  };

  changeActiveColumn = (index) => {
    this.setState({ activeColumn: index });
  };
  deliveryDay = (event) => {
    this.setState({ deliveryDay: event.target.value });
  };

  checkBtnStatus = (index, name) => {
    const { activeColumn } = this.state;
    if (activeColumn === index) return "active-btn";
    else if (this.checkValue(name)) return "success-btn";
  };

  selectColumn = (index) => {
    const {
      price_list: {
        price_list_info: { cells },
      },
    } = this.props;
    const { activeColumn, fields_map } = this.state;

    let field_name = cells[activeColumn - 1][0];
    let field = [field_name, index];
    let newIndex =
      cells.findIndex((el) => !this.checkValue(el[0]) && el[0] !== field_name) +
      1;

    let arr = fields_map.filter((el) => el[1] !== index);
    let newIndex2 =
      cells.findIndex(
        (el) =>
          !arr.some((elem) => elem.includes(el[0])) && el[0] !== field_name
      ) + 1;

    if (this.checkValue(field_name)) {
      if (fields_map.filter((el) => el[0] === field_name)[0][1] === index) {
        this.setState({ activeColumn: newIndex2 });
      } else {
        this.setState(({ activeColumn, fields_map }) => ({
          activeColumn: newIndex2,
          fields_map: fields_map
            .filter((el) => el[1] !== index)
            .map((el) => (el[0] === field_name ? field : el)),
        }));
      }
    } else if (this.checkValue(index)) {
      this.setState(({ activeColumn, fields_map }) => ({
        activeColumn: newIndex2,
        fields_map: [...fields_map.filter((el) => el[1] !== index), field],
      }));
    } else {
      this.setState(({ activeColumn, fields_map }) => ({
        activeColumn: newIndex,
        fields_map: [...fields_map, field],
      }));
    }
  };

  checkValue = (value) => {
    const { fields_map } = this.state;
    return fields_map.some((el) => el.includes(value));
  };

  render() {
    const {
      handleSubmit,
      error,
      valid,
      price_list: {
        price_list_info: { rows, cells },
      },
    } = this.props;
    const {
      loading,
      activeColumn,
      type,
      delivery_item,
      delivery_list,
      deliveryDay,
      open,
      tempProducers,
      user_producer_list,
      errorColumn,
    } = this.state;
    return (
      <div className="price-settings">
        <div className="title">Колонки</div>
        <div className="columns-block">
          <div className="columns-title">
            {cells.map((cell, i) => {
              let idx = ++i;
              return (
                <button
                  className={this.checkBtnStatus(idx, cell[0])}
                  key={i}
                  onClick={() => this.changeActiveColumn(idx)}
                >
                  {cell[1]}
                </button>
              );
            })}
          </div>
          <div className="price-table">
            <div className="descriptions">
              Укажите номер столбца, содержащего выбранный параметр товара. Для
              этого выберите заголовок соответствующего столбца, нажав на него
              мышкой.
            </div>
            <div className="scroll_block">
              <div className="columns-table ">
                <div className="flex-center table_row">
                  <div className="row_number">&nbsp;</div>
                  {rows &&
                    rows[0] &&
                    rows[0].map((row, i) => {
                      let idx = ++i;
                      return (
                        <button
                          className="col_btn"
                          disabled={activeColumn === 0}
                          key={i}
                          onClick={() => this.selectColumn(idx)}
                        >
                          {idx}
                        </button>
                      );
                    })}
                </div>
                {rows.map((row, i) => (
                  <div className="flex-center table_row" key={i}>
                    <div className="row_number">{i + 1}</div>
                    {row.map((cell, k) => (
                      <div
                        className="table_cell"
                        key={k}
                        style={{
                          backgroundColor: this.checkValue(k + 1)
                            ? `#EDF2F8`
                            : "transparent",
                        }}
                      >
                        {cell}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <form
          className="settings-form"
          onSubmit={handleSubmit(this.submitForm)}
        >
          <div className="price-field">
            <div className="drop-down">
              {/*<Field name="delivery_time" component={RenderSelectField} label="Срок поставки" labelWidth={80}>*/}
              {/*{delivery_time.map((el, i) => (*/}
              {/*<MenuItem value={el[0]} key={i}>{el[1]}</MenuItem>*/}
              {/*))}*/}
              {/*</Field>*/}
              <FormControl className="select_wrapper">
                <span className="span-label">Срок поставки</span>
                <SelectComponent
                  value={delivery_item}
                  options={delivery_list}
                  loading={!isArray(delivery_list)}
                  change={this.handleChangeDelivery("address")}
                  placeholder="Срок поставки"
                  isClearable
                />
              </FormControl>
            </div>
            {delivery_item.value === "custom" && (
              <Field
                name="delivery_day"
                onChange={this.deliveryDay}
                type="number"
                component={RenderField}
                label="Дней"
              />
            )}
          </div>
          <div className="markup">
            <div className="markup-title">Наценка</div>
            <div>
              <FormControlLabel
                value="miss"
                control={
                  <Radio
                    color="primary"
                    checked={type === "miss"}
                    onChange={this.handleChange}
                    checkedIcon={<img src={RadioOn} alt="radio on" />}
                    icon={<img src={RadioOff} alt="radio off" />}
                  />
                }
                classes={{
                  root: "custom_radio",
                }}
                label="Нет"
                labelPlacement="end"
              />
            </div>
            <FormControlLabel
              value="fixate"
              control={
                <Radio
                  color="primary"
                  checked={type === "fixate"}
                  onChange={this.handleChange}
                  checkedIcon={<img src={RadioOn} alt="radio on" />}
                  icon={<img src={RadioOff} alt="radio off" />}
                />
              }
              label="Фиксированная"
              labelPlacement="end"
              classes={{
                root: "custom_radio",
              }}
            />
            {type === "fixate" && (
              <div className="markup-fixed">
                <Field
                  name="markup_percentage"
                  type="text"
                  component={RenderField}
                  label="Наценка"
                  symbol="%"
                />
              </div>
            )}
            <div>
              <FormControlLabel
                value="stepped"
                control={
                  <Radio
                    color="primary"
                    checked={type === "stepped"}
                    onChange={this.handleChange}
                    checkedIcon={<img src={RadioOn} alt="radio on" />}
                    icon={<img src={RadioOff} alt="radio off" />}
                  />
                }
                label="Ступенчатая"
                labelPlacement="end"
                classes={{
                  root: "custom_radio",
                }}
              />
            </div>
            {type === "stepped" && (
              <FieldArray
                name="markup_list"
                component={RenderSteppedPriceField}
              />
            )}
          </div>
          <div className="btn-wrapper">
            <DefaultButton
              variant="outlined"
              classes="cancel_btn"
              type="link"
              to="/admin/price-list"
            >
              Отмена
            </DefaultButton>
            <DefaultButton
              variant="contained"
              disabled={
                delivery_item.value === "custom" && deliveryDay.length === 0
              }
              loading={loading}
              formAction
            >
              Сохранить
            </DefaultButton>
            {errorColumn ? (
              <p className="auth_error error_after_button">
                Выберите минимимум пять обязательных колонок (артикул,
                наименование, производитель, цена, количество)
              </p>
            ) : error &&
              error !==
                null ? null /*<p className="auth_error error_after_button">{error} </p>*/ : null}
          </div>
        </form>
        <DialogComponent
          open={open}
          // onClose={this.toggleDialog}
          longDialog
        >
          <div className="title-dialog">Найдены несоответствия</div>
          <div className="descriptions">
            В загруженном файле обнаружены названия производителей, которые
            отличаются от имеющихся в базе. Пожалуйста, выберите соответствие из
            предложенных вариантов.
          </div>
          <div className="undefined-producers-wrapper">
            {user_producer_list.length > 0 &&
              !!tempProducers.optionsProdusers &&
              tempProducers.optionsProdusers.length > 0 &&
              user_producer_list.map((elem, index) => (
                <div
                  key={"undefined_producers" + index}
                  className="undefined-producers-item"
                >
                  <input
                    id={"undefined-producers" + index}
                    type="text"
                    value={elem.name}
                    disabled={true}
                    autoComplete="off"
                  />
                  <img src={Arrow} alt="" />
                  <FormControl className="select_wrapper">
                    <SelectComponent
                      value={
                        tempProducers.optionsProdusers[
                          tempProducers.optionsProdusers.findIndex(
                            (x) => x.value === elem.id
                          )
                        ]
                      }
                      options={tempProducers.optionsProdusers}
                      loading={!isArray(tempProducers.optionsProdusers)}
                      change={this.handleChangeProducer(elem)}
                      placeholder=""
                      isClearable
                    />
                  </FormControl>
                </div>
              ))}
          </div>
          <div className="btn-wrapper">
            <DefaultButton
              variant="outlined"
              classes="cancel_btn"
              onClick={this.toggleDialog}
            >
              Отмена
            </DefaultButton>
            <DefaultButton
              variant="contained"
              onClick={this.submitNewproducers}
            >
              Заменить
            </DefaultButton>
          </div>
          <IconButton
            classes={{
              root: "dialog_close_button",
            }}
            onClick={this.toggleDialog}
          >
            <img src={CloseDialogIcon} alt="close icon" />
          </IconButton>
        </DialogComponent>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.markup_fixed) {
    errors.markup_fixed = "Required";
  }
  if (!values.markup_percentage) {
    errors.markup_percentage = "Required";
  }

  if (+values.delivery_day < 1) {
    errors.delivery_day = "Required";
  }

  if (values.markup_list && values.markup_list.length > 0) {
    const markupListErrors = [];
    values.markup_list.forEach((product, memberIndex) => {
      const memberErrors = {};
      if (!product || !product._before) {
        memberErrors._before = "Required";
        markupListErrors[memberIndex] = memberErrors;
      }
      if (!product || !product._after) {
        memberErrors._after = "Required";
        markupListErrors[memberIndex] = memberErrors;
      }
    });
    if (markupListErrors.length) {
      errors.markup_list = markupListErrors;
    }
  }
  return errors;
};

PriceSettings = reduxForm({
  form: "AddPriceList",
  enableReinitialize: true,
  validate,
})(PriceSettings);

const selector = formValueSelector("AddPriceList");

function mapStateToProps(state) {
  const delivery = selector(state, "delivery_time");
  return {
    delivery,
    price_list: state.price_list,
    initialValues: {
      markup_list: [{ _before: 100, _after: 5 }],
      markup_percentage: 5,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postCreatePriceList,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceSettings);
