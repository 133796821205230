import React, { Component } from "react";
import PopperInfo from "../PopperInfo/PopperInfo";
import SearchIcon from "../../../assets/image/search_grey.png";

import "./PanelPage.scss";

class PanelPage extends Component {
    state = {
        open: false,
        openPopper: false,
        valueSearch: "",
    };

    render() {
        const {
            handleSearch,
            changeValueSearch,
        } = this.props;
        const {
            value,
            anchorEl,
            openPopper,
        } = this.state;

        return (
            <div className="panel_page">
                <div className="search_wrapper search_transactions_panel">
                    <div className="search_product_wrapper">
                        <input
                            id="products"
                            type="text"
                            value={value}
                            onKeyDown={(e) => handleSearch(e)}
                            onChange={(e) => changeValueSearch(e)}
                            placeholder="Введите номер заказа или артикул"
                            autoComplete="off"
                        />
                        <PopperInfo
                            id="products_popper"
                            open={openPopper}
                            anchorEl={anchorEl}
                            clickAway={this.clickAway}
                            position="bottom-start"
                            transition
                        >
                            <ul className="search_products_list">
                                <li
                                    className="search_product_item"
                                >
                                    <div className="search_product_item_head">
                                        products_list
                                    </div>
                                </li>
                                {/*<li>Нет товаров</li>*/}
                            </ul>
                        </PopperInfo>
                    </div>
                    <div className="search_input_icon">
                        <img
                            src={SearchIcon}
                            alt="SearchIcon"
                            onClick={() => handleSearch("iconClick")}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default PanelPage;
