import React, { Component } from "react";
import { Fragment } from "react";
import ErrorIcon from "@material-ui/icons/Error";
import TooltipMessage from "../TooltipMessage/TooltipMessage";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import FileIcon from "../../../assets/image/file_icon.png";
import "./UploadFile.scss";

class UploadFile extends Component {
  state = {
    files: [],
  };

  componentDidUpdate(previousProps) {
    if (previousProps.isSending !== this.props.isSending) {
      this.setState({
        files: [],
      });
    }
  }

  handleInputFile = (e) => {
    this.setState({
      files: [],
    });
    e.preventDefault();
    this.setState({
      files: e.target.files,
    });
    let files = e.target.files;
    if (files.length) {
      this.setState({
        files,
      });
    }
    this.props.uploadFile(files);
  };

  selectFile = () => {
    let el = document.getElementById("files");
    el.click();
  };

  render() {
    const { files } = this.state;
    // TODO: проверить ошибки с бэка
    const { fileError } = this.props;

    return (
      <Fragment>
        <div className="flex">
          <div className="flex flex-column gap-20">
            <div className="">
              {/* загрузка нескольких файлов */}
              <input
                id="files"
                type="file"
                multiple
                hidden
                onChange={(e) => this.handleInputFile(e)}
              />
              <DefaultButton variant="contained" onClick={this.selectFile}>
                Прикрепить файлы
              </DefaultButton>
            </div>
            <div className="">
              {Array.from(files).map((file, index) => (
                <div
                  className="file_add"
                  key={index}
                  onClick={() => this.downloadFile(file.file)}
                >
                  <img src={FileIcon} alt="FileIcon" />
                  <span>{file.name}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="download-file_error flex-center">
            {fileError ? (
              <TooltipMessage
                text={"Ошибка"}
                delay={200}
                error
                position="right"
                classes=""
              >
                <ErrorIcon />
              </TooltipMessage>
            ) : (
              ""
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UploadFile;
