import React from 'react';
import './ToastMessage.scss';
import Close from "../../../assets/image/Mask.png";
import Success from "../../../assets/image/success_icon.png";

export const SuccessMessage = ({ close, message}) => {
  setTimeout(close, 3000);
  return (
    <div className="info-success-buy">
      <div>
        <span>
          <img src={Success} alt="Success" />
        </span>
        {/*<p>Товар добавлен в корзину</p>*/}
        {message}
      </div>
      <span onClick={close} className="close">
        <img src={Close} alt="Close" />
      </span>
    </div>
  );
};