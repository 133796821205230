import React, { Fragment } from "react";
import moment from "moment";

import { getStatusColor, getStatus } from "../../../helpers/functions";

import PhoneIcon from "@material-ui/icons/Phone";
import ClockIcon from "../../../assets/image/time.png";
import Chevron from "../../../assets/image/chevron.png";

import "./OrderItem.scss";

const OrderItem = ({ data, history, current_page, results_length }) => {
  let color = getStatusColor(data.status);
  if (!localStorage.token) {
    history.push("/auth");
  }
  return (
    <div
      className="dashboard_block order-item"
      onClick={() => {
        localStorage.pageOrders = !!current_page ? current_page : 1;
        localStorage.resultsOrders = !!results_length ? results_length : 1;
        history.push(`/admin/orders/${data.id}`);
      }}
    >
      <div className="status_block" style={{ backgroundColor: color }} />
      <div className="flex-center-btw">
        <p className="date_wrapper">
          <img src={ClockIcon} alt="clock" />
          {moment(data.date).format("DD.MM.YYYY HH:mm")}
        </p>
        <p className="status_title">
          <span style={{ backgroundColor: color }} />
          {getStatus(data.status)}
        </p>
      </div>
      <div className="order_title">
        {/*<Link to={`/admin/orders/${data.id}`}>*/}
        <h5>{data.id}</h5>
        {data.orderreviews > 0 ? (
          <span className="block_info_notif">{data.orderreviews}</span>
        ) : null}
        {/*</Link>*/}
      </div>
      <div className="block_info_item">
        <span className="block_label">Покупатель</span>
        <p className="block_value">
          {!!data.customer_name ? data.customer_name : "-"}
        </p>
        <span className="block_sub_value">
          <PhoneIcon />
          {!!data.customer_phone_number && data.customer_phone_number.length > 0
            ? data.customer_phone_number[0] === "+"
              ? `${data.customer_phone_number.substring(
                  1,
                  2
                )}-${data.customer_phone_number.substring(
                  2,
                  5
                )}-${data.customer_phone_number.substring(5)}`
              : `${data.customer_phone_number.substring(
                  0,
                  1
                )}-${data.customer_phone_number.substring(
                  1,
                  4
                )}-${data.customer_phone_number.substring(4)}`
            : "-"}
        </span>
      </div>
      <div className="block_info_item">
        <span className="block_label">Товар(ы)</span>
        <div className="flex-center-btw handler-hover-area">
          <p className="block_value">
            {!!data.orderproducts.length ? data.orderproducts[0].name : "-"}
          </p>
          <div className="block_value_currency">
            {data.orderproducts.length > 1 ? (
              <p className="block_value_all_wrapper">
                <span>
                  и еще {data.orderproducts.length - 1}{" "}
                  <img src={Chevron} alt="Chevron" />{" "}
                </span>
              </p>
            ) : (
              <Fragment>
                {!!data.orderproducts.length
                  ? data.orderproducts[0].price
                  : "-"}
                <i> ₽</i>
              </Fragment>
            )}
          </div>
          <div className="block_value_all">
            {!!data.orderproducts.length &&
              data.orderproducts.map((el, i) => (
                <p key={"itemOrder" + el.customer_name + i}>
                  <span>
                    <i>{el.name}</i>
                    <i>
                      {el.producer}, {el.quantity} шт.
                    </i>
                  </span>
                  <span>
                    {Number(el.price) * Number(el.quantity)} <i>₽</i>
                  </span>
                </p>
              ))}
          </div>
        </div>
        <span className="block_sub_value">
          {!!data.orderproducts.length && data.orderproducts[0].producer},{" "}
          {!!data.orderproducts.length && data.orderproducts[0].quantity} шт.
        </span>
        {/*{data.orderproducts.length === 1 ?*/}
        {/*<Fragment>*/}
        {/*<div className="flex-center-btw">*/}
        {/*<p className="block_value">data.orderproducts[0]</p>*/}
        {/*<p className="block_value_currency">163.00 ₽</p>*/}
        {/*</div>*/}
        {/*<span className="block_sub_value">Toyota, 3 шт.</span>*/}
        {/*</Fragment>*/}
        {/*:*/}
        {/*<Fragment>*/}
        {/*<div className="flex-center-btw">*/}
        {/*<p className="block_value">Свеча зажигания</p>*/}
        {/*<p className="block_value_currency">163.00 ₽</p>*/}
        {/*</div>*/}
        {/*<span className="block_sub_value">Toyota, 3 шт.</span>*/}
        {/*</Fragment>*/}
        {/*}*/}
      </div>
      <hr />
      <div className="order_bottom_info">
        <div>
          <span className="block_label">Всего</span>
          <span className="block_sub_value">
            {data.orderproducts.length} товар
            {data.orderproducts.length === 1
              ? null
              : data.orderproducts.length > 1 && data.orderproducts.length < 5
              ? "a"
              : "ов"}
          </span>
        </div>
        <p className="block_value_currency">
          {data.total} <i>₽</i>
        </p>
      </div>
    </div>
  );
};

export default OrderItem;
