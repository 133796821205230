import React, {Fragment} from 'react';
import {Field} from 'redux-form';
import RenderField from '../RenderField/RenderField';

const RenderSteppedPriceField = ({ fields, change, meta: { touched, error, submitFailed } }) => (
    <Fragment>
        <div className="markup-stepped">
            {!fields.length > 0 ?
                <p className="add_order_info">
                    Пока не добавлено ни одного товара
                </p>
                :
                fields.map((product, index) => (
                    <div key={index}>
                        <Field name={`${product}_before`} type="number" component={RenderField} label="Цена" symbol="до" placement="start"/>
                        <Field name={`${product}_after`} type="number" component={RenderField} label="Наценка" symbol="%"/>
                        {index === fields.length - 1 &&
                        <button
                            className="markup-btn"
                            onClick={() => fields.push({_before: 100, _after: 5})}
                        >
                            Добавить
                        </button>
                        }
                        {index !== fields.length - 1 &&
                        <button
                            className="markup-btn markup-btn-remove"
                            type="button"
                            onClick={() => fields.remove(index)}
                        >
                            Удалить
                        </button>
                        }

                    </div>
                ))}
            <div className="other-markup">
                <Field name="markup_percentage" type="text" component={RenderField} label="В иных случаях" symbol="%"/>
            </div>
        </div>
    </Fragment>
);

export default RenderSteppedPriceField;