import React, { Component, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Head from "../../components/Head/Head";
import Dashboard from "../../components/Dashboard/Dashboard";
import InfoCompany from "../../components/InfoCompany/InfoCompany";
import AddOrders from "../../components/Orders/AddOrders/AddOrders";
import Reviews from "../../components/Reviews/Reviews";
import Basket from "../../components/Basket/Basket";
import Purchases from "../../components/Purchases/Purchases";
import RequestsAdd from "../../components/Purchases/Requests/RequestsAdd/RequestsAdd";
import RefundRequestItem from '../../components/Purchases/Requests/RefundRequestItem/RefundRequestItem';
import Orders from "../../components/Orders/Orders";
import AddPriceList from "../../components/PriceList/AddPriceList/AddPriceList";
import PriceList from "../../components/PriceList/PriceList";

import {
  getUser,
  getProductsRecommendations,
  changeProductsRecommendationsAmount,
} from "../../actions/userActions";
import { addToCart } from "../../actions/cartActions";
import RemoveIcon from "@material-ui/icons/Remove";
import IncreaseIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseDialogIcon from "../../assets/image/Mask.png";
import DialogComponent from "../../components/HelperComponents/DialogComponent/DialogComponent";
import CartIcon from "../../assets/image/cart_icon_greeen.png";
import { SuccessMessage } from "../../components/HelperComponents/ToastMessages/ToastMessage";
import PriceInner from "../../components/PriceList/PriceInner/PriceInner";

class Container extends Component {
  constructor(props) {
    super(props);
    this.interval = undefined;
    this.state = {
      openRecommendationsDialog: false,
      addedToCartToast: false,
    };
  }

  componentDidMount() {
    const { getUser, getProductsRecommendations } = this.props;
    getUser();
    getProductsRecommendations().then((res) => {
      if (res.payload && res.payload.data.length) {
        this.setState({ openRecommendationsDialog: true });
      }
      this.setRecommendationInterval();
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.openRecommendationsDialog !==
      this.state.openRecommendationsDialog
    ) {
      if (!this.state.openRecommendationsDialog) {
        this.setRecommendationInterval();
      } else {
        clearInterval(this.interval);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setRecommendationInterval = () => {
    const { getProductsRecommendations } = this.props;
    this.interval = setInterval(() => {
      getProductsRecommendations().then((res) => {
        if (res.payload && res.payload.data.length) {
          this.setState({ openRecommendationsDialog: true });
        }
      });
    }, 120000);
  };

  toggleDialog = () => {
    this.setState(({ openRecommendationsDialog }) => ({
      openRecommendationsDialog: !openRecommendationsDialog,
    }));
  };

  handleAddProductToCart = async (offer_id, amount = 1) => {
    const { addToCart } = this.props;
    await addToCart(offer_id, amount);
    this.setState({ addedToCartToast: amount });
  };

  render() {
    const {
      match,
      history,
      productsRecommendations,
      changeProductsRecommendationsAmount,
      role
    } = this.props;
    const { openRecommendationsDialog, addedToCartToast } = this.state;
    if (!localStorage.token) return <Redirect to="/auth" />;
    return (
      <Fragment>
        <Head history={history} match={match} />
        <Switch>
          <Route path={`${match.url}/info`} component={InfoCompany} />
          <Route path={`${match.url}/purchases`} component={Purchases} />
          <Route path={`${match.url}/basket`} component={Basket} />
          <Route path={`${match.url}/requests-add`} component={RequestsAdd} />
          <Route path={`${match.url}/refund-request/:id`} exact component={RefundRequestItem} />
          { role !== undefined && role !== 'wholesale_customer' ? 
            (
              <Fragment>
                <Route path={match.url} exact component={Dashboard} />
                <Route path={`${match.url}/orders`} exact component={Orders} />
                <Route path={`${match.url}/orders/:id`} exact component={AddOrders} />
                <Route path={`${match.url}/price-list`} exact component={PriceList} />
                <Route
                  path={`${match.url}/price-list/add`}
                  exact
                  component={AddPriceList}
                />
                <Route
                  path={`${match.url}/price-list/:id`}
                  exact
                  component={PriceInner}
                />
                <Route path={`${match.url}/reviews`} component={Reviews} />
              </Fragment>
            ) : (
              <Fragment>
                <Route path={match.url} exact component={Purchases} />
              </Fragment>
            )
          }
          <Route render={() => <p>Not found</p>} />
        </Switch>
        <DialogComponent
          open={openRecommendationsDialog}
          onClose={this.toggleDialog}
        >
          <div className="title-dialog">Вас может заинтересовать</div>
          <div className="dialog_description">
            Не упустите возможность пополнить Ваш ассортимент по самым выгодным
            ценам.
          </div>
          <div className="flex-center-btw hot_offers_dialog">
            {productsRecommendations.map(
              ({
                producer,
                article_number,
                description,
                quantity,
                delivery_time,
                price,
                offer_id,
                amount,
              }) => (
                <div className="product_item" key={offer_id}>
                  <div className="title">
                    <span>#{article_number}</span>&nbsp; •&nbsp;{description}
                    &nbsp;•&nbsp;{producer}
                  </div>
                  <div className="product_info">
                    <div className="delivery_time">
                      <div>Срок поставки</div>
                      <p>{delivery_time} дн.</p>
                    </div>
                    <div className="price">
                      {price} <span>₽</span>
                    </div>
                    <div className="custom_count_field">
                      <div className="count_field">
                        <button
                          type="button"
                          onClick={() =>
                            changeProductsRecommendationsAmount(
                              "subtract",
                              offer_id
                            )
                          }
                        >
                          <RemoveIcon />
                        </button>
                        <input
                          value={amount || 1}
                          type="number"
                          min={0}
                          max={quantity}
                          disabled
                        />
                        <button
                          type="button"
                          onClick={() =>
                            changeProductsRecommendationsAmount("add", offer_id)
                          }
                        >
                          <IncreaseIcon />
                        </button>
                      </div>
                    </div>
                    <img
                      src={CartIcon}
                      alt="add to cart"
                      className="cart_icon"
                      onClick={() =>
                        this.handleAddProductToCart(offer_id, amount)
                      }
                    />
                  </div>
                </div>
              )
            )}
          </div>
          <IconButton
            classes={{
              root: "dialog_close_button",
            }}
            onClick={this.toggleDialog}
          >
            <img src={CloseDialogIcon} alt="close icon" />
          </IconButton>
        </DialogComponent>
        {!!addedToCartToast && (
          <SuccessMessage
            close={() => this.setState({ addedToCartToast: false })}
            message={
              addedToCartToast > 1
                ? "Товары добавлены в корзину"
                : "Товар добавлен в корзину"
            }
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.user.user_info.role,
    productsRecommendations: state.user.productsRecommendations,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUser,
      getProductsRecommendations,
      addToCart,
      changeProductsRecommendationsAmount,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);
