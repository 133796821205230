import React, {Component} from 'react';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import RenderField from '../../../HelperComponents/RenderField/RenderField';
import DefaultButton from '../../../Buttons/DefaultButton/DefaultButton';

import { postResetPasswordFirst } from "../../../../actions/authActions";


class FirstStep extends Component {
    state = {
      loading: false
    };

    submitForm = data => {
        const { postResetPasswordFirst, history } = this.props;
        this.setState({loading: true});
        return postResetPasswordFirst(data).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
                history.push('/auth/password-recovery/approve');
            } else {
                this.setState({loading: false});
                throw new SubmissionError({...res.error.response.data});
            }
        });
    };

    render(){
        const { handleSubmit, submitting, pristine, valid } = this.props;
        const { loading } = this.state;
        return (
            <form onSubmit={handleSubmit(this.submitForm)}>
                <h3 className="auth-block_head">Восстановление пароля</h3>
                <Field name="login" type="text" component={RenderField} label="Электронный адрес" autoFocus/>
                <DefaultButton
                    variant="contained"
                    classes="full_btn_width"
                    disabled={submitting || pristine || !valid}
                    loading={loading}
                    formAction
                >
                    Продолжить
                </DefaultButton>
            </form>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.login) {
        errors.login = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.login)) {
        errors.login = 'Invalid email'
    }
    return errors
};

FirstStep = reduxForm({
    form: 'FirstStepForm',
    validate
})(FirstStep);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postResetPasswordFirst
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(FirstStep);