import * as types from './constants.jsx';

//reviews actions

export function getReviews(page) {
    return {
        type: types.REVIEWS,
        payload: {
            client: 'default',
            request: {
                url: `/reviews/?page=${page}`,
                method: "get"
            }
        }
    };
}

export function patchReview(id, data) {
    return {
        type: types.REVIEWS_RESPONSE,
        payload: {
            client: 'default',
            request: {
                url: `/review/${id}/`,
                method: "patch",
                data
            }
        }
    };
}

