import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import "./RefundRequestItem.scss";
import {
  getMyRequestsStatus,
  getStatusColor,
  splitByCommas,
} from "../../../../helpers/functions";
import { withUploadFile } from "../../../../hocs/withUploadFile";
import {
  getRefundRequest,
  postDialogMessage,
} from "../../../../actions/PurchasesActions";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CommentComponent from "../../../HelperComponents/CommentComponent/CommentComponent";
import UploadFile from "../../../HelperComponents/UploadFile/UploadFile";
import BackLink from "../../../BackLink/BackLink";
import DefaultButton from "../../../Buttons/DefaultButton/DefaultButton";
import TransitionedBlock from "../../../HelperComponents/TransitionedBlock/TransitionedBlock";
import Loader from "../../../HelperComponents/Loader/Loader";
import TelegramIcon from "../../../../assets/image/telegram.png";
import ViberIcon from "../../../../assets/image/viber.png";
import WhatsappIcon from "../../../../assets/image/whatsapp.png";
import FileIcon from "../../../../assets/image/file_icon.png";
import moment from 'moment';

class RefundRequest extends Component {
  state = {
    loading: true,
    open: false,
    openDialog: false,
    id: null,
    message: "",
    sendMessageLoading: false,
    files: null,
  };

  componentDidMount = () => {
    this.doRequest();
  };

  doRequest = () => {
    const {
      match: { params },
      getRefundRequest,
    } = this.props;
    getRefundRequest(params.id).then((res) => {
      if (res.payload && res.payload.status) {
        this.setState({
          loading: false,
          id: res.payload.data.id,
        });
      }
    });
  };

  downloadFile = (file) => {
    if (window.location.host !== "admin.mexcar.ru") {
      window.open(`https://api.testmexcar.ru${file}`);
    } else {
      window.open(`https://api.mexcar.ru${file}`);
    }
  };

  handleChangeTextarea = (e) => {
    this.setState({ message: e.target.value });
  };

  prepareDataToSend = () => {
    const {
      match: { params },
    } = this.props;
    const { message, files } = this.state;
    const { setFromData } = this.props.withUploadFile;
    setFromData(+params.id, message, files);
  };

  uploadFile = (files) => {
    this.setState({
      files,
    });
  };

  sendMessage = () => {
    const { postDialogMessage } = this.props;
    const { formData } = this.props.withUploadFile;
    this.setState({ sendMessageLoading: true });
    this.prepareDataToSend();
    postDialogMessage(formData)
      .then((res) => {
        if (res.payload && res.payload.status) {
          this.setState({ sendMessageLoading: false, message: "" });
          this.doRequest();
        }
      })
      .catch(() => {
        this.setState({ sendMessageLoading: false });
      });
  };

  render() {
    const {
      refund_request: { refund_request },
    } = this.props;
    const { loading, message, sendMessageLoading } = this.state;

    if (loading) return <Loader />;

    return (
      <TransitionedBlock>
        <div className="item_page container">
          <div className="flex-center-btw title-block">
            <div className="title-page">
              <BackLink to="/admin/purchases?tab=2" />
              <h1>
                Заявка на возврат к заказу{" "}
                {refund_request && refund_request && refund_request.ordernum}
              </h1>
            </div>
          </div>
          <div className="item_page_block">
            <div className="block_wrapper status_wrapper">
              <div className="title_items">Статус заказа</div>
              <div className="descriptions_items">Статус</div>
              <div className="title_items_mini block_status_info indent_none">
                <span
                  className="status"
                  style={{
                    background: `${getStatusColor(refund_request.status)}`,
                  }}
                />
                {refund_request && getMyRequestsStatus(refund_request.status)}
              </div>
            </div>
            <div className="line_wrapper" />
            <div className="block_wrapper">
              <div className="title_items">Информация о документе</div>
              <div className="half_block_wrapper_items">
                {refund_request && refund_request.document !== null ? (
                  <div>
                    <div className="descriptions_items">Номер</div>
                    <div className="title_items_mini">
                      {refund_request && refund_request.document && refund_request.document.number}
                    </div>
                    <div className="descriptions_items">Дата</div>
                    <div className="title_items_mini">
                      {
                        refund_request
                        && refund_request.document
                        && refund_request.document.date
                        && moment(refund_request.document.date).format('DD.MM.YYYY HH:mm')
                      }
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="title_items_mini">Информация не найдена</div>
                  </div> 
                )
              }
              </div>
            </div>
            <div className="line_wrapper" />
            <div className="block_wrapper">
              <div className="title_items">Информация о компании</div>
              <div className="half_block_wrapper_items">
                {refund_request && refund_request.company !== null && (
                  <div>
                    <div className="descriptions_items">Название</div>
                    <div className="title_items_mini">
                      {refund_request && refund_request.company}
                    </div>
                  </div>
                )}
                <div>
                  <div className="descriptions_items">Электронный адрес</div>
                  <div className="title_items_mini">
                    {refund_request && refund_request.company_email}
                  </div>
                </div>
              </div>
              <div className="half_block_wrapper_items">
                <div>
                  {refund_request &&
                    refund_request.phones &&
                    refund_request.phones.map((phone, idPhone) => (
                      <Fragment key={idPhone}>
                        <div className="descriptions_items">Телефон</div>
                        <div className="title_items_mini">
                          {phone.phone_number}
                          <div className="info-social-wrapper">
                            {!!phone.telegram ? (
                              <img src={TelegramIcon} alt="telegram icon" />
                            ) : null}
                            {!!phone.viber ? (
                              <img src={ViberIcon} alt="viber icon" />
                            ) : null}
                            {!!phone.whats_app ? (
                              <img src={WhatsappIcon} alt="whatsapp icon" />
                            ) : null}
                          </div>
                        </div>
                      </Fragment>
                    ))}
                </div>
              </div>
            </div>
            <div className="line_wrapper" />
            <div className="title_items">Сведения о товаре</div>
            {!!refund_request &&
            refund_request.product_info &&
            refund_request &&
            refund_request.product_info !== null ? (
              <div className="block_wrapper">
                <div className="descriptions_items">Название</div>
                <div className="title_items_mini">
                  {refund_request && refund_request.product_info.name}
                </div>
                <div className="half_block_wrapper_items">
                  <div>
                    <div className="descriptions_items">Артикул</div>
                    <div className="title_items_mini">
                      {refund_request &&
                        refund_request.product_info.article_number}
                    </div>
                  </div>
                  <div>
                    <div className="descriptions_items">Производитель</div>
                    <div className="title_items_mini">
                      {refund_request && refund_request.product_info.producer}
                    </div>
                  </div>
                </div>
                <div className="half_block_wrapper_items">
                  <div>
                    <div className="descriptions_items">Количество</div>
                    <div className="title_items_mini indent_none">
                      {refund_request && refund_request.product_info.amount}
                    </div>
                  </div>
                  <div>
                    <div className="descriptions_items">Сумма</div>
                    <div className="title_items_mini indent_none">
                      {splitByCommas(
                        refund_request && refund_request.product_info.price
                      )}{" "}
                      ₽
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="line_wrapper" />
            <div className="title_items">Комментарии</div>
            {!!refund_request &&
            refund_request &&
            refund_request.messages &&
            refund_request &&
            refund_request.messages.length ? (
              <div className="flex flex-column gap-20">
                {refund_request &&
                  refund_request.messages
                    //сортировка по времени публикации
                    .sort((a, b) => a.pub_date.localeCompare(b.pub_date))
                    .map((message, index) => (
                      <CommentComponent data={message} key={index} />
                    ))}
              </div>
            ) : null}
            <TextareaAutosize
              className="mt-50"
              placeholder="Ваше сообщение..."
              value={message}
              onChange={this.handleChangeTextarea}
            />
            <div className="flex mt-20 gap-20">
              <DefaultButton
                variant="outlined"
                classes="default_button_contained"
                loading={sendMessageLoading}
                disabled={!message}
                onClick={this.sendMessage}
              >
                Ответить
              </DefaultButton>
              <UploadFile
                uploadFile={this.uploadFile}
                isSending={sendMessageLoading}
              />
            </div>
            {refund_request &&
            refund_request.status === "processing" &&
            refund_request &&
            refund_request.messages &&
            refund_request &&
            refund_request.messages.length > 0 ? (
              <Fragment>
                <div className="line_wrapper" />
                <div className="block_wrapper">
                  <div className="title_items">Прикрепленные файлы</div>
                  {refund_request &&
                    refund_request.messages.map((item, idFiles) => {
                      if (item.files.length) {
                        return item.files.map((file, index) => (
                          <div
                            className="file_add"
                            key={index}
                            onClick={() => this.downloadFile(file.file)}
                          >
                            <img src={FileIcon} alt="FileIcon" />
                            <span>{file.name}</span>
                          </div>
                        ));
                      }
                      return null;
                    })}
                </div>
              </Fragment>
            ) : null}
          </div>
        </div>
      </TransitionedBlock>
    );
  }
}

const mapStateToProps = ({ refund_request }) => ({
  refund_request,
});

const mapDispatchToProps = {
  getRefundRequest,
  postDialogMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUploadFile(RefundRequest));
