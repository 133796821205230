import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import MailIcon from "../../assets/image/mail_icon.svg";
import PhoneIcon from "@material-ui/icons/Phone";
import EditIcon from "../../assets/image/edit.png";
import "./InfoHead.scss";

class InfoHead extends Component {
  render() {
    const {
      user: { user_info,  },
    } = this.props;

    return (
      <main>
        <section className="info-head container">
          <div className="info-head-wrapper">
            <div className="info-head_content">
              <h3 className="info-head_title">
                {user_info.company ? (
                  <Fragment>«{user_info.company}»</Fragment>
                ) : (
                  " "
                )}
              </h3>
              <div className="info-head_contacts">
                <p>
                  <img src={MailIcon} alt="mail icon" />
                  {user_info.email ? (
                    <Fragment>{user_info.email}</Fragment>
                  ) : (
                    " "
                  )}
                </p>
                <p>
                  <PhoneIcon />
                  {user_info.phones && user_info.phones.length > 0 ? (
                    <Fragment>
                      {user_info.phones[0].phone_number.substring(0, 1) +
                        "-" +
                        user_info.phones[0].phone_number.substring(1, 4) +
                        "-" +
                        user_info.phones[0].phone_number.substring(4)}
                    </Fragment>
                  ) : (
                    " "
                  )}
                </p>
              </div>
            </div>
            <div className="info-header">
              <Button
                component={Link}
                to="/admin/info"
                disableRipple={true}
                classes={{
                  root: "edit_btn",
                }}
              >
                Редактировать
                <img src={EditIcon} alt="edit icon" />
              </Button>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(
  mapStateToProps
)(InfoHead);
