import * as types from "../actions/constants";

const INITIAL_STATE = {
    addresses_list: [],
    price_list_info: {},
    price_lists: {},
    // price_list_info: {"rows":[["","","","","",""],["#90919-01164","Свеча зажигания","Toyota","163.00","2182 шт.","1-S2"],["#90919-01164","Свеча зажигания","Toyota","163.00","2182 шт.","1-S2"],["#90919-01164","Свеча зажигания","Toyota","163.00","2182 шт.","1-S2"],["#90919-01164","Свеча зажигания","Toyota","163.00","2182 шт.","1-S2"]],"cells":[["article_number","Артикул"],["name","Наименование"],["producer","Производитель"],["price","Цена"],["amount","Количество"],["location","Место хранения"]],"file_name":"asdasd","markup":[["miss","Нет"],["fixate","Фиксированая"],["stepped","Ступенчатая"]],"delivery_time":[["in stock","В наличии"],["custom","Под заказ"]],"representation":5},
    error_price: []
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.PRICE_LIST_ADDRESSES_SUCCESS :
            let addressesObj = {
                loaded: true,
                data: action.payload.data
            };
            return {...state, addresses_list : addressesObj};
        case types.SET_PRICE_LIST_INFO :
            return {...state, price_list_info : action.data};
        case types.PRICE_LISTS_SUCCESS :
            return {...state, price_lists : action.payload.data};
        case types.CREATE_PRICE_LIST_FAIL :
            return {...state, error_price : action.error.response.data};
        case types.SEND_FILE_FAIL :
            return {...state, error_price : action.error.response.data};
        case types.POST_VALIDATE_OPTIONAL_FIELDS :
            return { ...state, error_price: [] };
        case types.POST_VALIDATE_OPTIONAL_FIELDS_FAIL :
            let error_price = "";
            if (action.error.response.data.email) {
                if (typeof action.error.response.data.email === "string") {
                    error_price = action.error.response.data.email;
                } else {
                    error_price = action.error.response.data.email[0];
                }
            } else if (action.error.response.data.detail) {
                if (typeof action.error.response.data.detail === "string") {
                    error_price = action.error.response.data.detail;
                } else {
                    error_price = action.error.response.data.detail[0];
                }
            } else if (action.error.response.data.link) {
                if (typeof action.error.response.data.link === "string") {
                    error_price = action.error.response.data.link;
                } else {
                    error_price = action.error.response.data.link[0];
                }
            }
            return {
                ...state,
                error_price: { price_list: error_price }
            };
        case types.PRICE_LIST_ADDRESSES_FAIL :
            return {...state, error_price : action.error.response.data};
        default:
            return state;
    }
}