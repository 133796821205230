import * as types from "../actions/constants";

const INITIAL_STATE = {
    reviews_list: {},
    error: []
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.REVIEWS_SUCCESS :
            let reviewsObj = {
                loaded: true,
                ...action.payload.data
            };
            return {...state, reviews_list : reviewsObj};
        case types.REVIEWS_RESPONSE_SUCCESS :
            let reviewsSuccessObj = state.reviews_list;
            reviewsSuccessObj.results[reviewsSuccessObj.results.findIndex(x => x.id === action.payload.data.id)] = action.payload.data;
            return {...state, reviews_list : reviewsSuccessObj};
        case types.REVIEWS_FAIL :
            return {...state, error : action.error.response.data};
        default:
            return state;
    }
}