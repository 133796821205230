import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import BackLink from '../BackLink/BackLink';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputBase from '@material-ui/core/InputBase';
// import SearchIcon from '@material-ui/icons/Search';
import SelectComponent from '../HelperComponents/SelectComponent/SelectComponent';
import OrderItem from './OrderItem/OrderItem';
import Loader from '../HelperComponents/Loader/Loader';
import Pagination from '../HelperComponents/Pagination/Pagination';
import NoItems from '../HelperComponents/NoItems/NoItems';
import { isArray } from "../../helpers/functions";

import { getOrders } from "../../actions/ordersActions";

import '../Search/Search.scss';

import AddIcon from '../../assets/image/add.png';
import OrdersIcon from "../../assets/image/no_orders.png";
import SearchIcon from "../../assets/image/search_icon.svg";

class Orders extends Component {
    state = {
        loading: true,
        status: null,
        valueSearch : "",
        projects_list: [
            {label: "Новый", value: "new"},
            {label: "Готов к выдаче", value: "processing"},
            {label: "Выполнен", value: "complete"},
            { label: "Отказ", value: "denied" },
            { label: "Ожидаем документы", value: "waiting_for_documents" },
            { label: "Ожидаем деталь", value: "waiting_for_details" },
        ],
        activePage: 1
    };

    componentDidMount() {
        let status = null, search = "", page = !!localStorage.pageOrders ? localStorage.pageOrders : 1;
        if(!!localStorage.pageOrders) this.setState({ activePage: Number(localStorage.pageOrders)});

        setTimeout(()=>{
            this.doRequest(status, search, page);
        },0);
    }

    handleChange = name => event => {
        const {valueSearch, activePage} = this.state;
        this.setState({ [name]: event, activePage: 1});
        this.doRequest( event !== null ? event.value : null, valueSearch, 1)
    };

    changeValueSearch = (e) => {
        this.setState({valueSearch: e.target.value});
    };

    handleSearch = (event) => {
        const {status, valueSearch, activePage} = this.state;
        if (event.keyCode === 13) {
            this.setState({activePage: 1});
            this.doRequest( status, valueSearch, activePage);
        }
    };

    changePage = (pageNumber) => {
        const {status, valueSearch} = this.state;
        this.setState({activePage: pageNumber});
        this.doRequest(status && status.value ? status.value : null, valueSearch, pageNumber);
    };

    doRequest = (status, search,  page) => {
        const {getOrders, history} = this.props;
        let arr = [];
        arr.push(`page=${page}`);
        arr.push(`page_size=12`); //todo delete
        if( status !== null && !status.value) {
            arr.push(`filter=${status}`);
        }
        if(search.length>0) {
            arr.push(`query=${search}`);
        }
        getOrders(arr).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200){
                if (res.payload.data.results.length === 0) {
                    this.setState({ empty: true });
                } else {
                    this.setState({ empty: false });
                }
            }
            if(res.error && res.error.response.status === 401) {
                localStorage.clear();
                history.push('/auth');
            }
        });
    };

    render(){
        const { orders:{all_orders}, history } = this.props;
        const { status, valueSearch, projects_list, activePage } = this.state;
        return (
            <main className="container orders-main-block">
                <div className="title-block">
                    <BackLink/>
                    <h1>Заказы</h1>
                </div>
                <div className="flex-center-btw">
                    <div className="flex-center-start">
                        <div className="page_options_wrapper">
                            <div className='search_wrapper search_wrapper_small'>
                                <InputBase
                                    placeholder="Поиск…"
                                    onKeyDown={this.handleSearch}
                                    onChange={this.changeValueSearch}
                                    value={valueSearch}
                                    classes={{
                                        input: 'search_input',
                                    }}
                                />
                                <div className="search_input_icon">
                                    <img src={SearchIcon} alt="SearchIcon"/>
                                </div>
                            </div>
                        </div>
                        <FormControl className="select_wrapper">
                            <SelectComponent
                                value={status}
                                options={projects_list}
                                loading={!isArray(projects_list)}
                                change={this.handleChange('status')}
                                placeholder="Статусы"
                            />
                        </FormControl>
                    </div>
                    <Button
                        component={Link}
                        to="/admin/orders/add"
                        disableRipple ={true}
                        classes={{
                            root: 'add_btn'
                        }}
                    >
                        Добавить
                        <img src={AddIcon} alt="edit icon"/>
                    </Button>
                </div>

                <div className="dashboard-section_content_orders">
                    {!all_orders ||!all_orders.results ?
                        <div className="info_company_loader_wrapper">
                            <Loader/>
                        </div>
                        :  all_orders.results.length === 0 ?
                            <NoItems>
                                <div className="box-img"><img src={OrdersIcon} alt="edit icon"/></div>
                                <div className = "title" > Пока нет ни одного заказа</div>
                                <div className="descriptions">После оформления первого заказа Вы увидите его здесь</div>
                            </NoItems>
                            :
                            all_orders.results.map(order => (
                                order.status !== 'imported' && (
                                    <OrderItem data={order} history={history} key={"order" + order.id} current_page={activePage} results_length={all_orders.results.length}/>
                                )
                            ))
                    }
                </div>
                <div>
                    {all_orders.count && all_orders.count > 12 ?

                        <Pagination
                            current={activePage}
                            pageCount={12}
                            total={all_orders.count}
                            onChange={this.changePage}
                        />
                        :
                        null
                    }
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    return{
        orders: state.orders
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getOrders
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);