import React, {Component, Fragment} from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import RenderCheckField from "../HelperComponents/RenderCheckField/RenderCheckField";
import RenderField from "../HelperComponents/RenderField/RenderField";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";

import { postNewContact, patchContact } from "../../actions/userActions";

import AddIcon from '../../assets/image/add.png';
import CloseDialogIcon from '../../assets/image/Mask.png';


class AddContact extends Component {
    state = {
        open: false,
        loading: false
    };

    componentWillUnmount() {
        clearTimeout(this.loadingTimeout);
    }

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };

    submitForm = data => {
        const { postNewContact, patchContact, edit, id, reset } = this.props;
        this.setState({loading: true});
        if(edit) {
            return patchContact(id, data).then(res => {
                if(res.payload && res.payload.status && res.payload.status === 200) {
                    this.setState({open: false});
                    this.loadingTimeout = setTimeout(() => {
                        this.setState({loading: false});
                    }, 300);
                } else {
                    this.setState({loading: false});
                    throw new SubmissionError({...res.error.response.data});
                }
            })
        } else {
            return postNewContact(data).then(res => {
                if(res.payload && res.payload.status && res.payload.status === 201) {
                    reset();
                    this.setState({open: false});
                    this.loadingTimeout = setTimeout(() => {
                        this.setState({loading: false});
                    }, 300);
                } else {
                    this.setState({loading: false});
                    throw new SubmissionError({...res.error.response.data});
                }
            })
        }
    };

    render(){
        const { handleSubmit, submitting, pristine, valid, edit } = this.props;
        const { open, loading } = this.state;
        return (
            <Fragment>
                {edit ?
                    <Button
                        classes={{
                            root: 'blue_btn'
                        }}
                        disableRipple ={true}
                        onClick={this.toggleDialog}
                    >
                        Изменить
                    </Button>
                    :
                    <Button
                        classes={{
                            root: 'add_btn'
                        }}
                        disableRipple ={true}
                        onClick={this.toggleDialog}
                    >
                        Добавить
                        <img src={AddIcon} alt="edit icon"/>
                    </Button>
                }
                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                >
                    <div className="title-dialog">
                        {edit
                            ? 'Изменить контакт'
                            : 'Добавить контакт'
                        }
                    </div>
                    <form className="dialog-form" onSubmit={handleSubmit(this.submitForm)}>
                        <div className="dialog-field">
                            <Field name="phone_number" type="number" component={RenderField} label="Телефон"/>
                            <Field name="telegram" component={RenderCheckField} label="Telegram" />
                            <Field name="viber" component={RenderCheckField} label="Viber" />
                            <Field name="whats_app" component={RenderCheckField} label="WhatsApp" />
                        </div>
                        <div className="btn-wrapper">
                            <DefaultButton
                                variant="outlined"
                                classes="cancel_btn"
                                onClick={this.toggleDialog}
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                disabled={submitting || pristine || !valid}
                                loading={loading}
                                formAction
                            >
                                Сохранить
                            </DefaultButton>
                        </div>
                    </form>
                    <IconButton
                        classes={{
                            root: "dialog_close_button"
                        }}
                        onClick={this.toggleDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon"/>
                    </IconButton>
                </DialogComponent>
            </Fragment>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.phone_number) {
        errors.phone_number = 'Required'
    } else if (values.phone_number.length < 3) {
        errors.phone_number = 'Must be 3 characters or more'
    }
    return errors
};

AddContact = reduxForm({
    enableReinitialize: true,
    validate
})(AddContact);

function mapStateToProps(state, props) {
    return{
        //name: state.name,
        initialValues: {
            phone_number: props.edit && props.phone_number ? props.phone_number : '',
            telegram: props.edit && props.telegram ? props.telegram : false,
            viber: props.edit && props.viber ? props.viber : false,
            whats_app: props.edit && props.whats_app ? props.whats_app : false
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postNewContact,
        patchContact
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddContact);