import React, {Component} from 'react';
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from '../../Buttons/DefaultButton/DefaultButton';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SearchProducts from '../../SearchProducts/SearchProducts';

import AddIcon from '../../../assets/image/add.png';
import CloseDialogIcon from '../../../assets/image/Mask.png';
import IncreaseIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

class AddProduct extends Component {
    state = {
        open: false,
        amount: 1,
        product: null
    };

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open,
            amount: 1,
            product: null,
            errorCount: null
        }));
    };

    decreaseAmount = () => {
        const { amount } = this.state;
        let newValue = amount - 1;
        if(newValue >= 1) {
            this.setState({amount: newValue});
        }
    };

    increaseAmount = () => {
        this.setState(({amount}) => ({amount: amount + 1}));
    };

    setProduct = (product) => {
        this.setState({product});
    };

    submitProduct = () => {
        const { fields, change } = this.props;
        const { product, amount } = this.state;
        this.setState({errorCount : null});

        let hasProduct = null;

        fields.forEach((el, i) => {
            let item = fields.get(i);
            if(item.id === product.id) {
                hasProduct = {
                    i,
                    count: item.count
                };
            }
        });

        if(hasProduct !== null) {
            change(`orderproducts[${hasProduct.i}].count`, hasProduct.count + amount);
        } else if (amount <= product.amount) {
            fields.push({
                id: product.id,
                info: product.text,
                count: amount,
                product
            });
        }
        if (amount > product.amount) {
            this.setState({errorCount : 'Количество выбранного товара превышает доступное'});
        } else {
            this.setState({open : false});
        }
    };

    render(){
        const { edit } = this.props;
        const { open, amount, product, errorCount } = this.state;
        return (
            <div>
                <Button
                    classes={{
                        root: 'add_btn'
                    }}
                    disableRipple={true}
                    onClick={this.toggleDialog}
                >
                    Добавить
                    <img src={AddIcon} alt="edit icon"/>
                </Button>
                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                >
                    <div className="title-dialog">
                        {edit
                            ? 'Изменить товар'
                            : 'Добавить товар'
                        }
                    </div>
                    <div className="flex-center-btw add_product">
                        <SearchProducts
                            setProduct={this.setProduct}
                        />
                        <div className="custom_count_field">
                            <div className={`count_field ${product === null ? 'count_field_disabled' : ''}`}>
                                <button
                                    type="button"
                                    disabled={product === null}
                                    onClick={this.decreaseAmount}
                                >
                                    <RemoveIcon/>
                                </button>
                                <input
                                    value={amount}
                                    type="number"
                                    min={0}
                                    max={100}
                                    disabled
                                />
                                <button
                                    type="button"
                                    disabled={product === null}
                                    onClick={this.increaseAmount}
                                >
                                    <IncreaseIcon/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="btn-wrapper">
                        <DefaultButton
                            variant="outlined"
                            classes="cancel_btn"
                            onClick={this.toggleDialog}
                        >
                            Отмена
                        </DefaultButton>
                        <DefaultButton
                            variant="contained"
                            disabled={product === null}
                            onClick={this.submitProduct}
                        >
                            Сохранить
                        </DefaultButton>
                        { }
                        {errorCount !== null
                            ? <p className="error_after_button">{errorCount}</p>
                            : null
                        }
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button"
                        }}
                        onClick={this.toggleDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon"/>
                    </IconButton>
                </DialogComponent>
            </div>
        );
    }
}

export default AddProduct;