import React, { Component, Fragment } from "react";
import moment from "moment";

import ClockIcon from "../../../assets/image/time.png";

import "./PriceItem.scss";
import { bindActionCreators } from "redux";
import {
  patchPriceList,
  deletePriceList,
  getPriceLists,
} from "../../../actions/priceListActions";
import { getDashboard } from "../../../actions/userActions";
import { connect } from "react-redux";
import { hasExtension, isArray } from "../../../helpers/functions";
import SuccessIcon from "../../../assets/image/success_icon.png";
import CloseDialogIcon from "../../../assets/image/Mask.png";
import TickIcon from "../../../assets/image/tick_icon.svg";

import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import IconButton from "@material-ui/core/IconButton";
import Arrow from "../../../assets/image/arrow.png";
import FormControl from "@material-ui/core/FormControl";

class PriceItem extends Component {
  state = {
    loading: false,
    open: false,
    openMismatch: false,
    tempProducers: {},
    user_producer_list: [],
    formData: null,
    loadingDelete: false,
  };

  refreshPrice = (e) => {
    let el = document.getElementById("file" + e.toLocaleString());
    el.click();
  };

  toggleDialog = () => {
    this.setState(({ open }) => ({
      open: !open,
    }));
  };

  removePrice = (e) => {
    const { deletePriceList, getPriceLists, getDashboard, id } = this.props;

    deletePriceList(id).then((res) => {
      if (res.payload && res.payload.status && res.payload.status === 204) {
        this.setState(({ open }) => ({
          open: !open,
          loadingDelete: false,
        }));
        getPriceLists(1);
        getDashboard();
      }
    });
  };

  handleImageChange(e) {
    e.preventDefault();
    const { patchPriceList, id } = this.props;

    let file = e.target.files[0];
    if (hasExtension(file.name, [".xls", ".xlsx", ".xlsb", ".xml"])) {
      let formData = new FormData();
      formData.append("price_list", file);
      this.setState({ formData: e.target.files[0] });
      return patchPriceList(formData, id).then((res) => {
        if (
          res.payload &&
          res.payload.status &&
          res.payload.status === (200 || 201)
        ) {
          this.setState({ loading: true });
          setTimeout(() => this.setState({ loading: false }), 3000);
        } else {
          let arr2d2 = []; //  Default value undefined producers
          res.error.response.data.undefined_producers.map((e) =>
            arr2d2.push({
              name: e,
              id:
                res.error.response.data.producer_list[
                  res.error.response.data.producer_list.findIndex(
                    (x) => x.name === "Другое"
                  )
                ].id,
            })
          );

          let arrProd = []; //  List of available producers
          res.error.response.data.producer_list.map((el) =>
            arrProd.push({ value: el.id, label: el.name })
          );
          let obj = {};
          obj.optionsProdusers = arrProd;
          this.setState({
            loading: false,
            openMismatch: true,
            tempProducers: obj,
            user_producer_list: arr2d2,
          });
        }
      });
    }
  }

  handleChangeProducer = (item) => (event) => {
    const { user_producer_list } = this.state;
    let stateTemp = user_producer_list;
    stateTemp[stateTemp.findIndex((x) => x.name === item.name)].id =
      event.value;
    this.setState({ user_producer_list: stateTemp });
  };

  toggleDialogMis = () => {
    this.setState(({ openMismatch }) => ({
      openMismatch: !openMismatch,
    }));
  };

  submitNewproducers = () => {
    const { patchPriceList, id } = this.props;
    const { tempProducers, user_producer_list, formData } = this.state;
    let newObj = tempProducers;
    delete newObj.optionsProdusers;

    let newFormData = new FormData();
    newFormData.append("price_list", formData);
    newFormData.append(
      "user_producer_list",
      JSON.stringify(user_producer_list)
    );

    return patchPriceList(newFormData, id).then((res) => {
      if (
        res.payload &&
        res.payload.status &&
        (res.payload.status === 201 || res.payload.status === 200)
      ) {
        this.setState({
          openMismatch: false,
          loading: true,
          formData: null,
        });
        setTimeout(() => this.setState({ loading: false }), 3000);
      }
    });
  };

  render() {
    const {
      id,
      date,
      name,
      positions,
      agency,
      agency_name,
      addresses_list,
      updating_from_file,
      history,
    } = this.props;
    const {
      loading,
      open,
      openMismatch,
      user_producer_list,
      tempProducers,
      loadingDelete,
    } = this.state;
    return (
      <div className="dashboard_block price-item">
        <div
          className={`info_card${!updating_from_file ? " has-inner" : ""}`}
          onClick={() =>
            !updating_from_file && history.push(`/admin/price-list/${id}`)
          }
        >
          <div>
            <p className="date_wrapper">
              <img src={ClockIcon} alt="clock" />
              {moment(date).format("DD.MM.YYYY HH:mm")}
            </p>
          </div>
          <div className="price_title">
            <h5>{name}</h5>
          </div>
          <div className="block_info_item">
            <span className="block_label">Позиций</span>
            <p className="block_value">{positions}</p>
          </div>
          <div className="block_info_item">
            <span className="block_label">Представительство</span>
            <p className="block_value block_value_extra">
              {!!agency_name
                ? agency_name
                : addresses_list.filter((e) => agency === e.id)[0].coordinates}
            </p>
          </div>
          <hr />
        </div>
        <div
          className={`flex-center-btw price_btn_wrapper${
            updating_from_file ? "" : " flex-end-btw"
          }`}
        >
          <input
            id={"file" + id}
            type="file"
            style={{ display: "none" }}
            onChange={(e) => this.handleImageChange(e)}
          />
          {updating_from_file ? (
            <button
              className={
                loading ? "refresh_price_btn text-green" : "refresh_price_btn"
              }
              onClick={() => this.refreshPrice(id)}
            >
              {loading ? (
                <Fragment>
                  {" "}
                  <img src={SuccessIcon} alt="success icon" />
                  Обновлено
                </Fragment>
              ) : (
                "Обновить XLS-файл"
              )}
            </button>
          ) : (
            <div className="tick_wrapper">
              <img src={TickIcon} alt="tick icon" />
              Учёт
            </div>
          )}
          <button className="delete_price_btn" onClick={this.toggleDialog}>
            Удалить
          </button>
          <DialogComponent open={open} onClose={this.toggleDialog}>
            <div className="title-dialog">Удалить прайс-лист</div>
            <div className="descriptions">Вы уверены?</div>
            <div className="btn-wrapper">
              <DefaultButton
                variant="outlined"
                classes="cancel_btn"
                onClick={this.toggleDialog}
              >
                Отмена
              </DefaultButton>
              <DefaultButton
                onClick={() => {
                  this.setState({ loadingDelete: true });
                  this.removePrice();
                }}
                disabled={loadingDelete}
                loading={loadingDelete}
              >
                Удалить
              </DefaultButton>
            </div>
            <IconButton
              classes={{
                root: "dialog_close_button",
              }}
              onClick={this.toggleDialog}
            >
              <img src={CloseDialogIcon} alt="close icon" />
            </IconButton>
          </DialogComponent>

          <DialogComponent
            open={openMismatch}
            onClose={this.toggleDialogMis}
            longDialog
          >
            <div className="title-dialog">Найдены несоответствия</div>
            <div className="descriptions">
              В загруженном файле обнаружены названия производителей, которые
              отличаются от имеющихся в базе. Пожалуйста, выберите соответствие
              из предложенных вариантов.
            </div>
            <div className="undefined-producers-wrapper">
              {user_producer_list.length > 0 &&
                !!tempProducers.optionsProdusers &&
                tempProducers.optionsProdusers.length > 0 &&
                user_producer_list.map((elem, index) => (
                  <div
                    key={"undefined_producers" + index}
                    className="undefined-producers-item"
                  >
                    <input
                      id={"undefined-producers" + index}
                      type="text"
                      value={elem.name}
                      disabled={true}
                      autoComplete="off"
                    />
                    <img src={Arrow} alt="" />
                    <FormControl className="select_wrapper">
                      <SelectComponent
                        value={
                          tempProducers.optionsProdusers[
                            tempProducers.optionsProdusers.findIndex(
                              (x) => x.value === elem.id
                            )
                          ]
                        }
                        options={tempProducers.optionsProdusers}
                        loading={!isArray(tempProducers.optionsProdusers)}
                        change={this.handleChangeProducer(elem)}
                        placeholder=""
                        isClearable
                      />
                    </FormControl>
                  </div>
                ))}
            </div>
            <div className="btn-wrapper">
              <DefaultButton
                variant="outlined"
                classes="cancel_btn"
                onClick={this.toggleDialogMis}
              >
                Отмена
              </DefaultButton>
              <DefaultButton
                variant="contained"
                onClick={this.submitNewproducers}
              >
                Заменить
              </DefaultButton>
            </div>
            <IconButton
              classes={{
                root: "dialog_close_button",
              }}
              onClick={this.toggleDialogMis}
            >
              <img src={CloseDialogIcon} alt="close icon" />
            </IconButton>
          </DialogComponent>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    price_list: state.price_list,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPriceLists,
      patchPriceList,
      deletePriceList,
      getDashboard,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceItem);
