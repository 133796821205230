import React, { Component, Fragment } from "react";
import BackLink from "../BackLink/BackLink";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import MyOrders from "./MyOrders/MyOrders";
import OrderItem from "./OrderItems/OrderItems";
import Requests from "./Requests/Requests";
import TransactionHistory from "./TransactionHistory/TransactionHistory";
import Up from "../../assets/image/up.png";
import "./Purchases.scss";
import { connect } from 'react-redux';

class Purchases extends Component {
    state = {
        open: false,
        tab: 0,
        success: false,
        showButton: false,
    };

    changeTab = (tab) => {
        let { history } = this.props;
        if (tab === 0) {
            history.push({
                search: ``,
            });
        } else {
            history.push({
                search: `tab=${tab}`,
            });
        }

        this.setState({ tab });
    };

    scrollSite = (value) => this.setState({ showButton: value });

    ScrollToReviews = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    componentDidMount() {
        const queryString = require("query-string");
        const values = queryString.parse(this.props.location.search);
        let tab_value = values.tab;
        if (tab_value) {
            this.setState({ tab: parseInt(tab_value) });
        }
        let success = values.success;
        if (success) {
            this.setState({ success: true });
        }
        document.addEventListener("scroll", (e) => {
            if (Math.floor(window.pageYOffset) > 600) this.scrollSite(true);
            else this.scrollSite(false);
        });
    }

    render() {
        const { tab, showButton } = this.state;
        const { role } = this.props;

        return (
            <>
                <div className="container purchases_page">
                    <div className="title-block">
                        {role !== undefined && role !== 'wholesale_customer' &&
                            (
                                <Fragment>
                                    <BackLink />
                                    <h1>Закупки</h1>
                                </Fragment>
                            )
                        }
                        <TransitionedBlock>
                            <div className="purchases">
                                <div className="purchases_tabs">
                                    <div className="tabs_block_purchases">
                                        <div className="tab_button_purchases">
                                            <button
                                                className={`${
                                                    tab === 0 ? "active" : ""
                                                }`}
                                                onClick={() =>
                                                    this.changeTab(0)
                                                }
                                            >
                                                Заказ товара
                                            </button>
                                            <button
                                                className={`${
                                                    tab === 1 ? "active" : ""
                                                }`}
                                                onClick={() =>
                                                    this.changeTab(1)
                                                }
                                            >
                                                Мои заказы
                                            </button>
                                            <button
                                                className={`${
                                                    tab === 2 ? "active" : ""
                                                }`}
                                                onClick={() =>
                                                    this.changeTab(2)
                                                }
                                            >
                                                Заявки на возврат
                                            </button>
                                            <button
                                                className={`${
                                                    tab === 3 ? "active" : ""
                                                }`}
                                                onClick={() =>
                                                    this.changeTab(3)
                                                }
                                            >
                                                История транзакций
                                            </button>
                                        </div>
                                    </div>
                                    {tab === 0 && <MyOrders />}
                                    {tab === 1 && <OrderItem />}
                                    {tab === 2 && (
                                        <Requests
                                            success={this.state.success}
                                        />
                                    )}
                                    {tab === 3 && <TransactionHistory />}
                                </div>
                            </div>
                        </TransitionedBlock>
                    </div>
                    {showButton ? (
                        <button
                            className="up-btn"
                            onClick={this.ScrollToReviews}
                        >
                            НАВЕРХ <img src={Up} alt="Up" />
                        </button>
                    ) : null}
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return{
        role: state.user.user_info.role,
    }
}

export default connect(mapStateToProps)(Purchases);