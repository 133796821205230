import React, {Component} from 'react';
import {connect} from 'react-redux';
import PopperInfo from '../HelperComponents/PopperInfo/PopperInfo';

import { isArray } from "../../helpers/functions";
import { getProducts } from "../../actions/ordersActions";

import './SearchProducts.scss';

class SearchProducts extends Component {
    state = {
        value: '',
        open: false,
        anchorEl: null,
        results: {data: [], query: ''},
        product: null
    };

    onChange = (e) => {
        let value = e.target.value;
        this.setState({value});
    };

    onKeyUp = (e) => {
        const { results, open } = this.state;
        let value = e.target.value;
        if (e.keyCode === 13 && value.length >= 3) {
            this.getProducts(value.slice(0,3).toString());
        }
        if(value.length === 3 && results.query !== value && value.indexOf(" ") === -1){
            this.getProducts(value.toString());
        } else if (value.length > 3 && !open) {
            this.setState({open: true});
        } else if(value.length < 3) {
            this.setState({open: false});
        }
    };

    getProducts = (value) => {
        const { getProducts } = this.props;
        getProducts(value).then((res) => {
            this.setState({
                open: true,
                anchorEl: document.getElementById("products_input"),
                results: {
                    data: res.payload.data,
                    query: value
                }
            });
        })
    };

    selectProduct = (item) => {
        const { setProduct } = this.props;

        let arr = [];
        arr.push(item.product);
        arr.push(item.article_number);
        arr.push(item.product_producer);
        arr.push(`${item.amount} шт`);
        arr.push(item.price_list);
        arr.push(`${item.price} ₽`);

        setProduct({...item, text: arr.join(' • ')});
        this.setState({
            value: arr.join(' • '),
            open: false
        });
    };

    clickAway = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };

    highlightText = (text) => {
        const { value } = this.state;
        let val = value.toLowerCase();
        let txt = text.toLowerCase();
        let index = txt.indexOf(val);
        if (index !== -1) {
            return  <h4>{text.substring(0, index)}<strong className="highlight">{text.substring(index, index + value.length)}</strong>{text.substring(index + value.length)}</h4>
        } else {
            return <h4>{text}</h4>;
        }
    };

    render(){
        const { value, anchorEl, open, results } = this.state;
        let items = results.data.filter(el => el.article_number.toLowerCase().indexOf(value.toLowerCase()) !== -1 && el.article_number.toLowerCase().includes(value.toLowerCase()));
        return (
            <div className="search_product_wrapper">
                <input
                    id="products_input"
                    type="text"
                    value={value}
                    onChange={this.onChange}
                    onKeyUp={this.onKeyUp}
                    onKeyDown={this.onKeyDown}
                    onFocus={this.onFocus}
                    placeholder="Поиск по товарам"
                    autoComplete="off"
                />
                <PopperInfo
                    id="products_popper"
                    open={open}
                    anchorEl={anchorEl}
                    clickAway={this.clickAway}
                    position="bottom-start"
                >
                    <ul className="search_products_list">
                        {isArray(items) ?
                            items.map(el => {
                                const { id, product, article_number, product_producer, price, price_list, amount, location } = el;
                                return (
                                    <li
                                        className="search_product_item"
                                        onClick={() => this.selectProduct(el)}
                                        key={id}
                                    >
                                        <div className="search_product_item_head">
                                            {this.highlightText(product)}
                                            <span>{article_number}</span>
                                        </div>
                                        <div className="search_product_item_info">
                                            <div>
                                                <span className="block_label">Производитель</span>
                                                <p className="block_value">{product_producer}</p>
                                            </div>
                                            <div>
                                                <span className="block_label">В наличии</span>
                                                <p className="block_value">{amount}</p>
                                            </div>
                                            <div>
                                                <span className="block_label">Прайс-лист</span>
                                                <p className="block_value">{price_list}</p>
                                            </div>
                                            <div>
                                                <span className="block_label">Место хранения</span>
                                                <p className="block_value">{location !== null ? location : <span>&nbsp;</span>}</p>
                                            </div>
                                            <div className="block_value_currency">
                                                {price} <i>₽</i>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })
                            : <li>Нет товаров</li>
                        }
                    </ul>
                </PopperInfo>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getProducts
};

export default connect(null, mapDispatchToProps)(SearchProducts);