import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BackLink from "../../BackLink/BackLink";
import PriceDownload from "./PriceDownload/PriceDownload";
import PriceSettings from "./PriceSettings/PriceSettings";

import "./AddPriceList.scss";

class AddPriceList extends Component {
  state = {
    tab: 0,
    additionalInfo: undefined,
    accounting: false,
  };

  changeTab = (tab) => {
    this.setState({ tab });
  };

  render() {
    const { history } = this.props;
    const { tab, additionalInfo, accounting } = this.state;
    console.log(additionalInfo);
    return (
      <div className="container ">
        <div className="title-block">
          <BackLink to="/admin/price-list" />
          <h1>Добавить прайс-лист</h1>
        </div>
        <div className="price-add">
          <div className="tab-button">
            <button className={`${tab === 0 ? "active" : ""}`}>
              1. Загрузка файла
            </button>
            <button className={`${tab === 1 ? "active" : ""}`}>
              2. Настройки
            </button>
          </div>

          {tab === 0 && (
            <PriceDownload
              changeTab={this.changeTab}
              setAdditionalInfo={(additionalInfo) =>
                this.setState({ additionalInfo })
              }
              accounting={accounting}
              changeAccounting={() =>
                this.setState(({ accounting }) => ({
                  accounting: !accounting,
                }))
              }
            />
          )}
          {tab === 1 && (
            <PriceSettings
              history={history}
              additionalInfo={additionalInfo}
              accounting={accounting}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    //name: state.name
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      //login
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPriceList);
