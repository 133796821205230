import React, { Component } from "react";
import IconChevron from "../../../../assets/image/chevron.png";
import "./../OrderItems.scss";
import moment from "moment";
import {
    getPurchasesStatus,
    getPurchasesStatusColor,
    splitByCommas,
} from "../../../../helpers/functions";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";

class OrderItem extends Component {
    state = {
        isOpen: true,
    };

    open = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
        const { item } = this.props;
        const { isOpen } = this.state;
        return (
            <div
                className={`table_row${
                    item.status === "denied"
                        ? " red-status"
                        : item.status === "complete"
                        ? " green-status"
                        : item.status === "on_way"
                        ? " yellow-status"
                        : item.status === "confirmed"
                        ? " confirmed-status"
                        : ''
                }`}
                key={item.id}
            >
                <div className="row_orders">
                    <div className="row_item">
                        <div>{item.article_number}</div>
                    </div>
                    <div className="row_item">
                        <div>{item.producer}</div>
                    </div>
                    <div className="row_item">
                        <div data-tip data-for={`name-${item.id}`}>
                            {item.name}
                        </div>
                        {item.name && item.name.length > 36 && (
                            <ReactTooltip
                                id={`name-${item.id}`}
                                effect="solid"
                                place="top"
                                backgroundColor="#FFFFFF"
                                textColor="#334150"
                                className="tooltip"
                            >
                                <span>{item.name}</span>
                            </ReactTooltip>
                        )}
                    </div>
                    <div className="row_item">
                        <div>{item.amount}</div>
                    </div>
                    <div className="row_item">
                        <div>{!!item.expelled ? item.expelled : "0"}</div>
                    </div>
                    <div className="row_item">
                        <div>{!!item.in_stock ? item.in_stock : "0"}</div>
                    </div>
                    {item.hasOwnProperty("canceled") && (
                        <div className="row_item">
                            <div>{!!item.canceled ? item.canceled : "0"}</div>
                        </div>
                    )}
                    <div className="row_item">
                        <div>
                            {item.price && splitByCommas(item.price.toFixed(2))}{" "}
                            <span className="ru_value">₽</span>
                        </div>
                    </div>
                    <div className="row_item">
                        <div>{item.delivery_time} дн.</div>
                    </div>                    

                    <div className="row_item">
                        <div className="block_status">
                            <span
                                className={classNames({
                                    status_orders: true,
                                    [getPurchasesStatusColor(
                                        item.status
                                    )]: true,
                                })}
                            />{" "}
                            {getPurchasesStatus(item.status)}
                        </div>
                    </div>
                    <div className="row_item">
                        <div data-tip data-for={`comment-${item.id}`}>
                            {item.comment}
                        </div>
                        {item.comment && item.comment.length > 41 && (
                            <ReactTooltip
                                id={`comment-${item.id}`}
                                effect="solid"
                                place="top"
                                backgroundColor="#FFFFFF"
                                textColor="#334150"
                                className="tooltip"
                            >
                                <span>{item.comment}</span>
                            </ReactTooltip>
                        )}
                    </div>
                    <div className="row_item">
                        <div>#{item.id}</div>
                    </div>
                    {/*<div className="row_item">*/}
                    {/*{isOpen ?*/}
                    {/*    <button className="btn_arrow_up" onClick={this.open}>*/}
                    {/*        <img src={IconChevron} alt="IconChevron"/>*/}
                    {/*    </button>*/}
                    {/*    :*/}
                    {/*    <button className="btn_arrow_down" onClick={this.open}>*/}
                    {/*        <img src={IconChevron} alt="IconChevron"/>*/}
                    {/*    </button>*/}
                    {/*}*/}
                    {/*</div>*/}
                </div>
                {/* {isOpen ? (
                    <div className="info_items">
                        <div className="name">
                            <div>
                                {item.article_number} • {item.producer}
                            </div>
                            <span>{item.name}</span>
                        </div>
                        {item.comment ? (
                            <div>
                                <div className="descriptions">Комментарий</div>
                                <div className="comment">{item.comment}</div>
                            </div>
                        ) : null}
                    </div>
                ) : null} */}
            </div>
        );
    }
}

export default OrderItem;
