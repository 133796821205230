
import * as types from './constants.jsx';

//add cart action
export const addToCart= (id, amount)=>{
    return{
        type: types.ADD_TO_CART,
        id,
        amount
    }
};
//remove item action
export const removeItem=(id)=>{
    return{
        type: types.REMOVE_ITEM,
        id
    }
};
//subtract qt action
export const subtractQuantity=(id)=>{
    return{
        type: types.SUB_QUANTITY,
        id
    }
};
//add qt action
export const addQuantity=(id)=>{
    return{
        type: types.ADD_QUANTITY,
        id
    }
};

//disable qt action
export const disableItem=(id)=>{
    return{
        type: types.DISABLED_ITEM,
        id
    }
};

export const disableItems=()=>{
    return{
        type: types.DISABLED_ITEMS,

    }
};


export const addComment=(id, comment)=>{
    return{
        type: types.ADD_COMMENT,
        id,
        comment
    }
};

export const clearCart=()=>{
    return{
        type: types.CLEAR_CART,

    }
};

export function getActualDate(arr){
    return {
        type: types.GET_MY_ACTUAL_DATA,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/japarts/actual-cart${arr.length && arr.length !== 0 ? '?' : ''}${arr.join('&')}`,
                method: "get"
            }
        }
    };
}
export const setAmount=(id, amount)=>{
    return{
        type: types.SET_AMOUNT,
        id,
        amount
    }
};
export const changeToast=()=>{
    return{
        type: types.CHANGE_CART_TOAST,
    }
};


