import React from "react";
import Select, { components } from "react-select";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ReactComponent as ExpandIcon } from "../../../assets/image/select_search_icon.svg";

import "./SelectComponent.scss";

const ClearIndicator = (props) => {
  const {
    children = <CloseIcon />,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref}>
      <div className="select_close_btn">{children}</div>
    </div>
  );
};

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <div
          className={
            props.selectProps.menuIsOpen
              ? "select_indicator indicator_active"
              : "select_indicator"
          }
        >
          {props.selectProps.withSearchIcon ? (
            <ExpandIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </div>
      </components.DropdownIndicator>
    )
  );
};

const SelectComponent = ({
  value,
  options,
  menuIsOpen,
  loading,
  change,
  placeholder,
  isClearable,
  isSearchable = true,
  withSearchIcon,
  blur,
  menuPortalTarget,
}) => (
  <Select
    className="select_component"
    classNamePrefix="select"
    isDisabled={false}
    menuIsOpen={menuIsOpen}
    withSearchIcon={withSearchIcon}
    isLoading={loading}
    isClearable={!!isClearable ? false : true}
    isSearchable={isSearchable}
    name="color"
    value={value}
    options={options}
    onChange={change}
    onBlur={blur}
    noOptionsMessage={() => "Нет совпадений"}
    loadingMessage={() => "Загрузка..."}
    placeholder={placeholder}
    components={{ ClearIndicator, DropdownIndicator }}
  />
);

export default SelectComponent;
