import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import IconButton from '@material-ui/core/IconButton';

import { deleteOrder, getOrders } from "../../../actions/ordersActions";

import CloseDialogIcon from '../../../assets/image/Mask.png';

class DeleteOrder extends Component {
    state = {
        open: false
    };

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };

    deleteItem = () => {
        const { deleteOrder, getOrders, id, history } = this.props;
        let activePage = localStorage.pageOrders;
        if(!!localStorage.pageOrders && Number(localStorage.resultsOrders) === 1) {
            localStorage.pageOrders = Number(activePage) - 1;
        }
        deleteOrder(id).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 204) {
                let arr = [];
                arr.push(`page=${!!localStorage.pageOrders ? localStorage.pageOrders : 1 }`);
                getOrders(arr);
                history.push('/admin/orders');
            }
        });
    };

    render(){
        const { open } = this.state;
        return (
            <Fragment>
                {/*<DefaultButton
                    variant="outlined"
                    classes="delete_btn"
                    onClick={this.toggleDialog}
                >
                    Удалить
                </DefaultButton>*/}
                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                >
                    <div className="title-dialog">Удалить заказ</div>
                    <div className="descriptions">Вы уверены?</div>
                    <div className="btn-wrapper">
                        <DefaultButton
                            variant="outlined"
                            classes="cancel_btn"
                            onClick={this.toggleDialog}
                        >
                            Отмена
                        </DefaultButton>
                        <DefaultButton
                            onClick={this.deleteItem}
                        >
                            Удалить
                        </DefaultButton>
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button"
                        }}
                        onClick={this.toggleDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon"/>
                    </IconButton>
                </DialogComponent>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    deleteOrder,
    getOrders
};

export default connect(null, mapDispatchToProps)(DeleteOrder);