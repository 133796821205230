import { PRICE_LIST } from "./ListActionTypes";

const INITIAL_STATE = {
  list: [],
  searched: [],
  addedOffers: [],
  producersList: [],
  priceListInfo: {},
  loading: false,
  addedError: false,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRICE_LIST.GET_PRICE_LIST_SUCCESS:
      return { ...state, list: action.payload.data, loading: true };

    case PRICE_LIST.GET_PRODUCER_LIST_SUCCESS:
      return {
        ...state,
        producersList: action.payload.data.map((el) => ({
          value: el.id,
          label: el.name,
        })),
      };

    case PRICE_LIST.GET_PRICE_LIST_INFO_SUCCESS:
      return { ...state, priceListInfo: action.payload.data };

    case PRICE_LIST.UPDATE_PRICE_LIST_SUCCESS:
      return { ...state };

    case PRICE_LIST.ADD_PRICE_LIST_SUCCESS:
      state.addedOffers = [];
      state.list.unshift(action.payload.data);
      return { ...state, addedError: false };

    case PRICE_LIST.ADD_PRICE_LIST_FAIL:
      return { ...state, addedError: true };

    case PRICE_LIST.DELETE_PRICE_LIST_SUCCESS:
      return { ...state };

    case PRICE_LIST.CHANGE_VALUE_OFFERS:
      let { rowIndex, colName, value, producerId } = action.payload.data;
      state.list[rowIndex][colName] = value;
      state.list[rowIndex].producer_id = producerId
        ? producerId
        : state.list[rowIndex].producer_id;
      return { ...state };

    case PRICE_LIST.CHANGE_VALUE_ADDED:
      let {
        AddedRowIndex,
        AddedColName,
        valueAdded,
        AddedproducerId,
      } = action.payload.data;
      state.addedOffers[AddedRowIndex][AddedColName] = valueAdded;
      state.addedOffers[AddedRowIndex].producer_id = AddedproducerId
        ? AddedproducerId
        : state.addedOffers[AddedRowIndex].producer_id;
      return { ...state };

    case PRICE_LIST.DELETE_OFFERS:
      state.list.splice(action.payload.data, 1);
      return { ...state };

    case PRICE_LIST.DELETE_ADDED:
      state.addedOffers.splice(action.payload.data, 1);
      return { ...state };

    case PRICE_LIST.ADD_OFFERS:
      state.addedOffers.unshift({
        producer_id: "",
        article_number: "",
        product_name: "",
        amount: "",
        price: "",
        location: "",
        purchase_price: null,
        wholesale_price: null,
        lf_price: null,
      });
      return { ...state };

    case PRICE_LIST.RESET_STATE:
      return { ...state, loading: false, addedOffers: [] };

    case PRICE_LIST.CLEAR_LIST:
      return { ...state, list: [] };
    case PRICE_LIST.SEARCH_OFFERS:
      return {
        ...state,
        searched: state.list.filter((el) =>
          Object.values(el).some((elem) =>
            String(elem)
              .toLocaleLowerCase()
              .includes(action.payload.data.toLocaleLowerCase())
          )
        ),
      };

    default:
      return state;
  }
}
