import React from 'react';
import DefaultButton from '../../Buttons/DefaultButton/DefaultButton';

import LogoHeader from '../../../assets/image/logo_header.png';

const auth_urls = ['/auth', "/auth/approve", "/auth/password-recovery"];
const hidden_urls = ["/auth/activation", "/auth/password-recovery/approve"];

const AuthHead = ({location}) => {
    let isRegister = auth_urls.includes(location.pathname);
    // let isHidden = hidden_urls.includes(location.pathname) && location.search !== '';
    let isHidden = hidden_urls.includes(location.pathname);
    return (
        <header className="auth_header">
            <img src={LogoHeader} alt="logo header"/>
            <div className="flex-center" style={{display: isHidden ? 'none' : 'flex'}}>
                <p>{isRegister ? 'Еще нет аккаунта?' : 'Уже есть аккаунт?'}</p>
                <DefaultButton
                    variant="outlined"
                    type="link"
                    to={
                        isRegister
                        ? '/auth/register'
                        : '/auth'
                    }
                >
                    {isRegister
                        ? 'Зарегистрироваться'
                        : 'Войти'
                    }
                </DefaultButton>
            </div>
        </header>
    );
};

export default AuthHead;