import React, {Component} from 'react';
import { Field, reduxForm, SubmissionError, stopSubmit } from 'redux-form';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import RenderField from "../../HelperComponents/RenderField/RenderField";
import RenderAutocomplete from "../../HelperComponents/RenderAutocomplete/RenderAutocomplete";
import DefaultButton from '../../Buttons/DefaultButton/DefaultButton';

import { postRegister } from "../../../actions/authActions";

class Register extends Component {
    state = {
        loading: false
    };

    submitForm = data => {
        const { postRegister, history} = this.props;
        this.setState({loading: true});
        window.ymaps.geocode(data.address).then(res => {
            const point = res.geoObjects.get(0).geometry.getCoordinates();
            let obj = {
                ...data,
                point: point,
                role: "seller"
            };
            return postRegister(obj).then(res => {
                if(res.payload && res.payload.status && res.payload.status === 201) {
                    history.push('/auth/activation');
                } else {
                    // Название компании должно быть уникальным
                    let action = stopSubmit("RegisterForm", res.error.response.data);
                    this.props.dispatch(action);
                    this.setState({loading: false});
                    throw new SubmissionError({...res.error.response.data, _error: res.error.response.data.non_field_errors ? res.error.response.data.non_field_errors : 'Error.'});
                }
            });
        });
        // let obj = {
        //     ...data,
        //     point: [55.753215, 37.622504],
        //     role: "seller"
        // };
        // return postRegister(obj).then(res => {
        //     if(res.payload && res.payload.status && res.payload.status === 201) {
        //         history.push('/auth/activation');
        //     } else {
        //         this.setState({loading: false});
        //         throw new SubmissionError({...res.error.response.data, _error: res.error.response.data.non_field_errors ? res.error.response.data.non_field_errors : 'Error.'});
        //     }
        // });
    };

    onSelect = (e, name) => {
        const { change } = this.props;
        change(name, e.get('item').value);
    };

    render(){
        const { handleSubmit, submitting, pristine, valid, error } = this.props;
        const { loading } = this.state;
        return (
            <form onSubmit={handleSubmit(this.submitForm)}>
                <h3 className="auth-block_head">Регистрация</h3>
                <Field name="company" type="text" component={RenderField} label="Название компании"/>
                <Field name="email" type="text" component={RenderField} label="Электронный адрес"/>
                <Field name="password" type="password" component={RenderField} label="Пароль" password/>
                <hr/>
                <Field name="phone" type="number" component={RenderField} label="Телефон"/>
                <Field id="suggest" name="address" type="text" component={RenderAutocomplete} onSelect={this.onSelect} label="Адрес"/>
                <DefaultButton
                    variant="contained"
                    classes="full_btn_width"
                    disabled={submitting || pristine || !valid}
                    loading={loading}
                    formAction
                >
                    Зарегистрироваться
                </DefaultButton>
                {error && error !== null
                    ? <p className="auth_error">{error}</p>
                    : null
                }
            </form>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.company) {
        errors.company = 'Required'
    } else if (values.company.length < 3) {
        errors.company = 'Must be 3 characters or more'
    }
    if (!values.email) {
        errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
        errors.email = 'Invalid email'
    }
    if (!values.password) {
        errors.password = 'Required'
    } else if (values.password.length < 8) {
        errors.password = 'Must be 8 characters or more'
    }
    if (!values.phone) {
        errors.phone = 'Required'
    }
    if (!values.address) {
        errors.address = 'Required'
    }
    return errors
};

Register = reduxForm({
    form: 'RegisterForm',
    validate
})(Register);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postRegister
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(Register);