import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import BackLink from "../BackLink/BackLink";
import Button from "@material-ui/core/Button";
import PriceItem from "./PriceItem/PriceItem";
import Loader from "../HelperComponents/Loader/Loader";
import NoItems from "../HelperComponents/NoItems/NoItems";
import Pagination from "../HelperComponents/Pagination/Pagination";

import {
  getPriceLists,
  getPriceListAddresses,
} from "../../actions/priceListActions";

import { resetState, clearList } from "../PriceList/PriceInner/ListActions";

import AddIcon from "../../assets/image/add.png";

import "./PriceList.scss";
import PricesIcon from "../../assets/image/no_prices.png";

class PriceList extends Component {
  state = {
    loading: true,
    activePage: 1,
  };

  componentDidMount() {
    const {
      getPriceLists,
      getPriceListAddresses,
      history,
      resetState,
      clearList,
    } = this.props;
    getPriceLists(1).then((res) => {
      if (res.payload && res.payload.status && res.payload.status === 200) {
        this.setState({ loading: false });
      }
      if (res.error && res.error.response.status === 401) {
        localStorage.clear();
        history.push("/auth");
      }
    });
    getPriceListAddresses();
    resetState();
    clearList();
  }

  changePage = (pageNumber) => {
    const { getPriceLists } = this.props;
    this.setState({ loading: true, activePage: pageNumber });

    getPriceLists(pageNumber).then((res) => {
      if (res.payload && res.payload.status && res.payload.status === 200) {
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const {
      price_list: { price_lists, addresses_list },
      history,
    } = this.props;
    const { loading, activePage } = this.state;

    return (
      <main className="container price-list">
        <div className="title-block">
          <BackLink />
          <div className="flex-center-btw">
            <h1>Прайс-листы</h1>
            <Button
              component={Link}
              to="/admin/price-list/add"
              disableRipple={true}
              classes={{
                root: "add_btn",
              }}
            >
              Добавить
              <img src={AddIcon} alt="edit icon" />
            </Button>
          </div>
        </div>
        <div className="dashboard-section_content">
          {!loading && addresses_list.data && addresses_list.data.length > 0 ? (
            price_lists.results.length === 0 ? (
              <NoItems>
                <div className="box-img">
                  {" "}
                  <img src={PricesIcon} alt="edit icon" />
                </div>
                <div className="title">Пока нет ни одного прайс-листа</div>
                <div className="descriptions">
                  После добавления первого прайс-листа Вы увидите его здесь
                </div>
              </NoItems>
            ) : (
              price_lists.results.map((data) => (
                <PriceItem
                  key={data.id}
                  {...data}
                  addresses_list={addresses_list.data}
                  history={history}
                  // refreshPrice={this.refreshPrice}
                  // removePrice={this.removePrice}
                />
              ))
            )
          ) : (
            <div className="loader_wrapper_center">
              <Loader />
            </div>
          )}
        </div>
        <div>
          {price_lists.count && price_lists.count > 12 ? (
            <Pagination
              current={activePage}
              pageCount={12}
              total={price_lists.count}
              onChange={this.changePage}
            />
          ) : null}
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    price_list: state.price_list,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getPriceLists, getPriceListAddresses, resetState, clearList },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceList);
