import React, {Component} from 'react';
import { Field, reduxForm } from 'redux-form';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import RenderCheckField from "../HelperComponents/RenderCheckField/RenderCheckField";
import RenderTimeField from "../HelperComponents/RenderTimeField/RenderTimeField";
import RenderAutocomplete from "../HelperComponents/RenderAutocomplete/RenderAutocomplete";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import Loader from '../HelperComponents/Loader/Loader';

import { days } from "../../helpers/functions";
import { postNewAddress, patchAddress } from "../../actions/userActions";

import AddIcon from '../../assets/image/add.png';
import CloseDialogIcon from '../../assets/image/Mask.png';

class AddRepresentation extends Component {
    state = {
        open: false,
        loading: false,
        mapOptions: {center: [55.753215, 37.622504], zoom: 12},
        mapLoaded: false
    };

    componentWillUnmount() {
        clearTimeout(this.loadingTimeout);
    }

    componentWillReceiveProps(nextProps) {
        const { mapOptions } = this.state;
        if(nextProps.edit && nextProps.map !== undefined && mapOptions.id === undefined) {
            this.setState({mapOptions: nextProps.map});
        }
    }

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };

    submitForm = data => {
        const { postNewAddress, patchAddress, setMap, edit, id, reset } = this.props;
        const { mapOptions } = this.state;
        let arr = days.map(({name}) => {
            return {
                is_enabled: data[name] || false,
                working_day: name,
                from_hour: data[`${name}_from`] || '00:00',
                to_hour: data[`${name}_to`] || '00:00'
            }
        });
        let obj = {
            coordinates: data.address,
            point: mapOptions.center,
            workingtime: arr
        };
        this.setState({loading: true});
        if(edit) {
            let option = {
                id,
                ...mapOptions
            };
            setMap(option);
            patchAddress(id, obj).then(res => {
                if(res.payload && res.payload.status && res.payload.status === 200) {
                    this.setState({open: false});
                    this.loadingTimeout = setTimeout(() => {
                        this.setState({loading: false});
                    }, 300);
                } else {
                    this.setState({loading: false});
                }
            });
        } else {
            postNewAddress(obj).then(res => {
                if(res.payload && res.payload.status && res.payload.status === 201) {
                    reset();
                    this.setState({open: false, mapOptions: {center: [55.753215, 37.622504], zoom: 12}});
                    this.loadingTimeout = setTimeout(() => {
                        this.setState({loading: false});
                    }, 300);
                } else {
                    this.setState({loading: false});
                }
            });
        }
    };

    onLoadMap = () => {
        this.setState({mapLoaded: true});
    };

    onSelect = (e, name) => {
        const { change } = this.props;
        let value = e.get('item').value;
        change(name, value);

        window.ymaps.geocode(value).then(res => {
            this.setState({
                mapOptions: {
                    center: res.geoObjects.get(0).geometry.getCoordinates(),
                    zoom : 12
                },
            });
        });
    };

    clickOnMap = (e) => {
        const { change } = this.props;
        let coords = e.get('coords');
        window.ymaps.geocode(coords).then(res => {
            let firstGeoObject = res.geoObjects.get(0);
            change('address', firstGeoObject.getAddressLine());
        });

        this.setState({
            mapOptions: {
                center: coords,
                zoom : 12
            }
        });
    };

    render(){
        const { handleSubmit, submitting, pristine, valid, edit } = this.props;
        const { open, loading, mapOptions, mapLoaded } = this.state;
        return (
            <div>
                {edit ?
                    <Button
                        classes={{
                            root: 'blue_btn'
                        }}
                        onClick={this.toggleDialog}
                        disableRipple ={true}
                    >
                        Изменить
                    </Button>
                    :
                    <Button
                        classes={{
                            root: 'add_btn'
                        }}
                        disableRipple ={true}
                        onClick={this.toggleDialog}
                    >
                        Добавить
                        <img src={AddIcon} alt="edit icon"/>
                    </Button>
                }
                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                    classes="representation_dialog"
                >
                    <div className="title-dialog">
                        {edit
                            ? 'Изменить представительство'
                            : 'Добавить представительство'
                        }
                    </div>
                    <form className="dialog-form" onSubmit={handleSubmit(this.submitForm)}>
                        <div className="dialog-field-representation">
                            <Field id="suggest_add" name="address" type="text" component={RenderAutocomplete} onSelect={this.onSelect} label="Адрес"/>
                        </div>
                        <div className="representation-block">
                            <div className="schedule">
                                <div className="title">График работы</div>
                                {days.map(({name, label}, i) => (
                                    <div className="schedule-day" key={i}>
                                        <Field name={name} component={RenderCheckField} label={label} />
                                        <Field name={`${name}_from`} component={RenderTimeField} />
                                        <p>-</p>
                                        <Field name={`${name}_to`} component={RenderTimeField} />
                                    </div>
                                ))}
                            </div>
                            <div className="map">
                                <YMaps>
                                    <div className="map_wrapper">
                                        {!mapLoaded ? <Loader/> : null}
                                        <Map
                                            width={537}
                                            height={426}
                                            state={mapOptions}
                                            onClick={this.clickOnMap}
                                            onLoad={this.onLoadMap}
                                        >
                                            <Placemark geometry={mapOptions.center} />
                                        </Map>
                                    </div>
                                </YMaps>
                            </div>
                        </div>
                        <div className="btn-wrapper">
                            <DefaultButton
                                onClick={this.toggleDialog}
                                variant="outlined"
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                disabled={submitting || pristine || !valid}
                                loading={loading}
                                formAction
                            >
                                {edit
                                    ? 'Сохранить'
                                    : 'Добавить'
                                }
                            </DefaultButton>
                        </div>
                    </form>
                    <IconButton
                        classes={{
                            root: "dialog_close_button"
                        }}
                        onClick={this.toggleDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon"/>
                    </IconButton>
                </DialogComponent>
            </div>
        );
    }
}

AddRepresentation = reduxForm({
    enableReinitialize: true,
    //validate
})(AddRepresentation);

function mapStateToProps(state, props) {
    return{
        //name: state.name,
        initialValues: {
            address: props.edit && props.coordinates ? props.coordinates : '',
            monday: props.edit && props.workingtime[0] && props.workingtime[0].is_enabled ? props.workingtime[0].is_enabled : false,
            monday_from: props.edit && props.workingtime[0] && props.workingtime[0].from_hour ? props.workingtime[0].from_hour : '09:00',
            monday_to: props.edit && props.workingtime[0] && props.workingtime[0].to_hour ? props.workingtime[0].to_hour : '18:00',
            tuesday: props.edit && props.workingtime[1] && props.workingtime[1].is_enabled ? props.workingtime[1].is_enabled : false,
            tuesday_from: props.edit && props.workingtime[1] && props.workingtime[1].from_hour ? props.workingtime[1].from_hour : '09:00',
            tuesday_to: props.edit && props.workingtime[1] && props.workingtime[1].to_hour ? props.workingtime[1].to_hour : '18:00',
            wednesday: props.edit && props.workingtime[2] && props.workingtime[2].is_enabled ? props.workingtime[2].is_enabled : false,
            wednesday_from: props.edit && props.workingtime[2] && props.workingtime[2].from_hour ? props.workingtime[2].from_hour : '09:00',
            wednesday_to: props.edit && props.workingtime[2] && props.workingtime[2].to_hour ? props.workingtime[2].to_hour : '18:00',
            thursday: props.edit && props.workingtime[3] && props.workingtime[3].is_enabled ? props.workingtime[3].is_enabled : false,
            thursday_from: props.edit && props.workingtime[3] && props.workingtime[3].from_hour ? props.workingtime[3].from_hour : '09:00',
            thursday_to: props.edit && props.workingtime[3] && props.workingtime[3].to_hour ? props.workingtime[3].to_hour : '18:00',
            friday: props.edit && props.workingtime[4] && props.workingtime[4].is_enabled ? props.workingtime[4].is_enabled : false,
            friday_from: props.edit && props.workingtime[4] && props.workingtime[4].from_hour ? props.workingtime[4].from_hour : '09:00',
            friday_to: props.edit && props.workingtime[4] && props.workingtime[4].to_hour ? props.workingtime[4].to_hour : '18:00',
            saturday: props.edit && props.workingtime[5] && props.workingtime[5].is_enabled ? props.workingtime[5].is_enabled : false,
            saturday_from: props.edit && props.workingtime[5] && props.workingtime[5].from_hour ? props.workingtime[5].from_hour : '09:00',
            saturday_to: props.edit && props.workingtime[5] && props.workingtime[5].to_hour ? props.workingtime[5].to_hour : '18:00',
            sunday: props.edit && props.workingtime[6] && props.workingtime[6].is_enabled ? props.workingtime[6].is_enabled : false,
            sunday_from: props.edit && props.workingtime[6] && props.workingtime[6].from_hour ? props.workingtime[6].from_hour : '09:00',
            sunday_to: props.edit && props.workingtime[6] && props.workingtime[6].to_hour ? props.workingtime[6].to_hour : '18:00',
        }
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postNewAddress,
        patchAddress
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRepresentation);