import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";

import { deleteContact } from "../../actions/userActions";

import CloseDialogIcon from '../../assets/image/Mask.png';

class DeleteContact extends Component {
    state = {
      open: false
    };

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };

    deleteItem = () => {
      const { deleteContact, id } = this.props;
        deleteContact(id);
    };

    render(){
        const { open } = this.state;
        return (
            <Fragment>
                <Button
                    className="red_btn"
                    onClick={this.toggleDialog}
                    disableRipple ={true}
                >
                    Удалить
                </Button>
                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                >
                    <div className="title-dialog">Удалить контакт</div>
                    <div className="descriptions">Вы уверены?</div>
                    <div className="btn-wrapper">
                        <DefaultButton
                            variant="outlined"
                            classes="cancel_btn"
                            onClick={this.toggleDialog}
                        >
                            Отмена
                        </DefaultButton>
                        <DefaultButton
                            onClick={this.deleteItem}
                        >
                            Удалить
                        </DefaultButton>
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button"
                        }}
                        onClick={this.toggleDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon"/>
                    </IconButton>
                </DialogComponent>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        //name: state.name
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        deleteContact
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteContact);