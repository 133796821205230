import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";

import { deleteAddress } from "../../actions/userActions";

import CloseDialogIcon from '../../assets/image/Mask.png';
import AlertIcon from '../../assets/image/alert.png';

class DeleteRepresentation extends Component {
    state = {
        open: false
    };

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };

    deleteItem = () => {
        const { deleteAddress, id } = this.props;
        deleteAddress(id);
    };

    render(){
        const { open } = this.state;
        return (
            <Fragment>
                <Button
                    className="red_btn"
                    onClick={this.toggleDialog}
                    disableRipple ={true}
                >
                    Удалить
                </Button>
                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                >
                    <div className="title-dialog">Удалить представительство</div>
                    <div className="block-change-info">
                        <div className="text-info">
                            <img src={AlertIcon} alt="alert icon"/>
                            Прайс-лист, связанный с этим представительством, также будет удален. Вы уверены?
                        </div>
                        <div className="btn-wrapper">
                            <DefaultButton
                                variant="outlined"
                                classes="cancel_btn"
                                onClick={this.toggleDialog}
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                onClick={this.deleteItem}
                            >
                                Удалить
                            </DefaultButton>
                        </div>
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button"
                        }}
                        onClick={this.toggleDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon"/>
                    </IconButton>
                </DialogComponent>
            </Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        deleteAddress
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(DeleteRepresentation);