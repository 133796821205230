import React, {Component, Fragment} from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import Rating from '../../HelperComponents/Rating/Rating';
import moment from 'moment';
import DefaultButton from '../../Buttons/DefaultButton/DefaultButton';
import RenderAreaField from '../../HelperComponents/RenderAreaField/RenderAreaField';

import { patchReview } from "../../../actions/reviewsActions";

import TimeIcon from '../../../assets/image/time.png';

class ReviewPageItem extends Component {
    state = {
        loading: false
    };

    submitForm = data => {
        const { patchReview, id } = this.props;
        this.setState({loading: true});
        return patchReview(id, data).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
            } else {
                this.setState({loading: false});
                throw new SubmissionError({
                    response: (res.error.response.data && res.error.response.data.detail) ||
                    (res.error.response.data && res.error.response.data.response)
                });
            }
        })
    };

    render(){
        const { handleSubmit, submitting, pristine, valid, date, full_name, rating, comment, processed, response } = this.props;
        const { loading } = this.state;
        return (
            <div className="reviews">
                <div className="info-time">
                    <img src={TimeIcon} alt="time icon"/>
                    <span>{moment(date).format('DD.MM:YYYY HH:mm')}</span>
                </div>
                <div className="reviews-wrapper">
                    <div className="name">
                        {full_name}
                    </div>
                    <Rating
                        value={rating}
                    />
                </div>
                {processed ?
                    <Fragment>
                        <div className="descriptions">
                            {comment}
                        </div>
                        <div className="descriptions">
                            <span>Ваш ответ: </span>
                            {response}
                        </div>
                    </Fragment>
                    :
                    <form onSubmit={handleSubmit(this.submitForm)}>
                        <div className="descriptions">
                            {comment}
                        </div>
                        <Field name="response" component={RenderAreaField} placeholder="Ваше сообщение..."/>
                        <DefaultButton
                            variant="contained"
                            disabled={submitting || pristine || !valid}
                            loading={loading}
                            formAction
                        >
                            Ответить
                        </DefaultButton>
                    </form>
                }
            </div>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.response) {
        errors.response = 'Required'
    } else if (values.response.length < 3) {
        errors.response = 'Must be 3 characters or more'
    }
    return errors
};

ReviewPageItem = reduxForm({
    validate
})(ReviewPageItem);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        patchReview
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(ReviewPageItem);