import React from 'react';

const RenderAreaField = ({ input, placeholder, autoFocus, symbol, disabled, meta: {touched, error} }) => {
    return (
        <div className="custom_area_field">
            <textarea
                {...input}
                placeholder={placeholder}
                autoFocus={autoFocus}
                disabled={disabled}
            />
        </div>
    );

};

export default RenderAreaField;