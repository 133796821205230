import * as types from "../actions/constants";
import actions from "redux-form/lib/actions";



const checkTotal = (cart) => {
    let total = 0;
    let cart_items = cart.filter(item => item.active === true);
    cart_items.map(item => {
        total += parseFloat(item.amount) * parseFloat(item.price)
    });
    localStorage.setItem('total', parseFloat(total.toFixed(2)));
    return total.toFixed(2)
};

const chekedAll = (cart) => {
    cart.map((item) => {
        item.active = true
    });
    localStorage.setItem('cart', JSON.stringify(cart));

    return cart
};

const initState = {
    items: [],
    all_items: [],
    addedItems: chekedAll(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : []),
    total: checkTotal(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : []),
    allChecked: localStorage.getItem("cart") ? true : false,
    basketUpdated: false,
    loading: false,
    created_success: false,
    create_items: 0,
    maxBalance: 0,
    createDisable: false
};


export default function (state = initState, action) {
    switch (action.type) {
        case types.SET_AMOUNT :
            let item = state.addedItems.find(item => action.id === item.offer_id);
            item.amount = action.amount;
            let total = checkTotal(state.addedItems);

            localStorage.setItem('cart', JSON.stringify(state.addedItems));

            return {
                ...state,
                addedItems: [...state.addedItems],
                total: total,
                createDisable: parseFloat(total) > parseFloat(state.maxBalance)
            };


        case types.GET_MY_ACTUAL_DATA_SUCCESS :

            let addItems = state.addedItems;
            let updatedItems = [];
            addItems.map(item => {
                let actualItem = action.payload.data.find(it => item.priceid === it.priceid);
                if (actualItem) {
                    item.pricerur = actualItem.pricerur;
                    item.quantity = actualItem.quantity;
                    item.lot = actualItem.lot;
                    if (item.amount > actualItem.quantity) {
                        item.amount = actualItem.quantity
                    }
                    updatedItems.push(item)
                }

                let newTotal = checkTotal(updatedItems);
                localStorage.setItem('cart', JSON.stringify(updatedItems));
                return {
                    ...state,
                    total: newTotal,
                    addedItems: state.addedItems,
                    basketUpdated: updatedItems === state.addedItems,
                    createDisable: parseFloat(newTotal) > parseFloat(state.maxBalance)

                }
            });


        /*
                case types.GET_PURCHASES_SUCCESS :
                    console.log(action.payload.data);
                    return {...state, items: action.payload.data};
        */

        case types.GET_PURCHASES_DETAIL_SUCCESS :
            let items;
            if(Object.keys(action.payload.data).length){
                items = [...action.payload.data.analogs, ...action.payload.data.originals];
            }else{
                items = [];
            }

            // action.payload.data.analogs.forEach(element => {
            //     items.push(element);
            // });
            // action.payload.data.analogs.forEach(element => {
            //     items.push(element);
            // });
            return {...state, items: items, all_items: action.payload.data};
        // return {...state, items: state.items.concat(action.payload.data.originals, action.payload.data.analogs)};


        case types.CLEAR_CART :
            state.addedItems = [];
            let newTotal = checkTotal(state.addedItems);
            localStorage.setItem('cart', JSON.stringify(state.addedItems));
            return {
                ...state,
                total: newTotal,
                addedItems: state.addedItems,
                allChecked: false,
                createDisable: parseFloat(newTotal) > parseFloat(state.maxBalance)

            };
        case types.CHANGE_CART_TOAST:
            return {
                ...state,
                created_success: false
            };


        case types.BALANACE_INFO_SUCCESS:
            const maxBalance = parseFloat(action.payload.data['balance']) + parseFloat(action.payload.data['max_minus']);
            return {
                ...state, maxBalance: maxBalance.toFixed(2), createDisable: parseFloat(state.total) > parseFloat(state.maxBalance)
            };


        case types.JAPARTS_ORDERS_CREATE:
            return {
                ...state,
                loading: true
            };
        case types.JAPARTS_ORDERS_CREATE_SUCCESS :
            const create_items = state.addedItems.filter(item => item.active === true).length;
            state.addedItems = state.addedItems.filter(item => item.active === false);
            let newTotal6 = checkTotal(state.addedItems);
            localStorage.setItem('cart', JSON.stringify(state.addedItems));
            return {
                ...state,
                total: newTotal6,
                addedItems: state.addedItems,
                loading: false,
                created_success: true,
                create_items: create_items,
                createDisable: parseFloat(newTotal6) > parseFloat(state.maxBalance)

            };
        case types.ADD_COMMENT :

            let addItem = state.addedItems.find(item => action.id === item.offer_id);
            addItem.comment = action.comment;
            localStorage.setItem('cart', JSON.stringify(state.addedItems));

            return {
                ...state,
                addedItems: state.addedItems,
            };


        case types.DISABLED_ITEMS :

            let checked = !state.allChecked;
            state.addedItems.map(item => {
                item.active = checked
            });
            let newTotal1 = checkTotal(state.addedItems);

            return {
                ...state,
                total: newTotal1,
                addedItems: state.addedItems,
                allChecked: checked,
                createDisable: parseFloat(newTotal1) > parseFloat(state.maxBalance)

            };


        case types.DISABLED_ITEM :
            let disabledItem = state.addedItems.find(item => action.id === item.offer_id);
            disabledItem.active = !disabledItem.active;
            let newTotal2 = checkTotal(state.addedItems);
            localStorage.setItem('cart', JSON.stringify(state.addedItems));
            return {
                ...state,
                total: newTotal2,
                allChecked: state.addedItems.filter(item => item.active === true).length === state.addedItems.length,
                createDisable: parseFloat(newTotal2) > parseFloat(state.maxBalance)

            };

        case types.GET_PRODUCTS_RECOMMENDATIONS_SUCCESS :
            return {
                ...state,
                items : [ ...state.items, ...action.payload.data ]
            };

        case types.ADD_TO_CART :
            let addedItemEl = state.items.find(item => item.offer_id === action.id);
            let existed_item = state.addedItems.find(item => action.id === item.offer_id);
            if (existed_item) {

                addedItemEl.price = parseFloat(addedItemEl.price) * parseFloat(action.amount);
                addedItemEl.amount = action.amount;

                let newTotal = checkTotal(state.addedItems);
                localStorage.setItem('cart', JSON.stringify(state.addedItems.concat(addedItemEl)));
                return {
                    ...state,
                    total: newTotal,
                    createDisable: parseFloat(newTotal) > parseFloat(state.maxBalance)

                }
            } else {

                addedItemEl.amount = action.amount;
                addedItemEl.active = true;
                addedItemEl.comment = "";
                addedItemEl.price = parseFloat(addedItemEl.price);
                //calculating the total
                let newTotal = checkTotal(state.addedItems.concat(addedItemEl));
                localStorage.setItem('cart', JSON.stringify(state.addedItems.concat(addedItemEl)));

                return {
                    ...state,
                    addedItems: [...state.addedItems, addedItemEl],
                    total: newTotal,
                    createDisable: parseFloat(newTotal) > parseFloat(state.maxBalance)

                }

            }

        case types.REMOVE_ITEM :
            let new_items = state.addedItems.filter(item => action.id !== item.offer_id);

            //calculating the total
            let newTotal3 = checkTotal(new_items);
            localStorage.setItem('cart', JSON.stringify(new_items));
            return {
                ...state,
                addedItems: new_items,
                total: newTotal3,
                createDisable: parseFloat(newTotal3) > parseFloat(state.maxBalance)

            };

        //--------------------------------------------------------------------------------------------------------------------------------------------------------------
        //--------------------------------------------------------------------------------------------------------------------------------------------------------------
        //--------------------------------------------------------------------------------------------------------------------------------------------------------------
        case types.ADD_QUANTITY :
            let addedItem1 = state.addedItems.find(item => item.offer_id === action.id);
            addedItem1.amount += parseInt(addedItem1.lot);
            if (addedItem1.amount > addedItem1.quantity) {
                addedItem1.amount = addedItem1.quantity
            }
            let newTotal4 = checkTotal(state.addedItems);
            localStorage.setItem('cart', JSON.stringify(state.addedItems));
            return {
                ...state,
                total: newTotal4,
                addedItems: state.addedItems,
                createDisable: parseFloat(newTotal4) > parseFloat(state.maxBalance)
            }

        case types.SUB_QUANTITY :
            let addedItem = state.addedItems.find(item => item.offer_id === action.id);
            //if the qt == 0 then it should be removed
            if (parseInt(addedItem.amount) <= 1 || (parseInt(addedItem.amount - addedItem.lot) === 0)) {
                let new_items = state.addedItems.filter(item => item.offer_id !== action.id);
                let newTotal = checkTotal(new_items);
                localStorage.setItem('cart', JSON.stringify(new_items));
                return {
                    ...state,
                    addedItems: new_items,
                    total: newTotal,
                    createDisable: parseFloat(newTotal) > parseFloat(state.maxBalance)

                }
            } else {

                addedItem.amount -= parseInt(addedItem.lot);

                let newTotal = checkTotal(state.addedItems);
                localStorage.setItem('cart', JSON.stringify(state.addedItems));
                return {
                    ...state,
                    total: newTotal,
                    addedItems: state.addedItems,
                    createDisable: parseFloat(newTotal) > parseFloat(state.maxBalance)


                }
            }
        default:
            return state;
    }
}
