import React, {Component, Fragment} from 'react';
import TransitionedBlock from '../../HelperComponents/TransitionedBlock/TransitionedBlock';
import PopperInfo from "../../HelperComponents/PopperInfo/PopperInfo";
import Search from '../../../assets/image/search_grey.png';
import NoItems from '../../../assets/image/no_orders_purchases.png';
import {connect} from "react-redux";
import {getPurchases, getPurchasesDetail, clearDataItem} from "../../../actions/PurchasesActions";
import {addToCart} from '../../../actions/cartActions'
import {bindActionCreators} from "redux";
import MyOrderItem from './myOrderItem/myOrderItem';
import classNames from 'classnames';

import './MyOrders.scss';
import Loader from "../../HelperComponents/Loader/Loader";


class MyOrders extends Component {

    state = {
        value: '',
        openModal: false,
        initSearch: false,
        hasResults: false,
        price_id: false,
        openPopper: false,
        origins: [],
        popperLoader: false,
        allOrders: [],
        isSearch: false
    };


    componentWillUnmount() {
        const {clearDataItem } = this.props;
        clearDataItem();
    }


    toggleDialog = () => {
        this.setState(({openModal}) => ({
            openModal: !openModal,
        }));
    };


    onChange = (e) => {
        let value = e.target.value;
        this.setState({value});
    };


    onKeyUp = (e) => {
        const {openPopper} = this.state;
        let value = e.target.value;
        if (e.keyCode === 13) {
            if (value.length >= 3 && value.trim() !== '') {
                this.setState({
                    openPopper: true,
                    popperLoader: true
                });
                this.getPurchases(value);
            } else if (value.length >= 3 && !openPopper) {
                this.setState({openPopper: true});
            }
        } else {
            this.setState({ openPopper: false });
        }
    };

    handleSearch = () => {
        const {openPopper, value} = this.state;
        if (value.length >= 3 && value.trim() !== '') {
            this.setState({
                openPopper: true,
                popperLoader: true
            });
            this.getPurchases(value);
        } else if (value.length >= 3 && !openPopper) {
            this.setState({openPopper: true});
        }
    };

    getPurchases = (val) => {
        const {getPurchases, clearDataItem} = this.props;
        const value = val.toUpperCase().replace(/[^A-Za-z0-9]+/g, "");
        getPurchases(value).then((res) => {
            if(res.payload && res.payload.status && res.payload.status === 200){
                if(!res.payload.data.length){
                    this.setState({openModal: true, popperLoader: false, hasResults: true});
                    clearDataItem();
                }else{
                    this.setState({ openPopper: true, popperLoader: false, openModal: false});
                }
            }else{
                this.setState({ openPopper: true, popperLoader: false});

            }
        })
    };


    selectProduct = (el) => {
        const {getPurchasesDetail} = this.props;
        this.setState({initSearch: true, openPopper: false});

        getPurchasesDetail(el.article_number, el.producer_original, el.description).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200){
                if((res.payload.data.analogs && res.payload.data.analogs.length) || (res.payload.data.originals && res.payload.data.originals.length)) {
                    this.setState({hasResults: true, initSearch: false, popperLoader: false, isSearch: true});
                }else{
                    this.setState({initSearch: false, hasResults: false, isSearch: true});
                }
            }
        })

    };





    groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    };

    changeTab = tab => {
        this.setState({tab});
    };


    clickAway = () => {
        this.setState({ openPopper: false });
    };

    toggleAllOrders = key => {
        if(!this.state.allOrders.includes(key)){
            this.setState({allOrders: [...this.state.allOrders, key]})
        }else{
            this.setState({allOrders: this.state.allOrders.filter(el => el !== key)})
        }

    };


    render() {
        //items_o
        const {value, openPopper, popperLoader, hasResults, initSearch, allOrders, openModal, isSearch} = this.state;
        const {items_obj, item_detail, items} = this.props;
        // if (!item_detail || items_obj.length === 0 || items.length === 0) return null;

        let globalArray;
        if(item_detail && items_obj.analogs && Object.keys(item_detail).length !== 0 ){
            globalArray = this.groupBy(items_obj.analogs, item => item.producer);
        }
        return (
            <TransitionedBlock>
                <div className="my_orders">
                    <div className="my_orders_search">
                        <input
                            type="text"
                            value={value}
                            onChange={this.onChange}
                            onKeyUp={this.onKeyUp}
                            placeholder="Введите артикул товара"
                            id='input'
                        />
                        <button className="btn_search" onClick={this.handleSearch}><img src={Search} alt=""/></button>
                        <PopperInfo
                            id="my_orders_popper"
                            open={openPopper}
                            anchorEl={document.getElementById('input')}
                            clickAway={this.clickAway}
                            position="bottom-start"
                            transition
                            popperLoader={popperLoader}
                        >
                            <ul className="search_products_list my_orders_ul" >
                                {
                                    items.length ?
                                        items.map((el, index) => {
                                            return(
                                                <li
                                                    className="search_product_item my_orders_li"
                                                    key={index}
                                                    onClick={() => {this.selectProduct(el)}}
                                                >
                                                    <p>
                                                        <span>{el.producer}</span>
                                                        <span className="my_orders_dot">•</span>
                                                        {el.description}
                                                        {
                                                            el.description !== '' ? <span className="my_orders_dot">•</span> : null
                                                        }
                                                        {el.article_number}
                                                    </p>
                                                </li>
                                            )
                                        }) :  <li>Нет товаров</li>
                                }
                            </ul>
                        </PopperInfo>
                    </div>
                    {
                        initSearch && !openModal && Object.keys(item_detail).length !== undefined ? <Loader/> :
                            Object.keys(item_detail).length || hasResults ?

                                <div>
                                    <div className="my_orders_table">
                                        {
                                            Object.keys(item_detail).length !== 0  && item_detail.originals.length ?
                                                <div className="transactions_columns table_container">
                                                    <div className="name_block">
                                                        <span>{item_detail.originals[0].producer}</span>
                                                        <b>•</b>{item_detail.originals[0].article_number}{item_detail.originals[0].description !== '' ? <b>•</b> : null}{item_detail.originals[0].description}
                                                        <div className='original'>ОРИГИНАЛ</div>
                                                    </div>
                                                    <div className={!allOrders.includes(1) ? 'testOriginal' : 'test isShow'}>
                                                        {
                                                            item_detail.originals.map((el, key) => {
                                                                return(
                                                                    <MyOrderItem item={el} key={key}
                                                                                 info={el.description || '-'}
                                                                                 handler={this.toggleDialog}
                                                                                 items_length={el}
                                                                                 lot={el.lot}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    {item_detail.originals.length > 6 ?
                                                        <button className="btn_hide" onClick={() => this.toggleAllOrders(1)}>• • • {allOrders.includes(1) ? "скрыть" : "показать все"}</button>
                                                        :
                                                        null
                                                    }
                                                </div> : null
                                        }
                                        {
                                            Object.keys(item_detail).length !== 0  && item_detail.analogs.length ?
                                                <div>
                                                    {
                                                        [...globalArray].map(([key, value]) => {
                                                            return (
                                                                <div className="transactions_columns table_container" key={key}>
                                                                    <div className="name_block">
                                                                        <span>{key}</span>
                                                                        <b>•</b>{value[0].article_number}<b>•</b>{value[0].description}
                                                                        <div className='analogue'>АНАЛОГ</div>
                                                                    </div>
                                                                    <div className={!allOrders.includes(key) ? 'test' : 'test isShow'}>
                                                                        {
                                                                            value.map((el, idx )=> {
                                                                                return (
                                                                                    <MyOrderItem item={el} key={idx}
                                                                                                 info={el.description || '-'}
                                                                                                 handler={this.toggleDialog}
                                                                                                 items_length={el}
                                                                                                 lot={el.lot}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    {value.length > 3 ?
                                                                        <button className="btn_hide" onClick={() => this.toggleAllOrders(key)}>• • • {allOrders.includes(key) ? "скрыть" : "показать все"}</button>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div> :
                                                null
                                        }
                                    </div>
                                </div> :
                                <div>
                                    {
                                        isSearch ?
                                            <div className="no_item_orders">
                                                <div className="box_img">
                                                    <img src={NoItems} alt="Ничего не найдено"/>
                                                </div>
                                                <div className="title_no_item">По вашему запросу не найдено ни одного предложения</div>
                                                <div className="descriptions_no_item">
                                                </div>
                                            </div> :
                                            <div className="no_item_orders">
                                                <div className="box_img">
                                                    <img src={NoItems} alt="Введите артикул товара"/>
                                                </div>
                                                <div className="title_no_item">Введите артикул товара</div>
                                                <div className="descriptions_no_item">Здесь будут отображены результаты Вашего поискового запроса</div>
                                            </div>
                                    }
                                </div>
                    }
                </div>
            </TransitionedBlock>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        items: state.purchases.items,
        items_obj: state.cart.all_items, //Не нужно
        item_detail: state.purchases.item_detail
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPurchases,
        getPurchasesDetail,
        addToCart,
        clearDataItem
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders);


