import React, {Component, Fragment} from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {  Redirect } from 'react-router-dom';

import RenderField from "../HelperComponents/RenderField/RenderField";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { postChangePassword } from "../../actions/authActions";

import CloseDialogIcon from '../../assets/image/Mask.png';
import SuccessIcon from '../../assets/image/success_icon.png';

class ChangePassword extends Component {
    state = {
        open: false,
        success: false,
        loading: false
    };

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };

    submitForm = data => {
        const { postChangePassword } = this.props;
        this.setState({loading: true});
        return postChangePassword(data).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
                // localStorage.token = res.payload.data.token;
                this.setState({success: true});
                localStorage.clear();
            } else {
                this.setState({loading: false});
                throw new SubmissionError({...res.error.response.data});
            }
        });
    };

    render(){
        const { handleSubmit, submitting, pristine, valid } = this.props;
        const { open, success, loading } = this.state;
        if(!localStorage.token) return <Redirect to="/auth" />;
        return (
            <Fragment>
                <Button
                    onClick={this.toggleDialog}
                    disableRipple ={true}
                    classes={{
                        root: 'add_btn'
                    }}
                >
                    Сменить пароль
                </Button>
                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                >
                    <div className="title-dialog">
                        Сменить пароль
                    </div>
                    {success ?
                        <div className="block-change-info">
                            <div className="text-info">
                                <img src={SuccessIcon} alt="success icon"/>
                                Ваш пароль успешно изменен!
                            </div>
                            <div className="btn-wrapper">
                                <DefaultButton
                                    onClick={this.toggleDialog}
                                >
                                    ОК
                                </DefaultButton>
                            </div>
                        </div>
                        :
                        <form className="dialog-form" onSubmit={handleSubmit(this.submitForm)}>
                            <div className="dialog-field-change change_password_fields">
                                <Field
                                    name="old_password"
                                    type="password"
                                    component={RenderField}
                                    label="Текущий пароль"
                                    password
                                />
                                <Field
                                    name="new_password"
                                    type="password"
                                    component={RenderField}
                                    label="Новый пароль"
                                    password
                                />
                            </div>
                            <div className="btn-wrapper">
                                <DefaultButton
                                    variant="outlined"
                                    classes="cancel_btn"
                                    onClick={this.toggleDialog}
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    disabled={submitting || pristine || !valid}
                                    loading={loading}
                                    formAction
                                >
                                    Подтвердить
                                </DefaultButton>
                            </div>
                        </form>
                    }
                    <IconButton
                        classes={{
                            root: "dialog_close_button"
                        }}
                        onClick={this.toggleDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon"/>
                    </IconButton>
                </DialogComponent>
            </Fragment>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.old_password) {
        errors.old_password = 'Required'
    } else if (values.old_password.length < 8) {
        errors.old_password = 'Must be 8 characters or more'
    }
    if (!values.new_password) {
        errors.new_password = 'Required'
    } else if (values.new_password.length < 8) {
        errors.new_password = 'Must be 8 characters or more'
    } else if (values.old_password === values.new_password) {
        errors.new_password = 'Passwords can\'t be same'
    }
    return errors
};

ChangePassword = reduxForm({
    form: 'ChangePasswordForm',
    validate
})(ChangePassword);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postChangePassword
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(ChangePassword);