import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import { Field, reduxForm, SubmissionError } from 'redux-form';
import {bindActionCreators} from "redux";
import Rating from '../../HelperComponents/Rating/Rating';
import moment from 'moment';
import CloseDialogIcon from "../../../assets/image/Mask.png";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import IconButton from '@material-ui/core/IconButton';
import TimeIcon from '../../../assets/image/time.png';

import { patchReviewDashboard } from "../../../actions/userActions";

import ClockIcon from '../../../assets/image/time.png';
import './ReviewItem.scss';
import RenderAreaField from '../../HelperComponents/RenderAreaField/RenderAreaField';


class ReviewItem extends Component {
    state = {
        loading: false,
        open: false
    };

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };

    submitForm = data => {
        const { patchReviewDashboard, id } = this.props;
        this.setState({loading: true});
        return patchReviewDashboard(id, data).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
            } else {
                this.setState({loading: false});
                throw new SubmissionError({
                    response: (res.error.response.data && res.error.response.data.detail) ||
                    (res.error.response.data && res.error.response.data.response)
                });
            }
        })
    };

    render() {
        const { date, full_name, user, rating, comment, processed, response, handleSubmit, submitting, pristine, valid,} = this.props;
        const {loading, open} = this.state;
        return (
            <div className="dashboard_block review-item">
                <div>
                    <p className="date_wrapper">
                        <img src={ClockIcon} alt="clock"/>
                        {moment(date).format('DD.MM.YYYY HH:mm')}
                    </p>
                </div>
                <div className="review_title">
                    <h5>{full_name}</h5>
                </div>
                <Rating
                    value={rating}
                />
                <div className="review_content">
                    <p>{comment}</p>
                </div>
                <hr/>
                <div className="review_btn_wrapper">
                    <button
                        onClick={this.toggleDialog}
                    >
                        {processed ? "Посмотреть ответ" : "Ответить" }
                    </button>
                </div>
                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                >
                    <div className="reviews">
                        <div className="info-time">
                            <img src={TimeIcon} alt="time icon"/>
                            <span>{moment(date).format('DD.MM:YYYY HH:mm')}</span>
                        </div>
                        <div className="reviews-wrapper">
                            <div className="name">
                                {full_name}
                            </div>
                            <Rating
                                value={rating}
                            />
                        </div>
                        {processed ?
                            <Fragment>
                                <div className="descriptions">
                                    {comment}
                                </div>
                                <div className="descriptions">
                                    <span>Ваш ответ: </span>
                                    {response}
                                </div>
                            </Fragment>
                            :
                            <form onSubmit={handleSubmit(this.submitForm)}>
                                <div className="descriptions">
                                    {comment}
                                </div>
                                <Field name="response" component={RenderAreaField} placeholder="Ваше сообщение..."/>
                                <DefaultButton
                                    variant="contained"
                                    disabled={submitting || pristine || !valid}
                                    loading={loading}
                                    formAction
                                >
                                    Ответить
                                </DefaultButton>
                            </form>
                        }
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button"
                        }}
                        onClick={this.toggleDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon"/>
                    </IconButton>
                </DialogComponent>
            </div>
        )}
}


const validate = values => {
    const errors = {};
    if (!values.response) {
        errors.response = 'Required'
    } else if (values.response.length < 3) {
        errors.response = 'Must be 3 characters or more'
    }
    return errors
};

ReviewItem = reduxForm({
    validate
})(ReviewItem);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        patchReviewDashboard
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(ReviewItem);
