import React, {Component} from 'react';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock';
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import IconButton from '@material-ui/core/IconButton';
import DeleteItemIcon from '../../assets/image/delete_item.png';
import CloseDialogIcon from '../../assets/image/Mask.png';
import CommentBasketIcon from '../../assets/image/comment_basket.png';
import CommentBasketBlueIcon from '../../assets/image/comment_basket_blue.png';


import BasketNoItemsIcon from '../../assets/image/basket_no_items.png';
import {
    removeItem,
    addQuantity,
    subtractQuantity,
    disableItem,
    disableItems,
    addComment,
    clearCart,
    getActualDate,
    setAmount,
    changeToast
} from '../../actions/cartActions'
import {postCreateOrders} from '../../actions/PurchasesActions'
import './Basket.scss';
import IncreaseIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckOff from '../../assets/image/checkbox_off.png';
import CheckOn from '../../assets/image/checkbox_on.png';
import classNames from 'classnames';
import {SuccessMessage} from "../HelperComponents/ToastMessages/ToastMessage";

class Basket extends Component {

    state = {
        open: false,
        addDisabled: false,
        reDisabled: false,
        commentId: 0,
        commentValue: "",
        loading: false,
        openDeleteAll: false,
        created_success:false,
        boolValue: false,
        disabled: false,
    };


    //to remove the item completely
    handleRemove = (id) => {
        this.props.removeItem(id);
    };
    //to add the quantity
    handleAddQuantity = async (id) => {
        await this.props.addQuantity(id);
        this.setState(({ boolValue }) => ({ boolValue: !boolValue }));
    };
    //to substruct from the quantity
    handleSubtractQuantity = async (id) => {
        await this.props.subtractQuantity(id);
        this.setState(({ boolValue }) => ({ boolValue: !boolValue }));
    };

    handleDisableItem = (id) => {
        this.props.disableItem(id);
    };
    handleDisableItems = () => {
        this.props.disableItems();
    };
    handleAddComment = () => {
        let comment = document.getElementById("basket_commends").value;
        this.props.addComment(this.state.commentId, comment);
        this.toggleDialog();

    };


    submitForm = data => {

    };

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open,
        }));
    };
    toggleDeleteAllDialog = () => {
        this.setState({openDeleteAll: !this.state.openDeleteAll});
    };
    toggleDialogComment = (id, comment) => {
        this.setState(({open}) => ({
            open: !open,
            commentId: id,
            commentValue: comment
        }));
    };



    getMyActualData = (data) => {
        const {getActualDate} = this.props;

        let arr = [];

        arr.push(`search=${data}`);

        getActualDate(arr);
    };

    handleOrdersCreate = () => {
        this.setState({ disabled: true })
        setTimeout(() => {
            this.postCreateOrders();
        }, 0);
        setTimeout(() => {
            this.setState({ disabled: false })
        }, 100);
    }

    postCreateOrders = () => {
        const {postCreateOrders} = this.props;
        let data = {};
        data = this.props.items.filter(item => item.active === true);
        data.forEach(el => {
            el.quantity = String(el.amount);
            el.producer = el.producer_original;
        });
        postCreateOrders(data)
    };

    onValueChange = (e, quantity, lot, offer_id) => {
        if (parseInt(e.target.value) >= parseInt(quantity)) {
            this.props.setAmount(offer_id, quantity);
            // this.setState({'amount': quantity});
        } else if (parseInt(quantity) >= parseInt(e.target.value) && parseInt(e.target.value) >= parseInt(lot)) {
            let new_amount = Math.round(parseFloat(e.target.value) / lot) * lot;
            this.props.setAmount(offer_id, new_amount);
        } else {
            this.props.setAmount(offer_id, e.target.value);

        }

    };
    clearCart = () => {
        this.props.clearCart();
        this.toggleDeleteAllDialog();
    };
    onBlur = (e, lot, offer_id) => {
        if (parseInt(e.target.value) < parseInt(lot) || !parseInt(e.target.value)) {
            this.props.setAmount(offer_id, lot);

        }
    };
    toggleSnack = () => {
        this.props.changeToast()
    };
    render() {
        const {open, openDeleteAll, commentValue, disabled} = this.state;
        const {handleSubmit, total, allChecked, loading, created_success, create_items, maxBalance, createDisable} = this.props;
        if(maxBalance === 0){
            return null
        }


        let addedItems = this.props.items.length ?
            (
                this.props.items.map(item => {
                    return (
                        <div className="table_row" key={item.offer_id}>
                            <div className="row_item">
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={item.active}
                                                // onChange={() => {
                                                //     this.toggleCheck(id, price_list)
                                                // }}
                                                onClick={() => {
                                                    this.handleDisableItem(item.offer_id)
                                                }}
                                                classes={{
                                                    root: 'custom_check'
                                                }}
                                                name="telegram"
                                                checkedIcon={<img src={CheckOn} alt="check on"/>}
                                                icon={<img src={CheckOff} alt="check off"/>}

                                            />}
                                        label=""
                                    />
                                </div>
                            </div>
                            <div className="row_item">
                                <div className={classNames({"row_item_disable": !item.active,})}>{item.article_number}</div>
                            </div>
                            <div className="row_item">
                                <div className={classNames({"row_item_disable": !item.active,})}>{item.producer}</div>
                            </div>
                            <div className="row_item">
                                <div className={classNames({"row_item_disable": !item.active,})}>{item.description}</div>
                            </div>
                            <div className="row_item">
                                <div className={classNames({"row_item_disable": !item.active,})}>{item.quantity} шт.
                                    {item.lot > 1 && `(по ${item.lot})`}
                                </div>
                            </div>
                            <div className="row_item weight_bold">
                                <div className={classNames({"row_item_disable": !item.active,})}>{item.price}
                                    <span className="ru_value"> ₽</span>
                                </div>
                            </div>
                            <div className="row_item">
                                <div className={classNames({"row_item_disable": !item.active,})}>{item.delivery_time} дн.</div>
                            </div>
                            <div className="row_item">
                                <div className="custom_count_field row_item">
                                    <div className="count_field">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                this.handleSubtractQuantity(item.offer_id);
                                            }}
                                        >
                                            <RemoveIcon/>
                                        </button>
                                        <input
                                            value={item.amount}
                                            type="number"
                                            min={0}
                                            max={item.quantity}
                                            step={item.lot}
                                            onChange={(e) => this.onValueChange(e, item.quantity, item.lot, item.offer_id)}
                                            onBlur={(e) => this.onBlur(e, item.lot, item.offer_id)}
                                        />
                                        <button
                                            type="button"
                                            disabled={parseInt(item.lot) >= parseInt(item.quantity)}
                                            onClick={() => {
                                                this.handleAddQuantity(item.offer_id)
                                            }}
                                        >
                                            <IncreaseIcon/>
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div className="row_item">
                                <div className="basket_table_btn">
                                    <button className="commends_item" onClick={() => {
                                        this.toggleDialogComment(item.offer_id, item.comment)
                                    }}>
                                        {item.comment ? <img src={CommentBasketBlueIcon} alt=""/> :
                                            <img src={CommentBasketIcon} alt=""/>}
                                    </button>
                                    <button className="delete_item" onClick={() => {
                                        this.handleRemove(item.offer_id)
                                    }}>
                                        <img src={DeleteItemIcon} alt=""/>
                                    </button>
                                </div>
                            </div>
                        </div>

                    )
                })
            ) :

            (
                <div className="basket_no_items">
                    <div className="box_icon">
                        <img src={BasketNoItemsIcon} alt="no item"/>
                    </div>
                    <div className="text">Здесь пока пусто</div>
                    <span>После добавления товаров в корзину Вы увидите их здесь</span>
                </div>
            )
        return (
            <div className="container basket_page">
                <div className="title-block">
                    <h1>Корзина</h1>
                    <TransitionedBlock>
                        <div className="basket">

                            <form className="" onSubmit={handleSubmit(this.submitForm)}>
                                <div className="transactions_columns table_container">
                                    {this.props.items.length ?
                                        <div className="table_header">
                                            <div className="table_row">
                                                <div className="row_item">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={allChecked}
                                                                // onChange={() => {
                                                                //     this.toggleCheck(id, price_list)
                                                                // }}
                                                                onChange={() => {
                                                                    this.handleDisableItems()
                                                                }}
                                                                classes={{
                                                                    root: 'custom_check'
                                                                }}
                                                                name="telegram"
                                                                checkedIcon={<img src={CheckOn} alt="check on"/>}
                                                                icon={<img src={CheckOff} alt="check off"/>}

                                                            />}
                                                        label=""
                                                    />
                                                </div>
                                                <div className="row_item">Артикул</div>
                                                <div className="row_item">Производитель</div>
                                                <div className="row_item">Название товара</div>
                                                <div className="row_item">Наличие</div>
                                                <div className="row_item">Цена</div>
                                                <div className="row_item">Срок поставки</div>
                                                <div className="row_item">Количество</div>
                                                <div className="row_item"></div>

                                            </div>
                                        </div>
                                        : null}
                                    <div className="table_body">
                                        {addedItems}


                                    </div>
                                </div>
                                {this.props.items.length ?
                                    <div>
                                        <div className="basket_price">
                                            <div className="available_funds">Доступные средства:  {maxBalance}<span
                                                className="ru_value"> ₽</span></div>
                                            {total > 0 ? <div className="all_price">Всего: <span>{total}<span
                                                className="ru_value"> ₽</span></span></div> : null}
                                        </div>
                                        <div className="basket_btn_wrapper">
                                            <DefaultButton
                                                variant="outlined"
                                                classes="delete_btn"
                                                // disabled={!this.props.items.filter(item => item.active === true).length}
                                                onClick={this.toggleDeleteAllDialog}

                                            >
                                                Удалить все
                                            </DefaultButton>
                                            <DefaultButton
                                                onClick={(event) => {
                                                    console.log(event);
                                                    this.handleOrdersCreate()
                                                }}
                                                loading={loading}
                                                disabled={(+total > +maxBalance) || ((+maxBalance) - (+total) === +maxBalance) || loading}
                                                variant="contained"
                                            >
                                                Подтвердить
                                            </DefaultButton>
                                        </div>
                                    </div>
                                    : null}
                                {created_success ?
                                    <SuccessMessage close={this.toggleSnack}
                                                    message={create_items > 1 ?"Заказы успешно созданы" :"Заказ успешно создан"}/> : null}
                            </form>
                        </div>
                    </TransitionedBlock>
                </div>
                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                >
                    <div className="title-dialog">
                        Комментарий к заказу
                    </div>
                    <textarea className="basket_commends" id="basket_commends" placeholder="Ваш комментарий" defaultValue={commentValue} />
                    <div className="btn-wrapper order-dialog-btn">
                        <DefaultButton
                            variant="outlined"
                            classes="cancel_btn"
                            onClick={this.toggleDialog}
                        >
                            Отмена
                        </DefaultButton>
                        <DefaultButton
                            // type="link"
                            // to="/admin/"
                            onClick={() => {
                                this.handleAddComment()
                            }}

                        >
                            Добавить
                        </DefaultButton>
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button"
                        }}
                        onClick={this.toggleDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon"/>
                    </IconButton>
                </DialogComponent>
                <DialogComponent
                    open={openDeleteAll}
                    onClose={this.toggleDeleteAllDialog}
                >
                    <div className="title-dialog">
                        Удалить все товары
                    </div>
                    Вы уверены что хотите удалить все товары из корзины?
                    <div className="btn-wrapper order-dialog-btn">
                        <DefaultButton
                            variant="outlined"
                            classes="cancel_btn"
                            onClick={this.toggleDeleteAllDialog}
                        >
                            Отмена
                        </DefaultButton>
                        <DefaultButton
                            onClick={() => {
                                this.clearCart()
                            }}

                        >
                            Удалить
                        </DefaultButton>
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button"
                        }}
                        onClick={this.toggleDeleteAllDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon"/>
                    </IconButton>
                </DialogComponent>


            </div>
        );
    }
}


Basket = reduxForm({
    form: 'BasketForm',
    // enableReinitialize: true,
})(Basket);

const mapStateToProps = (state) => {
    return {
        items: state.cart.addedItems,
        total: state.cart.total,
        allChecked: state.cart.allChecked,
        loading: state.cart.loading,
        created_success: state.cart.created_success,
        create_items: state.cart.create_items,
        maxBalance: state.cart.maxBalance,
        createDisable: state.cart.createDisable,
        //addedItems: state.addedItems
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        removeItem: (id) => {
            dispatch(removeItem(id))
        },
        addQuantity: (id) => {
            dispatch(addQuantity(id))
        },
        subtractQuantity: (id) => {
            dispatch(subtractQuantity(id))
        },
        disableItem: (id) => {
            dispatch(disableItem(id))
        },
        disableItems: () => {
            dispatch(disableItems())
        },
        addComment: (id, comment) => {
            dispatch(addComment(id, comment))
        },
        clearCart: () => {
            dispatch(clearCart())
        },
        getActualDate: (arr) => {
            dispatch(getActualDate(arr))
        },
        postCreateOrders: (data) => {
            dispatch(postCreateOrders(data))
        },
        setAmount: (id, amount) => {
            dispatch(setAmount(id, amount))
        },
        changeToast: () => {
            dispatch(changeToast())
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Basket)
