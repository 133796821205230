import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import OrderItem from "../Orders/OrderItem/OrderItem";
import ReviewItem from "../Reviews/ReviewItem/ReviewItem";
import PriceItem from "../PriceList/PriceItem/PriceItem";
import NoItems from "../HelperComponents/NoItems/NoItems";

import MailIcon from "../../assets/image/mail_icon.svg";
import PhoneIcon from "@material-ui/icons/Phone";
import AddIcon from "../../assets/image/add.png";
import EditIcon from "../../assets/image/edit.png";

import OrdersIcon from "../../assets/image/no_orders.png";
import PricesIcon from "../../assets/image/no_prices.png";
import ReviewsIcon from "../../assets/image/no_reviews.png";
import Arrow from "../../assets/image/arrow_small.png";

import {
  getPriceLists,
  getPriceListAddresses,
} from "../../actions/priceListActions";
import { getOrders } from "../../actions/ordersActions";
import { getDashboard } from "../../actions/userActions";

import "./Dashboard.scss";
import Loader from "../HelperComponents/Loader/Loader";
import SearchProducts from "../SearchProducts/SearchProducts";

// import {animateScroll as scroll} from "react-scroll/modules";

class Dashboard extends Component {
  state = {
    loading: true,
    product: null,
  };

  componentDidMount() {
    const { getDashboard, history } = this.props;
    getDashboard().then((res) => {
      if (res.payload && res.payload.status && res.payload.status === 200) {
        this.setState({ loading: false });
      }
      if (res.error && res.error.response.status === 401) {
        localStorage.clear();
        history.push("/auth");
      }
    });
  }

  setProduct = (product) => {
    this.setState({ product });
  };

  render() {
    const {
      orders: { all_orders },
      user: { user_info, dashboard },
      history,
    } = this.props;
    const { loading } = this.state;

    return (
      <main>
        <section className="info-head container">
          <div className="info-head-wrapper">
            <div className="info-head_content">
              <h3 className="info-head_title">
                {user_info.company ? (
                  <Fragment>«{user_info.company}»</Fragment>
                ) : (
                  " "
                )}
              </h3>
              <div className="info-head_contacts">
                <p>
                  <img src={MailIcon} alt="mail icon" />
                  {user_info.email ? (
                    <Fragment>{user_info.email}</Fragment>
                  ) : (
                    " "
                  )}
                </p>
                <p>
                  <PhoneIcon />
                  {user_info.phones && user_info.phones.length > 0 ? (
                    <Fragment>
                      {user_info.phones[0].phone_number.substring(0, 1) +
                        "-" +
                        user_info.phones[0].phone_number.substring(1, 4) +
                        "-" +
                        user_info.phones[0].phone_number.substring(4)}
                    </Fragment>
                  ) : (
                    " "
                  )}
                </p>
              </div>
            </div>
            <div className="info-header">
              <Button
                component={Link}
                to="/admin/info"
                disableRipple={true}
                classes={{
                  root: "edit_btn",
                }}
              >
                Редактировать
                <img src={EditIcon} alt="edit icon" />
              </Button>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="search_wrapper_dashboard">
            <SearchProducts setProduct={this.setProduct} />
          </div>
          <div>
            <div className="dashboard-section_head flex-center-btw">
              <h4>Заказы</h4>
              <Button
                component={Link}
                to="/admin/orders/add"
                disableRipple={true}
                classes={{
                  root: "add_btn",
                }}
              >
                Добавить
                <img src={AddIcon} alt="edit icon" />
              </Button>
            </div>
            {!loading ? (
              dashboard[0].orders.length === 0 ? (
                <NoItems>
                  <div className="box-img">
                    <img src={OrdersIcon} alt="edit icon" />
                  </div>
                  <div className="title"> Пока нет ни одного заказа</div>
                  <div className="descriptions">
                    После оформления первого заказа Вы увидите его здесь
                  </div>
                </NoItems>
              ) : (
                <div className="price-list">
                  <div className="dashboard-section_content">
                    {dashboard[0].orders.length > 4
                      ? all_orders.results
                          .slice(1, 5)
                          .map((data) => (
                            <OrderItem
                              key={data.id}
                              data={data}
                              history={history}
                            />
                          ))
                      : dashboard[0].orders.map((data) => (
                        data.status !== 'imported' && (
                          <OrderItem
                            key={data.id}
                            data={data}
                            history={history}
                          />
                        )
                      ))}
                  </div>
                  <div className="section_link_wrapper">
                    <Link className="section_link" to="/admin/orders">
                      Смотреть все
                      <img src={Arrow} alt="Arrow" />
                    </Link>
                  </div>
                </div>
              )
            ) : (
              <div className="loader_wrapper_center loader_wrapper_center_dashboard">
                <Loader />
              </div>
            )}
          </div>
          <div>
            <div className="dashboard-section_head flex-center-btw ">
              <h4>Прайс-листы</h4>
              <Button
                component={Link}
                to="/admin/price-list/add"
                disableRipple={true}
                classes={{
                  root: "add_btn",
                }}
              >
                Добавить
                <img src={AddIcon} alt="edit icon" />
              </Button>
            </div>

            {!loading ? (
              dashboard[0].price_list.length === 0 ? (
                <NoItems>
                  <div className="box-img">
                    <img src={PricesIcon} alt="edit icon" />
                  </div>
                  <div className="title">Пока нет ни одного прайс-листа</div>
                  <div className="descriptions">
                    После добавления первого прайс-листа Вы увидите его здесь
                  </div>
                </NoItems>
              ) : (
                <div className="price-list">
                  <div className="dashboard-section_content">
                    {dashboard[0].price_list.length > 4
                      ? dashboard[0].price_list
                          .slice(1, 5)
                          .map((data) => (
                            <PriceItem
                              key={data.id}
                              {...data}
                              agency_name={data.agency}
                              history={history}
                            />
                          ))
                      : dashboard[0].price_list.map((data) => (
                          <PriceItem
                            key={data.id}
                            {...data}
                            agency_name={data.agency}
                            history={history}
                          />
                        ))}
                  </div>
                  <div className="section_link_wrapper">
                    <Link className="section_link" to="/admin/price-list">
                      Смотреть все
                      <img src={Arrow} alt="Arrow" />
                    </Link>
                  </div>
                </div>
              )
            ) : (
              <div className="loader_wrapper_center loader_wrapper_center_dashboard">
                <Loader />
              </div>
            )}
          </div>
          <div>
            <div className="dashboard-section_head flex-center-btw">
              <h4>Отзывы</h4>
            </div>
            {!loading ? (
              dashboard[0].reviews.length === 0 ? (
                <NoItems>
                  <div className="box-img">
                    <img src={ReviewsIcon} alt="edit icon" />
                  </div>
                  <div className="title">Пока нет ни одного отзыва</div>
                  <div className="descriptions">
                    После получения первого отзыва Вы увидите его здесь
                  </div>
                </NoItems>
              ) : (
                <div className="price-list">
                  <div className="dashboard-section_content">
                    {dashboard[0].reviews.length > 4
                      ? dashboard[0].reviews
                          .slice(1, 5)
                          .map((review) => (
                            <ReviewItem
                              key={review.id}
                              {...review}
                              form={`Review${review.id}Form`}
                            />
                          ))
                      : dashboard[0].reviews.map((review) => (
                          <ReviewItem
                            key={review.id}
                            {...review}
                            form={`Review${review.id}Form`}
                          />
                        ))}
                  </div>
                  <div className="section_link_wrapper">
                    <Link className="section_link" to="/admin/reviews">
                      Смотреть все
                      <img src={Arrow} alt="Arrow" />
                    </Link>
                  </div>
                </div>
              )
            ) : (
              <div className="loader_wrapper_center loader_wrapper_center_dashboard">
                <Loader />
              </div>
            )}
          </div>
        </section>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    price_list: state.price_list,
    orders: state.orders,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPriceLists,
      getPriceListAddresses,
      getDashboard,
      getOrders,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
