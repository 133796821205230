import React, {Fragment} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthHead from '../../components/Auth/AuthHead/AuthHead';
import Login from "../../components/Auth/Login/Login";
import Register from "../../components/Auth/Register/Register";
import Activation from "../../components/Auth/Activation/Activation";
import FirstStep from "../../components/Auth/PasswordRecovery/FirstStep/FirstStep";
import SecondStep from "../../components/Auth/PasswordRecovery/SecondStep/SecondStep";

import Logo from '../../assets/image/logo.png';

import './AuthContainer.scss';

const AuthContainer = (props) => {
    const { location, match } = props;
    if(!!localStorage.token) return <Redirect to="/admin" />;
    return (
        <Fragment>
            <AuthHead location={location}/>
            <main className="auth_container">
                <div className="auth-box">
                    <div className="auth_bg">
                        <div className="auth_logo">
                            <img src={Logo} alt="logo"/>
                        </div>
                        <p>
                            Предложите свои товары в объединенной розничной <br/>
                            сети по всей стране, и мы поможем вашему покупателю найти Вас
                        </p>
                    </div>
                    <div className="auth_content">
                        <Switch>
                            <Route path={match.url} exact component={Login} />
                            <Route path={`${match.url}/register`} exact component={Register} />
                            <Route path={`${match.url}/activation`} exact component={Activation} />
                            <Route path={`${match.url}/password-recovery`} exact component={FirstStep} />
                            <Route path={`${match.url}/password-recovery/approve`} exact component={SecondStep} />
                            <Route render={()=>(<p>Not found</p>)} />
                        </Switch>
                    </div>
                </div>
            </main>
        </Fragment>
    );
};

export default AuthContainer;