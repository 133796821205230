import React from 'react';
import { Link } from 'react-router-dom';

import BackIcon from '../../assets/image/back.png';

const BackLink = ({to = '/admin'}) => {
    return (
        <Link
            className="app-back_link"
            to={to}
        >
            <img src={BackIcon} alt="back icon"/>
        </Link>
    );
};

export default BackLink;