import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import BackLink from '../BackLink/BackLink';
import Preloader from '../HelperComponents/Preloader/Preloader';
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock';
import ReviewPageItem from './ReviewPageItem/ReviewPageItem';
import NoItems from '../HelperComponents/NoItems/NoItems';
import Pagination from '../HelperComponents/Pagination/Pagination';
import { animateScroll as scroll } from 'react-scroll'

import { isArray } from "../../helpers/functions";
import { getReviews } from "../../actions/reviewsActions";
import { getNotifications } from "../../actions/userActions";

import NoReviewsItem from '../../assets/image/no_reviews.png';

import './Reviews.scss';


class Reviews extends Component {
    state = {
        activePage: 1
    };

    componentDidMount() {
        const { activePage } = this.state;
        this.doRequest(activePage);
    }

    componentWillUnmount() {
        this.props.reviews.reviews_list = {};
    }

    doRequest = (page) => {
        const { getReviews, history } = this.props;
        getReviews(page).then((res) => {
            scroll.scrollToTop({
                duration: 1000,
                delay: 100
            });
            if(res.payload && res.payload.status && res.payload.status === 200) {
                this.props.getNotifications();
            }
            if(res.error && res.error.response.status === 401) {
                localStorage.clear();
                history.push('/auth');
            }
        });
    };

    changePage = (activePage) => {
        this.setState({activePage});
        this.doRequest(activePage);
    };

    render(){
        const { reviews:{reviews_list} } = this.props;
        const { activePage } = this.state;
        return (
            <div className="container ">
                <div className="title-block">
                    <BackLink/>
                    <h1>Отзывы</h1>
                </div>
                {!reviews_list || !reviews_list.loaded ?
                    <Preloader/>
                    :
                    <TransitionedBlock>
                        <div className="content-block content-block-reviews">
                            {
                                isArray(reviews_list.results) ?
                                    reviews_list.results.map((review) => (
                                        <ReviewPageItem
                                            {...review}
                                            key={review.id}
                                            form={`Review${review.id}Form`}
                                        />
                                    ))
                                    :
                                    <NoItems>
                                        <div className="box-img">
                                            <img src={NoReviewsItem} alt="edit icon"/>
                                        </div>
                                        <div className="title"> Пока нет ни одного отзыва</div>
                                        <div className="descriptions">После получения первого отзыва Вы увидите его здесь</div>
                                    </NoItems>
                            }
                        </div>
                        {reviews_list.count && reviews_list.count > 10 ?
                            <Pagination
                                current={activePage}
                                pageCount={10}
                                total={reviews_list.count}
                                onChange={this.changePage}
                            />
                            :
                            null
                        }
                    </TransitionedBlock>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
        reviews: state.reviews
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getReviews,
        getNotifications
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);