import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import TooltipMessage from '../../HelperComponents/TooltipMessage/TooltipMessage';

import ErrorIcon from '@material-ui/icons/Error';


class RenderAutocomplete extends Component {
    constructor(props) {
        super(props);
        window.ymaps.ready(() => {
            let suggestView = new window.ymaps.SuggestView(props.id, {
                results: 5,
                offset: [0, 5],
                width: 300,
                container: document.getElementById('suggest_wrapper')
            });
            suggestView.events.add('select', (e) => props.onSelect(e, props.input.name));
        });
    }

    render() {
        const { input, placeholder, type, id, autoFocus, label, disabled, meta: {touched, error} } = this.props;
        return (
            <div className="custom_input_autocomplete">
                <div id="suggest_wrapper"></div>
                <TextField
                    id={id}
                    {...input}
                    type={type}
                    label={label}
                    variant="outlined"
                    disabled={disabled}
                    error={touched && !!error}
                    placeholder={placeholder}
                    autoComplete="off"
                    autoFocus={autoFocus}
                    classes={{
                        root: 'custom_input_wrapper'
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {touched && !!error ?
                                    <TooltipMessage
                                        text={error}
                                        delay={200}
                                        error
                                    >
                                        <ErrorIcon/>
                                    </TooltipMessage>
                                    : ''
                                }
                            </InputAdornment>
                        ),
                        classes: {
                            root: 'custom_input',
                            focused: 'custom_input_focused',
                            error: 'custom_input_error',
                            adornedEnd: 'custom_input_adorned_end',
                            notchedOutline: 'custom_input_outline'
                        }
                    }}
                    InputLabelProps={{
                        classes: {
                            root: 'custom_input_label',
                            focused: 'custom_input_label_focused',
                            shrink: 'custom_input_label_active',
                            error: 'custom_input_label_error'
                        }
                    }}
                />
            </div>
        );
    }
}

export default (RenderAutocomplete);