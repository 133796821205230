//auth actions

export const REGISTER = 'REGISTER';

export const LOGIN = 'LOGIN';

export const ACTIVATION = 'ACTIVATION';

export const RESET_PASSWORD_FIRST = 'RESET_PASSWORD_FIRST';

export const RESET_PASSWORD_SECOND = 'RESET_PASSWORD_SECOND';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

//user actions

export const USER = 'USER';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAIL = 'USER_FAIL';

export const NOTIFICATION           = 'NOTIFICATION';
export const NOTIFICATION_SUCCESS   = 'NOTIFICATION_SUCCESS';
export const NOTIFICATION_FAIL      = 'NOTIFICATION_FAIL';

export const DASHBOARD           = 'DASHBOARD';
export const DASHBOARD_SUCCESS   = 'DASHBOARD_SUCCESS';
export const DASHBOARD_FAIL      = 'DASHBOARD_FAIL';

export const INFO = 'INFO';
export const INFO_SUCCESS = 'INFO_SUCCESS';
export const INFO_FAIL = 'INFO_FAIL';

export const CHANGE_INFO = 'CHANGE_INFO';
export const CHANGE_INFO_SUCCESS = 'CHANGE_INFO_SUCCESS';
export const CHANGE_INFO_FAIL = 'CHANGE_INFO_FAIL';

export const INFO_CONTACTS = 'INFO_CONTACTS';
export const INFO_CONTACTS_SUCCESS = 'INFO_CONTACTS_SUCCESS';
export const INFO_CONTACTS_FAIL = 'INFO_CONTACTS_FAIL';

export const NEW_CONTACT = 'NEW_CONTACT';
export const NEW_CONTACT_SUCCESS = 'NEW_CONTACT_SUCCESS';
export const NEW_CONTACT_FAIL = 'NEW_CONTACT_FAIL';

export const CHANGE_CONTACT = 'CHANGE_CONTACT';
export const CHANGE_CONTACT_SUCCESS = 'CHANGE_CONTACT_SUCCESS';
export const CHANGE_CONTACT_FAIL = 'CHANGE_CONTACT_FAIL';

export const DELETE_CONTACT = 'DELETE_CONTACT';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAIL = 'DELETE_CONTACT_FAIL';

export const INFO_ADDRESSES = 'INFO_ADDRESSES';
export const INFO_ADDRESSES_SUCCESS = 'INFO_ADDRESSES_SUCCESS';
export const INFO_ADDRESSES_FAIL = 'INFO_ADDRESSES_FAIL';

export const NEW_ADDRESS = 'NEW_ADDRESS';
export const NEW_ADDRESS_SUCCESS = 'NEW_ADDRESS_SUCCESS';
export const NEW_ADDRESS_FAIL = 'NEW_ADDRESS_FAIL';

export const CHANGE_ADDRESS = 'CHANGE_ADDRESS';
export const CHANGE_ADDRESS_SUCCESS = 'CHANGE_ADDRESS_SUCCESS';
export const CHANGE_ADDRESS_FAIL = 'CHANGE_ADDRESS_FAIL';

export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAIL = 'DELETE_ADDRESS_FAIL';

//reviews actions

export const REVIEWS = 'REVIEWS';
export const REVIEWS_SUCCESS = 'REVIEWS_SUCCESS';
export const REVIEWS_FAIL = 'REVIEWS_FAIL';

export const REVIEWS_RESPONSE = 'REVIEWS_RESPONSE';
export const REVIEWS_RESPONSE_SUCCESS = 'REVIEWS_RESPONSE_SUCCESS';
export const REVIEWS_RESPONSE_FAIL = 'REVIEWS_RESPONSE_FAIL';

export const PATCH_REVIEWS_DASHBOARD            = 'PATCH_REVIEWS_DASHBOARD';
export const PATCH_REVIEWS_DASHBOARD_SUCCESS    = 'PATCH_REVIEWS_DASHBOARD_SUCCESS';
export const PATCH_REVIEWS_DASHBOARD_FAIL       = 'PATCH_REVIEWS_DASHBOARD_FAIL';

//price list actions

export const PRICE_LIST_ADDRESSES = 'PRICE_LIST_ADDRESSES';
export const PRICE_LIST_ADDRESSES_SUCCESS = 'PRICE_LIST_ADDRESSES_SUCCESS';
export const PRICE_LIST_ADDRESSES_FAIL = 'PRICE_LIST_ADDRESSES_FAIL';

export const SEND_FILE = 'SEND_FILE';
export const SEND_FILE_SUCCESS = 'SEND_FILE_SUCCESS';
export const SEND_FILE_FAIL = 'SEND_FILE_FAIL';

export const SET_PRICE_LIST_INFO = 'SET_PRICE_LIST_INFO';

export const CREATE_PRICE_LIST = 'CREATE_PRICE_LIST';
export const CREATE_PRICE_LIST_SUCCESS = 'CREATE_PRICE_LIST_SUCCESS';
export const CREATE_PRICE_LIST_FAIL = 'CREATE_PRICE_LIST_FAIL';

export const PRICE_LISTS            = 'PRICE_LISTS';
export const PRICE_LISTS_SUCCESS    = 'PRICE_LISTS_SUCCESS';
export const PRICE_LISTS_FAIL       = 'PRICE_LISTS_FAIL';

export const PATCH_PRICE_LIST            = 'PATCH_PRICE_LIST';
export const PATCH_PRICE_LIST_SUCCESS    = 'PATCH_PRICE_LIST_SUCCESS';
export const PATCH_PRICE_LIST_FAIL       = 'PATCH_PRICE_LIST_FAIL';

export const DELETE_PRICE_LIST            = 'DELETE_PRICE_LIST';
export const DELETE_PRICE_LIST_SUCCESS    = 'DELETE_PRICE_LIST_SUCCESS';
export const DELETE_PRICE_LIST_FAIL       = 'DELETE_PRICE_LIST_FAIL';

export const POST_VALIDATE_OPTIONAL_FIELDS            = 'POST_VALIDATE_OPTIONAL_FIELDS';
export const POST_VALIDATE_OPTIONAL_FIELDS_FAIL       = 'POST_VALIDATE_OPTIONAL_FIELDS_FAIL';

//orders actions

export const GET_ORDERS         = 'GET_ORDERS';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAIL    = 'GET_ORDERS_FAIL';

export const NEW_ORDER = 'NEW_ORDER';
export const NEW_ORDER_SUCCESS = 'NEW_ORDER_SUCCESS';
export const NEW_ORDER_FAIL = 'NEW_ORDER_FAIL';

export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';

export const PATCH_ORDER = 'PATCH_ORDER';
export const PATCH_ORDER_SUCCESS = 'PATCH_ORDER_SUCCESS';
export const PATCH_ORDER_FAIL = 'PATCH_ORDER_FAIL';

export const POST_ORDER_COMMENT             = 'POST_ORDER_COMMENT';
export const POST_ORDER_COMMENT_SUCCESS     = 'POST_ORDER_COMMENT_SUCCESS';
export const POST_ORDER_COMMENT_FAIL        = 'POST_ORDER_COMMENT_FAIL';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';

export const DELETE_ORDER = 'DELETE_ORDER';

// purchases
export const GET_PURCHASES = 'GET_PURCHASES';
export const GET_PURCHASES_SUCCESS = 'GET_PURCHASES_SUCCESS';
export const GET_PURCHASES_FAIL = 'GET_PURCHASES_FAIL';

export const GET_PURCHASES_DETAIL = 'GET_PURCHASES_DETAIL';
export const GET_PURCHASES_DETAIL_SUCCESS = 'GET_PURCHASES_DETAIL_SUCCESS';
export const GET_PURCHASES_DETAIL_FAIL = 'GET_PURCHASES_DETAIL_FAIL';


// myPurchases
export const GET_MY_PURCHASES = 'GET_MY_PURCHASES';
export const GET_MY_PURCHASES_SUCCESS = 'GET_MY_PURCHASES_SUCCESS';
export const GET_MY_PURCHASES_FAIL = 'GET_MY_PURCHASES_FAIL';

export const GET_MY_ACTUAL_DATA = 'GET_MY_ACTUAL_DATA';
export const GET_MY_ACTUAL_DATA_SUCCESS = 'GET_MY_ACTUAL_DATA_SUCCESS';
export const GET_MY_ACTUAL_DATA_FAIL = 'GET_MY_ACTUAL_DATA_FAIL';

// myRequests
export const GET_MY_REQUESTS = 'GET_MY_REQUESTS';
export const GET_MY_REQUESTS_SUCCESS = 'GET_MY_REQUESTS_SUCCESS';
export const GET_MY_REQUESTS_FAIL = 'GET_MY_REQUESTS_FAIL';

// myTransactions
export const GET_MY_TRANSACTIONS = 'GET_MY_TRANSACTIONS';
export const GET_MY_TRANSACTIONS_SUCCESS = 'GET_MY_TRANSACTIONS_SUCCESS';
export const GET_MY_TRANSACTIONS_FAIL = 'GET_MY_TRANSACTIONS_FAIL';

// Request create

export const NEW_REQUEST = 'NEW_REQUEST';
export const NEW_REQUEST_SUCCESS = 'NEW_REQUEST_SUCCESS';
export const NEW_REQUEST_FAIL= 'NEW_REQUEST_FAIL';
// card acctions
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_REQUEST_SUCCESS = 'ADD_TO_CART';

export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SUB_QUANTITY = 'SUB_QUANTITY';

export const ADD_QUANTITY = 'ADD_QUANTITY';
export const ADD_SHIPPING = 'ADD_SHIPPING';

export const DISABLED_ITEM = 'DISABLED_ITEM';
export const DISABLED_ITEMS = 'DISABLED_ITEMS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const CLEAR_CART = 'CLEAR_CART';
export const SET_AMOUNT = 'SET_AMOUNT';
export const CHANGE_CART_TOAST = 'CHANGE_CART_TOAST';

export const JAPARTS_ORDERS_CREATE = 'JAPARTS_ORDERS_CREATE';
export const JAPARTS_ORDERS_CREATE_SUCCESS = 'JAPARTS_ORDERS_CREATE_SUCCESS';
export const JAPARTS_ORDERS_CREATE_FAIL = 'JAPARTS_ORDERS_CREATE_FAIL';

export const JAPARTS_GET_ORDER = 'JAPARTS_GET_ORDER';

export const JAPARTS_REQUEST_CREATE = 'JAPARTS_REQUEST_CREATE';
export const JAPARTS_REQUEST_CREATE_SUCCESS = 'JAPARTS_REQUEST_CREATE_SUCCESS';
export const JAPARTS_REQUEST_CREATE_FAIL = 'JAPARTS_REQUEST_CREATE_FAIL';

export const BALANACE_INFO = 'BALANACE_INFO';
export const BALANACE_INFO_SUCCESS = 'BALANACE_INFO_SUCCESS';
export const BALANACE_INFO_FAIL = 'BALANACE_INFO_FAIL';

export const GET_PRODUCTS_RECOMMENDATIONS = 'GET_PRODUCTS_RECOMMENDATIONS';
export const GET_PRODUCTS_RECOMMENDATIONS_SUCCESS = 'GET_PRODUCTS_RECOMMENDATIONS_SUCCESS';

export const CHANGE_PRODUCTS_RECOMMENDATIONS_AMOUNT = 'CHANGE_PRODUCTS_RECOMMENDATIONS_AMOUNT';

export const CLEAR_DATA_ITEM  = 'CLEAR_DATA_ITEM';

// refund
export const GET_REFUND_REQUEST = 'GET_REFUND_REQUEST';
export const POST_DIALOG_MESSAGE = "POST_DIALOG_MESSAGE";
export const GET_REFUND_REQUEST_SUCCESS = 'GET_REFUND_REQUEST_SUCCESS';