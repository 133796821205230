import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import './RenderCount.scss';

const decrease = (change, input) => {
    let newValue = Number(input.value) - 1;
    if(newValue >= 0) {
        change(input.name, newValue);
    }
};

const increase = (change, input) => {
    let newValue = Number(input.value) + 1;
    change(input.name, newValue);
};

const RenderCount = ({ input, placeholder, label, disabled, change, meta: {touched, error} }) => {
    return (
        <div className="custom_count_field">
            {/*<span className="label">{label}</span>*/}
            <div className="count_field">
                <button
                    type="button"
                    onClick={() => decrease(change, input)}
                >
                    <RemoveIcon/>
                </button>
                <input
                    {...input}
                    type="number"
                    min={0}
                    max={100}
                    disabled={disabled}
                    placeholder={placeholder}
                />
                <button
                    type="button"
                    onClick={() => increase(change, input)}
                >
                    <AddIcon/>
                </button>
            </div>

        </div>
    );
};

export default RenderCount;