import { PRICE_LIST } from "./ListActionTypes";

export function getPriceList(id) {
  return {
    type: PRICE_LIST.GET_PRICE_LIST,
    payload: {
      client: "default",
      request: {
        url: `/price_list/${id}/offers/`,
        method: "GET",
      },
    },
  };
}

export function getProducerList() {
  return {
    type: PRICE_LIST.GET_PRODUCER_LIST,
    payload: {
      client: "default",
      request: {
        url: `/producer/list/`,
        method: "GET",
      },
    },
  };
}

export function getPriceListInfo(id) {
  return {
    type: PRICE_LIST.GET_PRICE_LIST_INFO,
    payload: {
      client: "default",
      request: {
        url: `/price_list/${id}/`,
        method: "GET",
      },
    },
  };
}

export function addPriceList(id, data) {
  return {
    type: PRICE_LIST.ADD_PRICE_LIST,
    payload: {
      client: "default",
      request: {
        url: `/price_list/${id}/offers/update/`,
        method: "POST",
        data,
      },
    },
  };
}

export function deletePriceList(id, data) {
  return {
    type: PRICE_LIST.DELETE_PRICE_LIST,
    payload: {
      client: "default",
      request: {
        url: `/price_list/${id}/offers/update/`,
        method: "DELETE",
        data,
      },
    },
  };
}

export function updatePriceList(id, data) {
  return {
    type: PRICE_LIST.UPDATE_PRICE_LIST,
    payload: {
      client: "default",
      request: {
        url: `/price_list/${id}/offers/update/`,
        method: "PUT",
        data,
      },
    },
  };
}

export function getLinkPriceList(id) {
  return {
    type: PRICE_LIST.GET_LINK_PRICE_LIST,
    payload: {
      client: "default",
      request: {
        url: `/price_list/${id}/offers/link/`,
        method: "GET",
      },
    },
  };
}
export function getExcelPriceList(url) {
  return {
    type: PRICE_LIST.DOWNLOAD_PRICE_LIST,
    payload: {
      client: "default",
      request: {
        url: `${url}`,
        method: "GET",
      },
    },
  };
}

export function changeValueOffers(rowIndex, colName, value, producerId) {
  return {
    type: PRICE_LIST.CHANGE_VALUE_OFFERS,
    payload: {
      data: { rowIndex, colName, value, producerId },
    },
  };
}

export function changeValueAdded(
  AddedRowIndex,
  AddedColName,
  valueAdded,
  AddedproducerId
) {
  return {
    type: PRICE_LIST.CHANGE_VALUE_ADDED,
    payload: {
      data: { AddedRowIndex, AddedColName, valueAdded, AddedproducerId },
    },
  };
}

export function deleteOffers(rowIndex) {
  return {
    type: PRICE_LIST.DELETE_OFFERS,
    payload: {
      data: rowIndex,
    },
  };
}

export function deleteAdded(rowIndex) {
  return {
    type: PRICE_LIST.DELETE_ADDED,
    payload: {
      data: rowIndex,
    },
  };
}

export function addOffers(list) {
  return {
    type: PRICE_LIST.ADD_OFFERS,
    payload: {
      data: list,
    },
  };
}

export function doCustomStatisticLoad(isLoad) {
  return {
    type: PRICE_LIST.DO_CUSTOM_STATISTIC_LOAD,
    payload: {
      data: isLoad,
    },
  };
}

export function searchOffers(value) {
  return {
    type: PRICE_LIST.SEARCH_OFFERS,
    payload: {
      data: value,
    },
  };
}

export function resetState(isLoad) {
  return { type: PRICE_LIST.RESET_STATE, payload: { data: isLoad } };
}

export function clearList() {
  return { type: PRICE_LIST.CLEAR_LIST };
}
