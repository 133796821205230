import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const configureSentry = ({ dsn, dev, prod }) => {
    const host = window.location.host;
    let environment;
    if (host.includes(dev)) environment = "development";
    if (host.includes(prod)) environment = "production";

    if (!host.includes("localhost")) {
        return Sentry.init({
            dsn,
            autoSessionTracking: true,
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 1.0,
            environment
        });
    }
};


export function getRating(rating) {
    switch (rating) {
        case 1:
            return "Очень плохо";
        case 2:
            return "Плохо";
        case 3:
            return "Неплохо";
        case 4:
            return "Хорошо";
        case 5:
            return "Отлично";
        default:
            return null
    }
}

export function isArray(arr) {
    return arr && arr.length && arr.length > 0;
}

export function getStatus(status) {
    switch (status) {
        case 'new':
            return "Новый";
        case 'processing':
            return "Готов к выдаче";
        case 'complete':
            return "Выполнен";
        case 'denied':
            return "Отказ";
        case 'waiting_for_documents':
            return 'Ожидаем документы'
        case 'waiting_for_details':
            return 'Ожидаем деталь'
        default:
            return null
    }
}

export function getStatusColor(status) {
    switch (status) {
        case 'new':
        case 'waiting_for_documents':
            return "#0253B3";
        case 'processing':
        case 'waiting_for_details':
            return "#249AF3";
        case 'complete':
            return "#16D761";
        case 'denied':
            return "#D0021B";
        default:
            return null
    }
}

export function getPurchasesStatus(status) {
    switch (status) {
        case 'processing':
            return "В работе";
        case 'no_payment':
            return 'Нет оплаты';            
        case 'complete':
            return "Выполнен";
        case 'on_way':
            return "В пути";
        case 'confirmed':
            return 'Потверждено';
        case 'denied':
            return "Отказ";
        case 'waiting_for_documents':
            return 'Ожидаем документы'
        case 'waiting_for_details':
            return 'Ожидаем деталь'
        default:
            return null
    }
}

export function getPurchasesStatusColor(status) {
    switch (status) {
        case 'processing':
        case 'no_payment':
        case 'waiting_for_documents':
        case 'waiting_for_details':
            return "blue";
        case 'complete':
            return "green";
        case 'on_way':
            return "yellow";
        case 'confirmed':
            return 'blue';
        case 'denied':
            return "red";
        default:
            return null
    }
}

export function getMyRequestsStatus(status) {
    switch (status) {
        case 'processing':
            return "На рассмотрении";
        case 'complete':
            return "Одобрена";
        case 'denied':
            return "Отказ";
        case 'waiting_for_documents':
            return 'Ожидаем документы'
        case 'waiting_for_details':
            return 'Ожидаем деталь'
        default:
            return null
    }
}

export function getMyRequestsStatusColor(status) {
    switch (status) {
        case 'processing':
        case 'waiting_for_documents':
        case 'waiting_for_details':
            return "blue";
        case 'complete':
            return "green";
        case 'denied':
            return "red";
        default:
            return null
    }
}

export function getSupplierFromType(type) {
    switch (type) {
        case 1:
            return "japarts";
        case 2:
            return "abcp";
        case 3:
            return "Rossko";
        case 4:
            return "Uniqom";
        case 5:
            return "Stparts";
        case 6:
            return "Froza";
        case 7:
            return "Autoeuro";
        case 8:
            return "Absel";
        case 9:
            return "Motexc";
        case 10:
            return "AutoSputnik";
        case 11:
            return "Adeo";
        case 12:
            return "IxoraAuto";
        case 13:
            return "ForumAuto";
        case 14:
            return "Favorit";
        case 15:
            return "Armtek";
        case 16:
            return "Mikado";
        case 17:
            return "Shate-M";
        default:
            return null
    }
}

export const days = [
    {name: 'monday', label: 'Пн'},
    {name: 'tuesday', label: 'Вт'},
    {name: 'wednesday', label: 'Ср'},
    {name: 'thursday', label: 'Чт'},
    {name: 'friday', label: 'Пт'},
    {name: 'saturday', label: 'Сб'},
    {name: 'sunday', label: 'Вс'}
];

export function hasExtension(fileName, exts) {
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
}

export function splitByCommas(data) {
    return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatDate = (data) => {
    const date = new Date(data).toLocaleDateString('ru')
    const hours = new Date(data).toLocaleTimeString('ru')
    return `${date} ${hours}`
  }
  