import * as types from "../actions/constants";

const INITIAL_STATE = {
    all_orders: {},
    order: {},
    error: []
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.GET_ORDERS_SUCCESS :
            return {...state, all_orders : action.payload.data};
        case types.GET_ORDER_SUCCESS :
            return {...state, order : action.payload.data};
        case types.POST_ORDER_COMMENT_FAIL :
            return {...state, error : action.error.response.data};
        case types.GET_ORDERS_FAIL :
            return {...state, error : action.error.response.data};
        case types.GET_ORDER_FAIL :
            return {...state, error : action.error.response.data};
        default:
            return state;
    }
}