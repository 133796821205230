import React, {Component} from 'react';
import TransitionedBlock from '../../HelperComponents/TransitionedBlock/TransitionedBlock';
import Pagination from '../../HelperComponents/Pagination/Pagination';
import {getMyTransactions} from "../../../actions/PurchasesActions";
import NoItems from '../../../assets/image/no_orders_3.png';
import './TransactionHistory.scss';
import {connect} from "react-redux";
import moment from "moment";
import classNames from 'classnames';
import { splitByCommas } from '../../../helpers/functions';

class TransactionHistory extends Component {


    state = {
        activePage: 1,
        hasResults: false,
        items: [],
        items_count: 0,

    };

    changePage = (pageNumber) => {
        this.setState({activePage: pageNumber});
        this.getTransactions(pageNumber);
    };

    componentDidMount() {
        let page = this.state.activePage;

        setTimeout(() => {
            this.getTransactions(page);
        }, 0);
    }

    getTransactions = (page) => {
        let arr = [];
        const {getMyTransactions} = this.props;

        arr.push(`page=${page}`);
        arr.push(`page_size=10`);
        getMyTransactions(arr).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                if (res.payload.data.results.transactions.length === 0) {
                    this.setState({hasResults: false});
                } else {
                    this.setState({
                        hasResults: true,
                        items: res.payload.data.results.transactions,
                        items_count: res.payload.data.count
                    });
                }
            }

        });
    };

    render() {
        const {activePage, hasResults, items, items_count} = this.state;
        return (
            <TransitionedBlock>
                <div className="transaction_history">

                    {hasResults ?
                        <div className="transaction_history_table">
                            <div className="transactions_columns table_container">
                                <div className="table_header">
                                    <div className="table_row">
                                        <div className="row_item">Дата/время</div>
                                        <div className="row_item">Сумма</div>
                                        <div className="row_item">Тип транзакции</div>
                                        <div className="row_item">Баланс</div>
                                    </div>
                                </div>
                                <div className="table_body">
                                    {items.map((el, index) => {
                                        return (
                                            <div className="table_row" key={el.id}>
                                                <div className="row_item">
                                                    <div>{moment(el.date).format('DD.MM.YYYY HH:mm')}</div>
                                                </div>
                                                <div className="row_item">
                                                    <div className={classNames({
                                                        ["green_text"]: el.amount >= 0,
                                                        ["red_text"]: el.amount < 0
                                                    })}>{splitByCommas(el.amount)}<span className="ru_value"> ₽</span>

                                                    </div>
                                                </div>
                                                <div className="row_item">
                                                    <div>{el.type_transaction}</div>
                                                </div>
                                                <div className="row_item">
                                                    <div>{splitByCommas(el.balance)} <span className="ru_value"> ₽</span></div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                                {
                                    items_count > 10 ?
                                        <div>
                                            <Pagination
                                                current={activePage}
                                                pageCount={10}
                                                total={items_count}
                                                onChange={this.changePage}
                                            />
                                        </div> :
                                        null
                                }

                            </div>
                        </div>
                        :
                        <div className="no_item_orders">
                            <div className="box_img">
                                <img src={NoItems} alt="Введите артикул товара"/>
                            </div>
                            <div className="title_no_item">Пока нет ни одной транзакции</div>
                            <div className="descriptions_no_item">После проведения первой транзакции Вы увидите ее
                                здесь
                            </div>
                        </div>
                    }
                </div>
            </TransitionedBlock>

        );
    }
}

const mapDispatchToProps = {
    getMyTransactions
};

export default connect(null, mapDispatchToProps)(TransactionHistory);