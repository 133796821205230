export const PRICE_LIST = {
  GET_PRICE_LIST: "GET_PRICE_LIST",
  GET_PRICE_LIST_SUCCESS: "GET_PRICE_LIST_SUCCESS",
  GET_PRICE_LIST_FAIL: "GET_PRICE_LIST_FAIL",

  GET_PRODUCER_LIST: "GET_PRODUCER_LIST",
  GET_PRODUCER_LIST_SUCCESS: "GET_PRODUCER_LIST_SUCCESS",
  GET_PRODUCER_LIST_FAIL: "GET_PRODUCER_LIST_FAIL",

  GET_PRICE_LIST_INFO: "GET_PRICE_LIST_INFO",
  GET_PRICE_LIST_INFO_SUCCESS: "GET_PRICE_LIST_INFO_SUCCESS",
  GET_PRICE_LIST_INFO_FAIL: "GET_PRICE_LIST_INFO_FAIL",

  POST_PRICE_LIST: "POST_PRICE_LIST",
  POST_PRICE_LIST_SUCCESS: "POST_PRICE_LIST_SUCCESS",
  POST_PRICE_LIST_FAIL: "POST_PRICE_LIST_FAIL",

  ADD_PRICE_LIST: "ADD_PRICE_LIST",
  ADD_PRICE_LIST_SUCCESS: "ADD_PRICE_LIST_SUCCESS",
  ADD_PRICE_LIST_FAIL: "ADD_PRICE_LIST_FAIL",

  DELETE_PRICE_LIST: "DELETE_PRICE_LIST",
  DELETE_PRICE_LIST_SUCCESS: "DELETE_PRICE_LIST_SUCCESS",
  DELETE_PRICE_LIST_FAIL: "DELETE_PRICE_LIST_FAIL",

  UPDATE_PRICE_LIST: "UPDATE_PRICE_LIST",
  UPDATE_PRICE_LIST_SUCCESS: "UPDATE_PRICE_LIST_SUCCESS",
  UPDATE_PRICE_LIST_FAIL: "UPDATE_PRICE_LIST_FAIL",

  DOWNLOAD_PRICE_LIST: "DOWNLOAD_PRICE_LIST",

  GET_LINK_PRICE_LIST: "GET_LINK_PRICE_LIST",

  CHANGE_VALUE_OFFERS: "CHANGE_VALUE_OFFERS",

  CHANGE_VALUE_ADDED: "CHANGE_VALUE_ADDED",

  DELETE_OFFERS: "DELETE_OFFERS",

  DELETE_ADDED: "DELETE_ADDED",

  ADD_OFFERS: "ADD_OFFERS",

  SEARCH_OFFERS: "SEARCH_OFFERS",

  RESET_STATE: "RESET_STATE",

  CLEAR_LIST: "CLEAR_LIST",
};
