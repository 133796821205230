import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from "redux-form";
import BackLink from "../../BackLink/BackLink";
import RenderField from "../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import RenderOrderProducts from "../../HelperComponents/RenderOrderProducts/RenderOrderProducts";
import CommentOrders from "../AddOrders/CommentOrders/CommentOrders";
import Preloader from "../../HelperComponents/Preloader/Preloader";
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import DeleteOrder from "./DeleteOrder";
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent";
import FormControl from "@material-ui/core/FormControl";
import { isArray } from "../../../helpers/functions";
import {
  postNewOrder,
  getOrder,
  patchOrder,
  getOrders,
  getFile,
} from "../../../actions/ordersActions";
import { getNotifications } from "../../../actions/userActions";
import "./AddOrders.scss";

class AddOrders extends Component {
  constructor(props) {
    super(props);
    this.edit = props.match.params.id !== "add";
  }

  state = {
    orderCountNot: [],
    editProduct: [],
    status: { label: "Новый", value: "new" },
  };

  componentDidMount() {
    if (this.edit) {
      this.loadOrder();
    }
  }

  loadOrder() {
    const {
      getOrder,
      getNotifications,
      match: { params },
      history,
    } = this.props;
    getOrder(params.id).then((res) => {
      if (res.payload && res.payload.status && res.payload.status === 200) {
        let s = res.payload.data.status,
          obj = {};
        if (s === "new") {
          obj = { label: "Новый", value: "new" };
        } else if (s === "processing") {
          obj = { label: "Готов к выдаче", value: "processing" };
        } else if (s === "complete") {
          obj = { label: "Выполнен", value: "complete" };
        } else if (s === "denied") {
          obj = { label: "Отказ", value: "denied" };
        } else if (s === "waiting_for_documents") {
          obj = { label: "Ожидаем документыказ", value: "waiting_for_documents" };
        } else if (s === "waiting_for_details") {
          obj = { label: "Ожидаем деталь", value: "waiting_for_details" };
        }

        this.setState({
          editProduct: res.payload.data.orderproducts,
          status: obj,
        });
      }

      if (
        res.error &&
        res.error.response &&
        res.error.response.status &&
        res.error.response.status === 404
      ) {
        history.push("/admin/orders");
      }
      getNotifications();
    });
  }

  componentWillUnmount() {
    this.props.orders.order = {};
  }

  handleChange = (name) => (event) => {
    this.setState({ status: event });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadOrder();
      getNotifications();
    }
  }

  submitForm = ({ customer_name, customer_phone_number, orderproducts }) => {
    const {
      postNewOrder,
      patchOrder,
      getOrders,
      history,
      orders: { order },
    } = this.props;
    this.setState({ orderCountNot: [] });
    let arr = [];
    let orderCountNot = false;
    if (orderproducts.length !== 0) {
      orderproducts.map((el, i) => {
        if (el.count > el.product.amount) {
          orderCountNot = true;
          let arr = this.state.orderCountNot;
          arr.push(i);
        }
      });
    } else {
      throw new SubmissionError({
        _error: { arr: [], text: "Товар не выбран" },
      });
    }

    this.setState({ orderCountNot: arr });
    if (orderCountNot) {
      throw new SubmissionError({
        _error: {
          arr: this.state.orderCountNot,
          text: "Количество выбранного товара превышает доступное",
        },
      });
    } else {
      let obj = {
        customer_name,
        customer_phone_number,
        orderproducts: orderproducts.map((el) => {
          let t, s;
          if (
            !!this.state.editProduct[
              this.state.editProduct.findIndex((x) => x.id === el.product.id)
            ]
          ) {
            t = "identified";
            s = el.product.id;
          } else {
            t = "offer";
            s = el.id;
          }
          return {
            [t]: s,
            price: el.product.price,
            quantity: el.count,
          };
        }),
        total: orderproducts
          .map((el) => el.product.price * el.count)
          .reduce((sum, current) => sum + current),
        status: this.state.status.value,
      };
      let arr2d2 = [];
      arr2d2.push(
        `page=${!!localStorage.pageOrders ? localStorage.pageOrders : 1}`
      );
      if (this.edit) {
        patchOrder(order.id, obj).then((res) => {
          if (res.payload && res.payload.status && res.payload.status === 200) {
            getOrders(arr2d2);
            history.push("/admin/orders");
          }
        });
      } else {
        postNewOrder(obj).then((res) => {
          if (res.payload && res.payload.status && res.payload.status === 201) {
            getOrders(arr2d2);
            history.push("/admin/orders");
          }
        });
      }
    }
  };

  downloadFile = (method) => {
    const { getFile, match } = this.props;
    getFile(match.params.id).then((res) => {
      if (res.payload && res.payload.status === 200) {
        method(`https://${res.payload.data.path}`);
        // window.open(`http://${res.payload.data.path}`, "_blank");
      }
    });
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      valid,
      error,
      change,
      array_products,
      orders: { order },
      match: { params },
      history,
    } = this.props;
    const { status } = this.state;

    let summary = 0;

    if (isArray(array_products)) {
      summary = array_products
        .map((el) => el.product.price * el.count)
        .reduce((sum, current) => sum + current);
    }

    if (this.edit && !order.id) return <Preloader />;
    return (
      <TransitionedBlock>
        <main className="container">
          <div className="title-block">
            <BackLink to="/admin/orders" />
            <h1>{this.edit ? `Заказ ${order.id}` : "Добавить заказ"}</h1>
          </div>
          <div className="content-block-wrapper">
            <form onSubmit={handleSubmit(this.submitForm)}>
              <div>
                <p className="order_section_title">Покупатель</p>
              </div>
              <div className="add_order_block">
                <Field
                  name="customer_name"
                  type="text"
                  component={RenderField}
                  disabled={this.edit && order.is_client_create ? true : false}
                  label="Имя"
                />
                <Field
                  name="customer_phone_number"
                  type="text"
                  component={RenderField}
                  label="Телефон"
                  disabled={this.edit && order.is_client_create ? true : false}
                />
              </div>
              <hr />
              <FieldArray
                name="orderproducts"
                component={RenderOrderProducts}
                error={error && error}
                change={change}
                buttonShown={!!this.edit}
                downloadFile={this.downloadFile}
              />
              <hr />
              <div>
                <p className="order_section_title">Итого</p>
              </div>
              <div className="flex-center orders_summary">
                <div>
                  <span className="block_label">Товаров</span>
                  <p className="block_value_currency">
                    {(array_products && array_products.length) || 0}
                  </p>
                </div>
                <div>
                  <span className="block_label">Сумма</span>
                  <p className="block_value_currency">
                    {summary.toFixed(2)} <i>₽</i>
                  </p>
                </div>
                {this.edit && (
                  <FormControl className="custom_input_wrapper">
                    <SelectComponent
                      value={status}
                      isClearable
                      change={this.handleChange("status")}
                      placeholder="Статус"
                      options={[
                        {
                          label: "Новый",
                          value: "new",
                        },
                        {
                          label: "Готов к выдаче",
                          value: "processing",
                        },
                        {
                          label: "Выполнен",
                          value: "complete",
                        },
                        {
                          label: "Отказ",
                          value: "denied",
                        },
                        {
                          label: "Ожидаем документы",
                          value: "waiting_for_documents",
                        },
                        {
                          label: "Ожидаем деталь",
                          value: "waiting_for_detail",
                        },
                      ]}
                    />
                  </FormControl>
                )}
              </div>
              <hr />
              <div className="edit_btn_wrapper">
                <div>
                  {!this.edit ? (
                    <DefaultButton
                      variant="outlined"
                      classes="cancel_btn"
                      type="link"
                      to="/admin/orders"
                    >
                      Отмена
                    </DefaultButton>
                  ) : (
                    <DeleteOrder id={order.id} history={history} />
                  )}
                  <DefaultButton
                    variant="contained"
                    disabled={
                      this.edit ? false : submitting || pristine || !valid
                    }
                    formAction
                  >
                    Сохранить
                  </DefaultButton>
                  {error && error.text !== null ? (
                    <p className="error_after_button">{error.text}</p>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
          {this.edit ? (
            // && isArray(order.orderreviews)
            <CommentOrders params={params} />
          ) : null}
        </main>
      </TransitionedBlock>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.customer_name) {
    errors.customer_name = "Required";
  } else if (values.customer_name.length > 49) {
    errors.customer_name = "Must be less 50 characters";
  }
  if (!values.customer_phone_number) {
    errors.customer_phone_number = "Required";
  } else if (values.customer_phone_number.length > 49) {
    errors.customer_phone_number = "Must be less 50 characters";
  } else if (
    !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(
      values.customer_phone_number
    )
  ) {
    errors.customer_phone_number = "Phone number format is wrong";
  }

  return errors;
};

AddOrders = reduxForm({
  form: "AddOrders",
  enableReinitialize: true,
  validate,
})(AddOrders);

const selector = formValueSelector("AddOrders");

function mapStateToProps(state) {
  const array_products = selector(state, "orderproducts");
  return {
    orders: state.orders,
    array_products,
    initialValues: {
      customer_name:
        (state.orders.order && state.orders.order.customer_name) || "",
      customer_phone_number:
        (state.orders.order && state.orders.order.customer_phone_number) || "",
      orderproducts:
        (state.orders.order &&
          isArray(state.orders.order.orderproducts) &&
          state.orders.order.orderproducts.map((el) => {
            let arr = [];
            arr.push(el.name);
            arr.push(el.article_number);
            arr.push(el.producer);
            arr.push(`${el.amount} шт`);
            arr.push(el.price_list);
            arr.push(`${el.price} ₽`);
            return {
              id: el.offer,
              count: el.quantity,
              info: arr.join(" • "),
              product: {
                ...el,
              },
            };
          })) ||
        "",
      status: (state.orders.order && state.orders.order.status) || "",
    },
  };
}

const mapDispatchToProps = {
  postNewOrder,
  getOrder,
  getOrders,
  patchOrder,
  getNotifications,
  getFile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOrders);
