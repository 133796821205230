import React, {Component} from 'react';
import CartIcon from "../../../../assets/image/cart_icon_greeen.png";
import IncreaseIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {bindActionCreators} from "redux";
import {addToCart} from "../../../../actions/cartActions";
import { getSupplierFromType } from "../../../../helpers/functions";
import {connect} from "react-redux";
import classNames from 'classnames';
import {SuccessMessage} from "../../../HelperComponents/ToastMessages/ToastMessage";

import './../myOrderItem/myOrderItem.scss';


class MyOrderItem extends Component {
    state = {
        openModal: false,
        amount: 1,
        addDisabled: false,
        reDisabled: false,
        openToast: false,
        total_cart: 0,
        message: '',
        isItems: false
    };

    componentDidMount() {

        let lot = parseInt(this.props.lot);

        this.setState({amount: lot});
        if (parseInt(this.props.item.quantity) <= parseInt(lot)) {
            this.setState({addDisabled: true, reDisabled: true});
        }

        let cart = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [];
        this.setState({total_cart: cart.length});
    }
    componentWillUnmount() {
        this.setState({ amount: 1 })
    }

    decreaseAmount = (step) => {
        step = parseInt(step);
        let newValue = 0;
        const {amount, addDisabled} = this.state;
        newValue = amount - step;
        if (newValue > 0) {
            this.setState({amount: newValue, addDisabled: false});
        }
        if (addDisabled) {
            newValue = Math.floor(amount / step) * step
        }

    };

    increaseAmount = (step) => {
        step = parseInt(step);
        const {amount} = this.state;
        let newValue = parseInt(amount + step);
        if (newValue >= parseInt(this.props.item.quantity)) {
            this.setState({addDisabled: true});
            newValue = parseInt(this.props.item.quantity)
        }
        this.setState({amount: newValue});
    };

    handleClick = (id) => {
        let arrayCart = JSON.parse(localStorage.cart);
        let isItemsCart = [];
        arrayCart.forEach(el => {
            if(el.offer_id === id && !isItemsCart.includes(id)){
                isItemsCart.push(el.offer_id)
            }
        });
        if (!isItemsCart.includes(id)) {
            this.props.addToCart(id, +this.state.amount);
        } else {
            this.setState({isItems: true});
        };
        this.setState({openToast: true});
        this.props.handler();
    };

    onValueChange = (e) => {
        if (parseInt(e.target.value) >= parseInt(this.props.item.quantity)) {
            this.setState({amount: this.props.item.quantity});
        } else if (parseInt(this.props.item.quantity) >= parseInt(e.target.value) && parseInt(e.target.value) >= parseInt(this.props.item.lot)) {
            let new_amount = Math.round(parseFloat(e.target.value) / this.props.item.lot) * this.props.item.lot;
            this.setState({amount: new_amount.toString()})
        } else {
            this.setState({amount: e.target.value});
        }
    };

    onBlur = (e) => {
        if (parseInt(e.target.value) < parseInt(this.props.item.lot) || !parseInt(e.target.value)) {
            this.setState({amount: this.props.item.lot});

        }
    };


    toggleSnack = () => {
        this.setState({openToast: false});
    };

    render() {
        const {item, info, items_length, key} = this.props;
        const {amount, addDisabled, isItems} = this.state;
        return (
            <div className={classNames({"table_row": true, "delete_border": parseInt(items_length) <= parseInt(info.length) && info.last_analog})} key={`${key}i`}>
                <div className="row_item">
                    <span className="descriptions_row">Наличие</span>
                    {item.lot > 1 ?
                        <div>{item.quantity} {item.quantity !== 'Нет в наличии' ? `шт. (по ${item.lot})` : null}</div>
                        :
                        <div>{item.quantity} {item.quantity !== 'Нет в наличии' && item.quantity !== 'Под заказ' ? "шт." : null}</div>
                    }
                </div>
                <div className="row_item">
                    <span className="descriptions_row">Срок поставки</span>
                    <div>{item.delivery_time} {   item.quantity !== 'Нет в наличии' ? 'дн.' : '-'}</div>
                </div>
                <div className="row_item">
                    {item.show_supplier ?
                        <div>
                            <span className="descriptions_row">Поставщик</span>
                            <div>{item.supplier}</div>
                        </div>
                        :
                        null
                    }
                </div>                
                {
                    item.quantity !== 'Нет в наличии' ?
                        <div className="row_item">
                            <div>{item.price} <span className="ru_value">₽</span></div>
                        </div> : null
                }
                {
                    item.quantity !== 'Нет в наличии' ?
                        <div className="custom_count_field row_item">
                            <div className="count_field">
                                <button
                                    type="button"
                                    disabled={amount <= item.lot}
                                    onClick={() => {
                                        this.decreaseAmount(item.lot)
                                    }}
                                >
                                    <RemoveIcon/>
                                </button>
                                <input
                                    value={amount}
                                    type="number"
                                    min={item.lot}
                                    max={item.quantity}
                                    step={item.lot}
                                    onChange={(e) => this.onValueChange(e)}
                                    onBlur={(e) => this.onBlur(e)}
                                />
                                <button
                                    type="button"
                                    disabled={addDisabled}
                                    onClick={() => {
                                        this.increaseAmount(item.lot)
                                    }}
                                >
                                    <IncreaseIcon/>
                                </button>
                            </div>
                        </div> : null
                }
                {
                    // this.state.openToast ? <SuccessMessage close={this.toggleSnack} message={amount > 1 ? "Товары добавлены в корзину" :"Товар добавлен в корзину"} /> : null
                    this.state.openToast ? <SuccessMessage close={this.toggleSnack} message={item.exist ? 'Существует невыполненный заказ по данному товару' : isItems ? 'Данный товар уже добавлен в корзину' : amount > 1 ? "Товары добавлены в корзину" :"Товар добавлен в корзину"} /> : null
                }
                {
                    item.quantity !== 'Нет в наличии' ?
                        <div className="row_item">
                            <button className="add_orders" onClick={() => {
                                // this.handleClick(item.priceid)
                                this.handleClick(item.offer_id)
                            }}>
                                <img src={CartIcon} alt=""/>
                            </button>
                        </div> : null
                }
            </div>

        );
    }

}

const mapStateToProps = (state) => {
    return {
        addedItems: state.cart.addedItems,
        //addedItems: state.addedItems
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addToCart
    }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(MyOrderItem);
