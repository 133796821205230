import React, { Component, Fragment } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import RenderField from "../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";

import { postLogin } from "../../../actions/authActions";

class Login extends Component {
  state = {
    loading: false,
  };

  componentWillMount() {
    localStorage.clear();
    sessionStorage.clear();
  }

  submitForm = (data) => {
    const { postLogin, history } = this.props;
    this.setState({ loading: true });
    return postLogin(data).then((res) => {
      if (res.payload && res.payload.status && res.payload.status === 200) {
        localStorage.token = res.payload.data.token;
        localStorage.cart = '[]';
        localStorage.total = 0;
        history.push("/admin");
      } else {
        this.setState({ loading: false });
        throw new SubmissionError({
          ...res.error.response.data,
          _error: res.error.response.data.detail,
        });
      }
    });
  };

  render() {
    const { handleSubmit, submitting, pristine, valid, error } = this.props;
    const { loading } = this.state;
    return (
      <Fragment>
        <form className="form_start" onSubmit={handleSubmit(this.submitForm)}>
          <h3 className="auth-block_head">Вход в аккаунт</h3>
          <Field
            name="login"
            type="text"
            component={RenderField}
            label="Электронный адрес"
          />
          <Field
            name="password"
            type="password"
            component={RenderField}
            label="Пароль"
            password
          />
          <DefaultButton
            variant="contained"
            classes="full_btn_width"
            disabled={submitting || pristine || !valid}
            loading={loading}
            formAction
          >
            Войти
          </DefaultButton>
          {error && error !== null ? (
            <p className="auth_error">{error}</p>
          ) : null}
        </form>
        <Link to="/auth/password-recovery" className="auth_link">
          Забыли пароль?
        </Link>
      </Fragment>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.login) {
    errors.login = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.login)) {
    errors.login = "Invalid email";
  }
  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 8) {
    errors.password = "Must be 8 characters or more";
  }
  return errors;
};

Login = reduxForm({
  form: "LoginForm",
  validate,
})(Login);

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postLogin,
    },
    dispatch
  );
}

export default connect(
  null,
  mapDispatchToProps
)(Login);
