import * as types from './constants.jsx';

//orders actions

export function getOrders(arr) {
    return {
        type: types.GET_ORDERS,
        payload: {
            client: 'default',
            request: {
                url: `/orders/${arr.length && arr.length !== 0 ? '?' : ''}${arr.join('&')}`,
                method: "get"
            }
        }
    };
}

export function postNewOrder(data) {
    return {
        type: types.NEW_ORDER,
        payload: {
            client: 'default',
            request: {
                url: `/orders/`,
                method: "post",
                data
            }
        }
    };
}

export function getOrder(id) {
    return {
        type: types.GET_ORDER,
        payload: {
            client: 'default',
            request: {
                url: `/orders/${id}/`,
                method: "get"
            }
        }
    };
}

export function patchOrder(id, data) {
    return {
        type: types.PATCH_ORDER,
        payload: {
            client: 'default',
            request: {
                url: `/orders/${id}/`,
                method: "patch",
                data
            }
        }
    };
}

export function postOrderComment(data) {
    return {
        type: types.POST_ORDER_COMMENT,
        payload: {
            client: 'default',
            request: {
                url: `orders/comments/`,
                method: "post",
                data
            }
        }
    };
}

export function deleteOrder(id) {
    return {
        type: types.DELETE_ORDER,
        payload: {
            client: 'default',
            request: {
                url: `/orders/${id}/`,
                method: "delete"
            }
        }
    };
}

export function getProducts(query) {
    return {
        type: types.GET_PRODUCTS,
        payload: {
            client: 'default',
            request: {
                url: `/orders/productlist/?query=${query}`,
                method: "get"
            }
        }
    };
}

export function getFile(id) {
    return {
        type: types.GET_PRODUCTS,
        payload: {
            client: 'default',
            request: {
                url: `/order/${id}/xlsx/`,
                method: "get"
            }
        }
    };
}