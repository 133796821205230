import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import RenderField from '../HelperComponents/RenderField/RenderField';
import DefaultButton from '../Buttons/DefaultButton/DefaultButton';
import BackLink from '../BackLink/BackLink';
import RenderCheckField from '../HelperComponents/RenderCheckField/RenderCheckField';
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock';
import Loader from '../HelperComponents/Loader/Loader';
import SnackBar from '../HelperComponents/SnackBar/SnackBar';
import ChangePassword from './ChangePassword';
import InfoContacts from './InfoContacts';
import InfoRepresentation from './InfoRepresentation';

import { getInfo, patchInfo } from "../../actions/userActions";


import SuccessIcon from '../../assets/image/success_icon.png';

import './InfoCompany.scss';


class InfoCompany extends Component {
    state = {
        loading: false,
        success: false
    };

    componentDidMount() {
        const { getInfo, user:{info_company} } = this.props;
        if(!info_company || !info_company.company) {
            getInfo();
        }
    }

    submitForm = data => {
        const { patchInfo } = this.props;
        return patchInfo(data).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({success: true, loading: false});
                // setTimeout(() => {
                //     this.setState({success: false, loading: false});
                // }, 1000);
            } else {
                this.setState({loading: false});
                throw new SubmissionError({...res.error.response.data});
            }
        });
    };

    toggleSnack = () => {
        this.setState(({success}) => ({
            success: !success
        }));
    };

    render(){
        const { handleSubmit, submitting, pristine, valid, user:{info_company} } = this.props;
        const { loading, success } = this.state;
        return (
            <TransitionedBlock>
                <div className="container info-company">
                    <div className="title-block">
                        <BackLink/>
                        <h1>Информация о компании</h1>
                    </div>
                    <div className="content-block-wrapper info_company_block">
                        <div className="title">Общие данные</div>
                        {!info_company || !info_company.company ?
                            <div className="info_company_loader_wrapper">
                                <Loader/>
                            </div>
                            :
                            <Fragment>
                                <form onSubmit={handleSubmit(this.submitForm)}>
                                    <div className="field-block">
                                        <Field name="company" type="text" component={RenderField} label="Название компании"/>
                                        <Field name="web_site" type="text" component={RenderField} label="Сайт"/>
                                    </div>
                                    <div className="field-block mb-30">
                                        <Field name="email" type="text" component={RenderField} label="Электронный адрес"/>
                                    </div>
                                    {
                                        // данный блок скрыт
                                        false && <div className="check_wrapper">
                                            <Field name="publication_price_list" component={RenderCheckField} label="Оповещать о публикации прайс-листа" />
                                            <Field name="outdated_price_list" component={RenderCheckField} label="Оповещать об устаревшем прайс-листе" />
                                        </div>
                                    }
                                    <DefaultButton
                                        variant="contained"
                                        disabled={submitting || pristine || !valid}
                                        loading={loading}
                                        formAction
                                    >
                                        Сохранить
                                    </DefaultButton>
                                </form>
                                <ChangePassword/>
                            </Fragment>
                        }
                    </div>
                    <div className="content-block-wrapper">
                        <InfoContacts/>
                        <InfoRepresentation/>
                    </div>
                    <SnackBar
                        open={success}
                        onClose={this.toggleSnack}
                        classes="success"
                    >
                        <img src={SuccessIcon} alt="success icon"/>
                        Изменения успешно сохранены
                    </SnackBar>
                </div>
            </TransitionedBlock>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.company) {
        errors.company = 'Required'
    } else if (values.company.length < 3) {
        errors.company = 'Must be 3 characters or more'
    }
    if (!values.email) {
        errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
        errors.email = 'Invalid email'
    }
    return errors
};

InfoCompany = reduxForm({
    form: 'InfoCompany',
    enableReinitialize: true,
    validate
})(InfoCompany);

function mapStateToProps(state) {
    return{
        user: state.user,
        initialValues: {
            company: state.user.info_company.company || '',
            web_site: state.user.info_company.web_site || '',
            email: state.user.info_company.email || '',
            publication_price_list: state.user.info_company.publication_price_list || false,
            outdated_price_list: state.user.info_company.outdated_price_list || false
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getInfo,
        patchInfo
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoCompany);