import * as types from './constants.jsx';

//price list actions

export function getPriceListAddresses() {
    return {
        type: types.PRICE_LIST_ADDRESSES,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/addresses/`,
                method: "get"
            }
        }
    };
}

export function postSendFile(data) {
    return {
        type: types.SEND_FILE,
        payload: {
            client: 'default',
            request: {
                url: `/price_list/upload/`,
                method: "post",
                data
            }
        }
    };
}

export function getPriceLists(page) {
    return {
        type: types.PRICE_LISTS,
        payload: {
            client: 'default',
            request: {
                url: `/price_list/?page_size=12&page=${page}`,
                method: "get"
            }
        }
    };
}

export function postCreatePriceList(data) {
    return {
        type: types.CREATE_PRICE_LIST,
        payload: {
            client: 'default',
            request: {
                url: `/price_list/`,
                method: "post",
                data
            }
        }
    };
}

export function patchPriceList(data, id) {
    return {
        type: types.PATCH_PRICE_LIST,
        payload: {
            client: 'default',
            request: {
                url: `/price_list/${id}/`,
                method: "patch",
                data
            }
        }
    };
}

export function deletePriceList(id) {
    return {
        type: types.DELETE_PRICE_LIST,
        payload: {
            client: 'default',
            request: {
                url: `/price_list/${id}/`,
                method: "delete",
            }
        }
    };
}

export function postValidateOptionalFields(data) {
    return {
        type: types.POST_VALIDATE_OPTIONAL_FIELDS,
        payload: {
            client: 'default',
            request: {
                url: `/price_list/update/optional_fields/`,
                method: "post",
                data
            }
        }
    };
}