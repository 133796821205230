import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import AddContact from './AddContact';
import DeleteContact from './DeleteContact';
import Loader from '../HelperComponents/Loader/Loader';

import { isArray } from "../../helpers/functions";
import { getContacts } from "../../actions/userActions";

import TelegramIcon from '../../assets/image/telegram.png';
import ViberIcon from '../../assets/image/viber.png';
import WhatsappIcon from '../../assets/image/whatsapp.png';

class InfoContacts extends Component {

    componentDidMount() {
        const { getContacts} = this.props;
        getContacts();
    }

    componentWillUnmount() {
        this.props.user.info_contacts_loaded = false;
        this.props.user.info_contacts = [];
    }

    render(){
        const { user:{info_contacts, info_contacts_loaded} } = this.props;
        return (
            <Fragment>
                <div>
                    <div className="title-info">
                        <h3>Контакты</h3>
                        <AddContact
                            form={`AddContactForm`}
                        />
                    </div>
                    {info_contacts_loaded ?
                        isArray(info_contacts) ?
                            info_contacts.map((contact, i) => (
                                <div className="contacts" key={i}>
                                    <span className="block_label">Телефон</span>
                                    <div className="contacts-info">
                                        <div className="contacts-info-left">
                                            <div className="info">{contact.phone_number}</div>
                                            <div className="info-social">
                                                {contact.telegram
                                                    ? <img src={TelegramIcon} alt="telegram icon"/>
                                                    : null
                                                }
                                                {contact.viber
                                                    ? <img src={ViberIcon} alt="viber icon"/>
                                                    : null
                                                }
                                                {contact.whats_app
                                                    ? <img src={WhatsappIcon} alt="whatsapp icon"/>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="contacts-info-right">
                                            <AddContact
                                                edit
                                                form={`EditContact${i}Form`}
                                                {...contact}
                                            />
                                            {info_contacts.length !== 1 ?
                                                <DeleteContact
                                                    id={contact.id}
                                                />
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                            :
                            <p className="info_section_no_items block_value">У вас нет добавленых контактов</p>
                        :
                        <div className="info_section_loader">
                            <Loader/>
                        </div>
                    }
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getContacts
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoContacts);